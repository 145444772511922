import { useState, useCallback } from 'react';
import { useTransition } from '@react-spring/web';

/**
 * Animate between two "pages" being mounted/unmounted.
 * Useful for transitioning between views inside a modal.
 *
 * See `modals/auth-modal.js` for example usage
 */
export const useAnimatedPageTransitions = (initialPage) => {
  const [page, setPage] = useState(initialPage);
  const [direction, setDirection] = useState(1);

  const transitions = useTransition(page, {
    from: {
      opacity: 0,
      transform: `translate3d(${direction * 100}%,0,0)`,
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0%,0,0)',
    },
    leave: {
      position: 'absolute',
      width: '100%',
      opacity: 0,
      transform: `translate3d(${direction * -100}%,0,0)`,
    },
    immediate: process.env.NODE_ENV === 'test',
  });

  const transitionTo = useCallback((page, direction) => {
    setDirection(direction);
    setPage(page);
  }, []);

  // We need a way to reset to the initial page
  // but can't rely on useEffect because our modals are
  // never technically unmounted and running when
  // `isOpen` changes to false didn't seem to work...
  const resetPage = () => {
    setPage(initialPage);
    setDirection(1);
  };

  return {
    transitions,
    transitionTo,
    resetPage,
    page,
  };
};

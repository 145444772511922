import { config } from '~/sanity/sanity-client';
import { captureException } from '~/utils/exception-tracking';

/**
 * Determine the URL for Sanity files.
 * Expects an `asset` object from Sanity with an `assetId` and `extension`
 */
export const assetUrlFor = (asset) => {
  if (!asset?.assetId || !asset?.extension) {
    captureException(new Error('Unable to generate URL for incomplete asset'), {
      extras: {
        asset,
      },
    });
    return '';
  }

  return `/cms/assets/${config.dataset}/${asset.assetId}.${asset.extension}/${asset.originalFilename}`;
};

/**
 * The auth paths are defined as constants because they
 * are referenced in the `refreshTokenOnError` function
 */

import { isExistingEmailError } from '~/api/api-errors';

export const SIGN_IN_PATH = '/o/token/';
export const SIGN_OUT_PATH = '/o/revoke_token/';
export const REFRESH_TOKEN_PATH = '/o/token/';

const auth = {
  clientId: 'GRN8QPAOplpQb4EVNiQp9snF3CbxOidisgmsPIle',
  clientSecret:
    'yGTUFAaL78nlpjr7w2Rd0qHWSTWoTKAAtsUhMBI3EUygLKbp5O0lBGo1M2fl7S15yQLXaq1CCBaoc5adf2B0nTBBe3v5mdqXbvs1CYAMNybKjxVRKzZ4N3gELbUBWdVv',
};

const oAuthAuthorizationHeader = `Basic ${btoa(
  `${auth.clientId}:${auth.clientSecret}`
)}`;

export const sessionsApi = (http) => {
  let isSigningOut = false;
  let signOutListeners = [];

  return {
    register: async ({ email, password, source }) => {
      const res = await http.post('/api/registration/', {
        email,
        password,
        source,
      });

      // Existing emails trigger a 201 response, but we want treat it like an error
      if (isExistingEmailError(res)) {
        return Promise.reject(res);
      }

      return res;
    },

    forgotPassword: ({ email, queryParams }) => {
      return http.post(`/api/one_time_token_email/`, {
        email,
        query: queryParams,
        usage: 'res', // "reset password"
      });
    },

    requestMagicLink: ({ email, queryParams, source = 'sig' }) => {
      return http.post(`/api/one_time_token_email/`, {
        email,
        query: queryParams,
        usage: source,
      });
    },

    signIn: ({ email, password }) => {
      const data = new URLSearchParams();
      data.append('grant_type', 'password');
      data.append('username', email);
      data.append('password', password);

      return http
        .post(SIGN_IN_PATH, null, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: oAuthAuthorizationHeader,
          },
          body: data,
        })
        .then((res) => parseSession(res))
        .catch((err) => {
          return Promise.reject({
            error: err.error,
            message: err.error_description,
          });
        });
    },

    /**
     * Uses a very simple/naive dedupe mechanism so that we
     * only try to sign out once when multiple calls fail with
     * a 401 response
     */
    signOut: (token) => {
      if (isSigningOut) {
        return new Promise((resolve) => {
          signOutListeners.push(resolve);
        });
      }

      const data = new URLSearchParams();
      data.append('token', token);

      isSigningOut = true;
      return http
        .post(SIGN_OUT_PATH, null, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: oAuthAuthorizationHeader,
          },
          body: data,
        })
        .then((res) => {
          isSigningOut = false;
          signOutListeners.forEach((cb) => cb());
          signOutListeners = [];
          return res;
        });
    },

    refresh: (refreshToken) => {
      const data = new URLSearchParams();
      data.append('grant_type', 'refresh_token');
      data.append('refresh_token', refreshToken);

      return http
        .post(REFRESH_TOKEN_PATH, null, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: oAuthAuthorizationHeader,
          },
          body: data,
        })
        .then((res) => parseSession(res));
    },

    changePassword: (password) => {
      return http.post('/api/set_password/', {
        password1: password,
        password2: password,
      });
    },

    validateGoogleToken: (access_token, source) => {
      return http.post('/o/social/google-oauth2/', {
        access_token,
        source,
      });
    },

    validateFacebookToken: (access_token, source) => {
      return http.post('/o/social/facebook-oauth2/', {
        access_token,
        source,
      });
    },
  };
};

function parseSession(sessionRes) {
  return {
    token: sessionRes.access_token,
  };
}

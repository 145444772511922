/**
 * Creates a logger that sends output to the console with a configured
 * prefix before the message and dynamic enable/disable functionality
 */
export const createLogger = ({ prefix = [], enabled = () => true } = {}) => {
  const groupCollapsed = (...args) => {
    if (!enabled()) {
      return;
    }

    console.groupCollapsed(...prefix, ...args);
  };

  const groupEnd = (...args) => {
    if (!enabled()) {
      return;
    }

    console.groupEnd(...prefix, ...args);
  };

  const info = (...args) => {
    if (!enabled()) {
      return;
    }

    console.log(...prefix, ...args);
  };

  return {
    groupCollapsed: groupCollapsed,
    groupEnd,
    info,
  };
};

export const brownSpotApi = (topsoilHttp) => {
  return {
    analyzeImage: async (image) => {
      const formData = new FormData();
      formData.append('file', image, image.name);
      const res = await topsoilHttp.post('/api/bare-spots-id/file/', null, {
        body: formData,
      });

      const sortByConfidenceDesc = (a, b) => {
        return b.confidence - a.confidence;
      };

      return {
        healthyLawn: res.healthy_lawn,
        orderedPredictions: res.ordered_predictions
          ?.map((prediction) => ({
            diagnosis: prediction.diagnosis,
            confidence: prediction.confidence,
          }))
          .sort(sortByConfidenceDesc),
        uuid: res.uuid,
      };
    },

    claimAnalysis: (analysisUuid, userUuid) => {
      const params = new URLSearchParams({ user_uuid: userUuid });
      return topsoilHttp.patch(
        `/api/bare-spots-id/file/${analysisUuid}?${params}`
      );
    },
  };
};

import React from 'react';

import { isPreviewMode, exitPreviewMode } from '~/sanity/preview';

const PreviewBanner = () => {
  if (!isPreviewMode) {
    return null;
  }

  return (
    <div style={styles.banner}>
      <span>
        <span>Preview Mode</span>

        <a
          style={styles.openCMSLink}
          href={`${window.env.CMS_URL}/desk`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Open CMS
        </a>
      </span>

      <button
        style={styles.exitPreviewButton}
        type="button"
        onClick={exitPreviewMode}
      >
        Disable Preview Mode
      </button>
    </div>
  );
};

export default PreviewBanner;

// Since this is such a rarely-used component I'm inlining the styles
// to avoid an extra http request for the stylesheet.
// Pulled out to object for clarity.
const styles = {
  banner: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
    color: 'var(--light-color)',
    fontSize: '1.4rem',
    textAlign: 'center',
    padding: '1rem 2rem',
    background: 'var(--gray-1)',
    boxShadow:
      '5px 15px 20px -10px rgba(50, 50, 50, 0.2), 5px 5px 10px -10px rgba(0, 0, 0, 0.16)',
    zIndex: 999,
  },
  openCMSLink: {
    color: 'var(--light-color)',
    marginLeft: '2rem',
  },
  exitPreviewButton: {
    color: 'var(--light-color)',
    fontSize: '1.4rem',
    textDecoration: 'underline',
    padding: '0',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
};

import { ordinalSuffix } from '~/utils/numbers';
import { captureException } from './exception-tracking';

/**
 * Turn a date string into a formatted string with the month name abbreviated.
 *
 * Ex: '2020-01-07' => 'Jan 7, 2020'
 *
 * @param {String} dateString
 */
export const toFullDateString = (dateString) => {
  if (!dateString) {
    return '';
  }

  try {
    return new Date(dateString).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      timeZone: 'America/Denver',
    });
  } catch (err) {
    captureException(err);
    return '';
  }
};

/**
 * Turn a date string into a formatted string with full month name.
 *
 * Ex: '2020-01-07' => 'January 7, 2020'
 *
 * @param {String} dateString
 */
export const toLongFullDateString = (dateString) => {
  if (!dateString) {
    return '';
  }

  try {
    return new Date(dateString).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  } catch (err) {
    captureException(err);
    return '';
  }
};

/**
 * Turn a date string into a formatted string of numeric month/day.
 *
 * Ex: '2020-01-07' => '1/7'
 *
 * @param {String} dateString
 */
export const toNumericDateString = (dateString) => {
  if (!dateString) {
    return '';
  }

  try {
    return new Date(dateString).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'numeric',
      timeZone: 'America/Denver',
    });
  } catch (err) {
    captureException(err);
    return '';
  }
};

/**
 * Turn a date string into a formatted string of numeric day/month/year.
 *
 * Ex: '2020-01-07' => '1/7/20'
 *
 * @param {String} dateString
 */
export const toFullNumericDateString = (dateString) => {
  if (!dateString) {
    return '';
  }

  try {
    return new Date(dateString).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
    });
  } catch (err) {
    captureException(err);
    return '';
  }
};

/**
 * Turn a date string into a formatted string of full month name + year.
 *
 * Ex: '2020-01-07' => 'January 2020'
 *
 * @param {String} dateString
 */
export const toMonthAndYear = (dateString) => {
  if (!dateString) {
    return '';
  }

  try {
    return new Date(dateString).toLocaleDateString('en-US', {
      month: 'long',
      year: 'numeric',
    });
  } catch (err) {
    captureException(err);
    return '';
  }
};

export const getYear = (dateString) => {
  if (!dateString) {
    return '';
  }

  try {
    return new Date(dateString).getFullYear();
  } catch (err) {
    captureException(err);
    return '';
  }
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// March 8th
export const toMonthAndOrdinal = (dateString) => {
  const date = new Date(dateString);
  return `${months[date.getMonth()]} ${date.getDate()}${ordinalSuffix(
    date.getDate()
  )}`;
};

// Mar 8
export const toShortMonthAndDate = (dateString) => {
  if (!dateString) {
    return '';
  }

  try {
    return new Date(dateString).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
    });
  } catch (err) {
    captureException(err);
    return '';
  }
};

// March 8
export const toLongMonthAndDate = (dateString) => {
  if (!dateString) {
    return '';
  }

  try {
    return new Date(dateString).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'long',
    });
  } catch (err) {
    captureException(err);
    return '';
  }
};

// March
export const toLongMonth = (dateString) => {
  if (!dateString) {
    return '';
  }

  try {
    return new Date(dateString).toLocaleDateString('en-US', { month: 'long' });
  } catch (err) {
    captureException(err);
    return '';
  }
};

// Add # of days to current date string
export const addDays = (dateStr, daysAdded) => {
  const dateCopy = new Date(dateStr);
  return dateCopy.setDate(dateCopy.getDate() + daysAdded);
};

/**
 * Is dateA within x number of days of dateB?
 * If dateB is not provided, it defaults to today
 *
 * @param {string} dateA
 * @param {number} days
 * @param {string} dateB
 * @returns {boolean}
 */
export const withinDaysOf = (dateA, days, dateB = new Date()) => {
  if (!dateA || !days) {
    return false;
  }

  const staticDate = new Date(dateB);
  staticDate.setHours(0, 0, 0, 0); // Set the time to midnight

  const variableDate = new Date(dateA);
  variableDate.setHours(0, 0, 0, 0); // Set the time to midnight

  const daysFromDateA = new Date();
  daysFromDateA.setDate(staticDate.getDate() + days);
  daysFromDateA.setHours(0, 0, 0, 0); // Set the time to midnight

  return variableDate <= daysFromDateA;
};

import React from 'react';
import getYouTubeId from 'get-youtube-id';
import cx from 'classnames';

import styles from '~/components/the-shed/components/widgets/embedded-video/embedded-video.module.scss';

const EmbeddedVideoSerializer = ({ node }) => {
  const { altText, url } = node;
  const id = getYouTubeId(url);
  const src = `https://www.youtube.com/embed/${id}?rel=0&modestbranding=1`;

  return (
    <div className={cx(styles.embeddedVideo, 'embeddedVideo')}>
      <iframe
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className={styles.iframe}
        frameBorder="0"
        height="350"
        src={src}
        title={altText}
        width="640"
      />
    </div>
  );
};

export default EmbeddedVideoSerializer;

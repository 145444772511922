import { useState } from 'react';

const BlurImg = ({ previewSrc, src, alt, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div style={{ position: 'relative' }}>
      {!isLoaded && (
        <img
          {...props}
          alt={alt}
          src={previewSrc}
          style={{ position: 'absolute', top: 0, left: 0, height: '100%' }}
        />
      )}
      <img
        {...props}
        alt={alt}
        src={src}
        onLoad={() => setIsLoaded(true)}
        style={{ height: '100%' }}
      />
    </div>
  );
};

export default BlurImg;

import React from 'react';

import { AutocompleteTypes } from '~/components/inputs/autocomplete-address-input/autocomplete-storage';
import GoogleAutocomplete from '~/components/inputs/autocomplete-address-input/google-autocomplete';

const AutocompleteAddressInput = ({
  type = AutocompleteTypes.FULL_ADDRESS,
  ...otherProps
}) => {
  return (
    <GoogleAutocomplete
      type={type}
      initialValue={otherProps?.initialValue}
      {...otherProps}
    />
  );
};

export default AutocompleteAddressInput;

import { EventType } from '~/utils/analytics';
import { cookies } from '~/utils/cookie';
import {
  SubscriptionStatus,
  subscriptionStatusDisplay,
} from '~/features/subscriptions/subscription';
import { captureException } from '~/utils/exception-tracking';

const cookieName = 'ATTENTIVE_AUTHED_LAWN_SUB';
export const attentivePlugin = {
  name: 'Attentive',
  init: () => {
    loadAttentive();
  },
  events: {
    [EventType.SESSION_CREATED]: ({ lawnSubscriptionStatus }) => {
      setAttentiveCookie(lawnSubscriptionStatus);
    },
    [EventType.PLAN_PURCHASED]: () => {
      setAttentiveCookie(subscriptionStatusDisplay(SubscriptionStatus.ACTIVE));
    },
  },
};

function initCookie() {
  const hasCookie = cookies.get(cookieName);
  if (hasCookie === undefined) {
    cookies.set(cookieName, false);
  }
  return;
}

function loadAttentive() {
  const script = document.createElement('script');
  script.src = 'https://cdn.attn.tv/sunday/dtag.js';

  document.body.appendChild(script);

  initCookie();
}

function setAttentiveCookie(lawnSubscriptionStatus) {
  const lawnSubStatus =
    lawnSubscriptionStatus?.toUpperCase() ===
    subscriptionStatusDisplay(SubscriptionStatus.ACTIVE).toUpperCase();

  cookies.set(cookieName, lawnSubStatus);
}

export const attentiveApi = (http) => {
  return {
    subscribe: (usersPhoneNumber) => {
      return http
        .post(`/webhooks/attentive/`, {
          user: {
            phone: usersPhoneNumber,
          },
          signUpSourceId: '347142',
        })
        .catch((err) => {
          captureException(new Error('Error subscribing user to Attentive'), {
            extras: {
              error: err,
            },
          });
        });
    },
  };
};

import { useQuery } from 'react-query';

import { api } from '~/api';
import { useSession } from '~/features/session/session-actions';

export const useFormAnswers = () => {
  const { isSignedIn } = useSession();

  const {
    data: formAnswers,
    isLoading,
    isError,
  } = useQuery('form-answers', () => api.dynamicForms.fetchAnswers(), {
    enabled: isSignedIn,
  });

  return {
    formAnswers,
    isError,
    isLoading,
  };
};

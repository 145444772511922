import React from 'react';
import cx from 'classnames';

import LazyImg from '~/components/lazy-img';
import { urlFor } from '~/sanity/images';
import { toLongFullDateString } from '~/utils/dates';

import styles from '~/components/the-shed/components/author-block.module.scss';

const AuthorBlock = ({
  author,
  publishedAt,
  updatedAt,
  variant,
  className,
}) => {
  if (!author) {
    return null;
  }

  const { name, photo } = author;
  const initials = name?.replace(/[^A-Z]/g, '');

  const source = urlFor(photo).width(160).height(160).fit('max').auto('format');

  const simpleDate =
    variant === 'horizontal-simple' || variant === 'featured-article';

  const updatedCopy = simpleDate
    ? toLongFullDateString(updatedAt)
    : `Updated on ${toLongFullDateString(updatedAt)}`;

  const articleHasUpdated =
    new Date(updatedAt).getTime() > new Date(publishedAt).getTime();

  const articleDate = articleHasUpdated
    ? updatedCopy
    : toLongFullDateString(publishedAt);

  return (
    <div
      className={cx(styles.authorBlock, className, {
        [styles.horizontal]: variant === 'horizontal',
        [styles.horizontalSimple]: variant === 'horizontal-simple',
        [styles.featuredArticle]: variant === 'featured-article',
        [styles.header]: variant === 'header',
      })}
    >
      {photo ? (
        <LazyImg
          className={styles.authorImage}
          src={source.url()}
          alt={photo?.alt || 'Author image'}
          width="80"
          height="80"
        />
      ) : (
        <div className={styles.authorImage}>{initials}</div>
      )}
      <div className={styles.authorDetails}>
        <p className={styles.authorName}>{name}</p>
        <p className={styles.articleDate}>{articleDate}</p>
      </div>
    </div>
  );
};

export { AuthorBlock };

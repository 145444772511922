import { FEATURE_FLAG_CT_PRICES } from '~/feature-flags';

const getChannelAndPrice = (channel, product) => {
  const { productDetails = {}, purchaseSku = {} } = product;

  const { unitPrice: purchaseSkuUnitPrice, fullPrice: purchaseSkuFullPrice } =
    purchaseSku;

  if (!FEATURE_FLAG_CT_PRICES) {
    return {
      unitPrice: purchaseSkuUnitPrice,
      fullPrice: purchaseSkuFullPrice,
    };
  }

  const prices = productDetails.prices;
  const unitPrice = productDetails.unitPrice || purchaseSkuUnitPrice;
  const fullPrice = productDetails.fullPrice || purchaseSkuFullPrice;

  if (!prices || prices.length === 0) {
    return {
      unitPrice,
      fullPrice: fullPrice,
    };
  }

  const selectedPrice = prices.find((price) => price.channel?.key === channel);

  if (selectedPrice) {
    const selectedDiscountedPrice = selectedPrice.discounted?.value?.centAmount;
    const selectedFullPrice = selectedPrice.value.centAmount;

    return {
      channel,
      unitPrice: selectedDiscountedPrice || selectedFullPrice,
      fullPrice: selectedFullPrice,
    };
  }

  const nullPrice = prices.find((price) => price.channel === null);

  if (nullPrice) {
    const nullDiscountedPrice = nullPrice.discounted?.value?.centAmount;
    const nullFullPrice = nullPrice.value.centAmount;

    return {
      unitPrice: nullDiscountedPrice || nullFullPrice,
      fullPrice: nullFullPrice,
    };
  }

  const firstPrice = prices[0];

  if (firstPrice) {
    const firstDiscountedPrice = firstPrice.discounted?.value?.centAmount;
    const firstFullPrice = firstPrice.value.centAmount;

    return {
      channel: firstPrice.channel.key,
      unitPrice: firstDiscountedPrice || firstFullPrice,
      fullPrice: firstFullPrice,
    };
  }

  return {
    unitPrice,
    fullPrice,
  };
};

export default getChannelAndPrice;

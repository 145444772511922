import React from 'react';

import { helpCenterRoute } from '~/routes';
import Copyable from '~/components/copyable';

import styles from './error.module.scss';
import buttonStyles from '~/components/button.module.scss';

const isNotProd = window.env.DEPLOY_ENV !== 'production';

/**
 * This component is used in the error modal the top-level error boundary,
 * so it's important to keep it very slim so we don't bloat the main JS bundle.
 */
const GeneralErrorContent = ({ error = '', stackTrace = '' }) => {
  return (
    <>
      <img
        className={styles.img}
        src="/images/smoking-mower.gif?v1"
        alt="A lawn mower with smoke coming out"
        width="457"
        height="536"
      />

      <h1 className={styles.title}>shucks</h1>

      <section className={styles.section}>
        <p className={styles.copy}>Something went wrong.</p>
        <p className={styles.copy}>
          Try reloading the page, or contact our support team so we can fix it.
        </p>
        {isNotProd && error && (
          <>
            <code className={styles.exception}>
              <Copyable value={error + stackTrace} code />
            </code>
          </>
        )}
      </section>

      {/* Use an explicit anchor tag to force a full reload */}
      <a
        className={[
          styles.supportBtn,
          buttonStyles.button,
          buttonStyles.outline,
        ].join(' ')}
        href={helpCenterRoute()}
      >
        view help center
      </a>
    </>
  );
};

export default GeneralErrorContent;

import * as r from '~/routes';
import { PEST_PLAN_FLAG } from '~/feature-flags';

const SubNavContent = {
  CUSTOM_PLANS_CONTENT: {
    headerText: PEST_PLAN_FLAG ? 'Custom plans' : 'Custom lawn plans',
    section1: {
      title: 'Shop',
      links: [
        {
          text: 'Build my custom lawn plan',
          heapId: 'nav-plans-smart-lawn-plan',
          route: r.customLawnPlanRoute(),
        },
        {
          text: 'Build my custom pest plan',
          heapId: 'nav-plans-smart-pest-plan',
          route: r.customPestPlanRoute(),
        },
      ],
    },
    section2: {
      title: 'How it works',
      links: [],
      customContent: [
        {
          altText: 'question mark',
          icon: '/icons/question-icon.svg',
          text: PEST_PLAN_FLAG
            ? 'Answer a few questions about your lawn and pest goals'
            : 'Answer a few questions about your lawn goals',
          height: '33',
          width: '34',
        },
        {
          altText: 'map pin',
          icon: '/icons/map-pin-icon.svg',
          text: 'Get a unique plan based on your location and yard use',
          height: '37',
          width: '38',
        },
        {
          altText: 'shipping box',
          icon: '/icons/box-icon.svg',
          text: 'Relax until your boxes arrive—right when you need them',
          height: '29',
          width: '32',
        },
      ],
    },
    featured: {
      image: {
        altText: 'Lawn kit items, pouches, sprayers, soil test',
        src: '/images/navigation/full-lawn-kit.jpg, /images/navigation/full-lawn-kit@2x.jpg',
        height: '157',
        width: '251',
      },
      link: {
        text: 'Get my lawn plan',
        heapId: 'nav-plans-lawn-plan-featured',
        route: r.newLawnRoute(),
      },
    },
  },
  LAWN_CARE_CONTENT: {
    headerText: 'Lawn care',
    section1: {
      title: 'Shop',
      links: [
        {
          text: 'Build my custom lawn plan',
          heapId: 'nav-lawn-shop-smart-plan',
          route: r.customLawnPlanRoute(),
        },
        {
          text: 'All lawn care',
          heapId: 'nav-lawn-shop-all',
          route: r.categoryPageRoute('lawn-care'),
        },
        {
          text: 'Grass seed',
          heapId: 'nav-lawn-shop-grass-seed',
          route: r.categoryPageRoute('lawn-care', 'grass-seed'),
        },
        {
          text: 'Weed control',
          heapId: 'nav-lawn-shop-weed-control',
          route: r.categoryPageRoute('lawn-care', 'weed-control'),
        },
        {
          text: 'Pet',
          heapId: 'nav-lawn-shop-pet',
          route: r.categoryPageRoute('lawn-care', 'pet'),
        },
        {
          text: 'Fertilizer & nutrients',
          heapId: 'nav-lawn-shop-fertilizer-nutrients',
          route: r.categoryPageRoute('lawn-care', 'fertilizer-nutrients'),
        },
        {
          text: 'Watering & irrigation',
          heapId: 'nav-lawn-shop-watering-irrigation',
          route: r.categoryPageRoute('lawn-care', 'watering-irrigation'),
        },
        {
          text: 'Mowers & equipment',
          heapId: 'nav-lawn-shop-mowers-equipment',
          route: r.categoryPageRoute('lawn-care', 'mowers-equipment'),
        },
      ],
    },
    section2: {
      title: 'Learn',
      links: [
        {
          text: 'Product instructions',
          heapId: 'nav-lawn-learn-instructions',
          route: r.shedCategoryRoute({
            category: 'product-instructions',
            subCategory: 'lawn-care-instructions',
          }),
        },
        {
          text: 'Lawn how-tos',
          heapId: 'nav-lawn-learn-lawn-howtos',
          route: r.shedCategoryRoute({
            category: 'lawn',
          }),
        },
        {
          text: 'Regional lawn guides',
          heapId: 'nav-lawn-learn-regional-lawn-guides',
          route: r.shedCategoryRoute({
            category: 'yard-guides',
            subCategory: 'regional-lawn-care-guides',
          }),
        },
      ],
    },
    featured: {
      image: {
        altText: 'Woman spraying on lawn pouch to grass',
        src: '/images/navigation/lawn-care_thumb.jpg, /images/navigation/lawn-care_thumb@2x.jpg',
        height: '157',
        width: '251',
      },
      link: {
        text: 'Get my lawn plan',
        heapId: 'nav-lawn-lawn-plan-featured',
        route: r.newLawnRoute(),
      },
    },
  },
  PEST_CONTROL_CONTENT: {
    headerText: 'Pest control',
    section1: {
      title: 'Shop',
      links: [
        {
          text: 'Build my custom pest plan',
          heapId: 'nav-pest-smart-pest-plan',
          route: r.customPestPlanRoute(),
        },
        {
          text: 'All pest control',
          heapId: 'nav-pest-shop-all',
          route: r.pestsRoute(),
        },
      ],
    },
    section2: {
      title: 'Learn',
      links: [
        {
          text: 'Product instructions',
          heapId: 'nav-pest-learn-pest-instructions',
          route: r.shedCategoryRoute({
            category: 'product-instructions',
            subCategory: 'pest-control-instructions',
          }),
        },
        {
          text: 'Pest pointers',
          heapId: 'nav-pest-learn-pest-pointers',
          route: r.shedCategoryRoute({
            category: 'pest-control',
          }),
        },
        {
          text: 'Regional pest guides',
          heapId: 'nav-pest-learn-regional-pest-guides',
          route: r.shedCategoryRoute({
            category: 'yard-guides',
            subCategory: 'regional-pest-guides',
          }),
        },
      ],
    },
    featured: {
      image: {
        altText: 'Pest control products',
        src: '/images/navigation/pest-control_thumb.jpg, /images/navigation/pest-control_thumb@2x.jpg',
        height: '157',
        width: '251',
      },
      link: {
        text: PEST_PLAN_FLAG
          ? 'Get my pest plan'
          : 'Learn more about pest control',
        heapId: PEST_PLAN_FLAG
          ? 'nav-pest-smart-pest-plan-featured'
          : 'nav-pest-shed-featured',
        route: PEST_PLAN_FLAG
          ? r.newPestRoute()
          : r.shedArticleRoute({
              category: 'sunday-way',
              slug: 'sunday-pest-control',
            }),
      },
    },
  },
  LIVE_PLANTS_CONTENT: {
    headerText: 'Live plants & trees',
    section1: {
      title: 'Shop',
      links: [
        {
          text: 'All live plants & trees',
          heapId: 'nav-plants+trees-shop-all-plants-trees',
          route: r.categoryPageRoute('live-plants-trees'),
        },
        {
          text: 'Trees',
          heapId: 'nav-plants+trees-shop-trees',
          route: r.categoryPageRoute('live-plants-trees', 'trees'),
        },
        {
          text: 'Shrubs & bushes',
          heapId: 'nav-plants+trees-shop-shrubs-bushes',
          route: r.categoryPageRoute('live-plants-trees', 'shrubs-bushes'),
        },
        {
          text: 'Perennials',
          heapId: 'nav-plants+trees-shop-perennials',
          route: r.categoryPageRoute('live-plants-trees', 'perennials'),
        },
      ],
    },
    section2: {
      title: 'Learn',
      links: [
        {
          text: 'Product instructions',
          heapId: 'nav-plants+trees-learn-instructions',
          route: r.shedCategoryRoute({
            category: 'product-instructions',
            subCategory: 'live-plants-and-trees-instructions',
          }),
        },
        {
          text: 'Gardening guides',
          heapId: 'nav-plants+trees-learn-gardening-guides',
          route: r.shedCategoryRoute({
            category: 'garden',
          }),
        },
        {
          text: 'Regional garden guides',
          heapId: 'nav-plants+trees-learn-regional-garden-guides',
          route: r.shedCategoryRoute({
            category: 'yard-guides',
            subCategory: 'regional-planting-guides',
          }),
        },
      ],
    },
    featured: {
      image: {
        altText: 'Apple blossom flower',
        src: '/images/navigation/live-plants+trees_thumb.jpg, /images/navigation/live-plants+trees_thumb@2x.jpg',
        height: '157',
        width: '251',
      },
      link: {
        text: 'Shop trees',
        heapId: 'nav-plants+trees-shop-trees-featured',
        route: r.categoryPageRoute('live-plants-trees', 'trees'),
      },
    },
  },
  GARDEN_CONTENT: {
    headerText: 'Garden',
    section1: {
      title: 'Shop',
      links: [
        {
          text: 'All garden',
          heapId: 'nav-garden-shop-all',
          route: r.categoryPageRoute('garden'),
        },
        {
          text: 'Fertilizer & soils',
          heapId: 'nav-garden-shop-fertilizer-soils',
          route: r.categoryPageRoute('garden', 'fertilizer-soils'),
        },
        {
          text: 'Garden tools',
          heapId: 'nav-garden-shop-tools',
          route: r.categoryPageRoute('garden', 'garden-tools'),
        },
        {
          text: 'Garden pest',
          heapId: 'nav-garden-shop-pest-control',
          route: r.categoryPageRoute('garden', 'garden-pests'),
        },
      ],
    },
    section2: {
      title: 'Learn',
      links: [
        {
          text: 'Product instructions',
          heapId: 'nav-garden-learn-garden-instructions',
          route: r.shedCategoryRoute({
            category: 'product-instructions',
            subCategory: 'garden-instructions',
          }),
        },
        {
          text: 'Gardening guides',
          heapId: 'nav-garden-learn-gardening-guides',
          route: r.shedCategoryRoute({
            category: 'garden',
          }),
        },
        {
          text: 'Regional garden guides',
          heapId: 'nav-garden-learn-regional-garden-guides',
          route: r.shedCategoryRoute({
            category: 'yard-guides',
            subCategory: 'regional-planting-guides',
          }),
        },
      ],
    },
    featured: {
      image: {
        altText: 'Woman digging in planting bed',
        src: '/images/navigation/garden_thumb.jpg, /images/navigation/garden_thumb@2x.jpg',
        height: '157',
        width: '251',
      },
      link: {
        text: 'Shop seed',
        heapId: 'nav-lawn-shop-grass-seed',
        route: r.grassSeedRoute(),
      },
    },
  },
  SHED_CONTENT: {
    headerText: 'The Shed blog',
    headerSubText: (
      <>
        Your guide to growing, mowing, <br /> and backyard living choices that
        are better for people, pets, and planet.
      </>
    ),
    shedCustom: {
      image: {
        altText: "Bird's eye view of a lawn being mowed",
        src: '/images/navigation/shed-spring-tips.jpg',
        height: '100',
        width: '311',
      },
      link: {
        text: 'Feeding Your Fall Lawn',
        heapId: 'nav-shed-featured-article',
        route: r.shedArticleRoute({
          category: 'lawn',
          slug: 'fall-lawn-fertilizer-benefits',
        }),
      },
    },
    section1: {
      title: 'Learn',
      links: [
        {
          text: 'Explore Shed home',
          heapId: 'nav-shed-learn-all',
          route: r.shedRoute(),
        },
        {
          text: 'Lawn how-tos',
          heapId: 'nav-shed-learn-lawn-howtos',
          route: r.shedCategoryRoute({
            category: 'lawn',
          }),
        },
        {
          text: 'Gardening guides',
          heapId: 'nav-shed-learn-gardening-guides',
          route: r.shedCategoryRoute({
            category: 'garden',
          }),
        },
        {
          text: 'Weeding tips',
          heapId: 'nav-shed-learn-weeding-tips',
          route: r.shedCategoryRoute({
            category: 'weeds',
          }),
        },
        {
          text: 'Pest pointers',
          heapId: 'nav-shed-learn-pest-pointers',
          route: r.shedCategoryRoute({
            category: 'pest-control',
          }),
        },
        {
          text: 'Backyard living',
          heapId: 'nav-shed-learn-backyard-living',
          route: r.shedCategoryRoute({
            category: 'backyard-living',
          }),
        },
        {
          text: 'Regional yard guides',
          heapId: 'nav-shed-learn-yard-guides',
          route: r.shedCategoryRoute({
            category: 'yard-guides',
          }),
        },
      ],
    },
    section2: {
      title: 'Product instructions',
      links: [
        {
          text: 'See products guide',
          heapId: 'nav-shed-instructions-all',
          route: r.shedCategoryRoute({
            category: 'product-instructions',
          }),
        },
        {
          text: 'Weed control',
          heapId: 'nav-shed-instructions-weed-control',
          route: r.shedCategoryRoute({
            category: 'product-instructions',
            subCategory: 'weed-control-instructions',
          }),
        },
        {
          text: 'Pet',
          heapId: 'nav-shed-instructions-pet-products',
          route: r.shedCategoryRoute({
            category: 'product-instructions',
            subCategory: 'pet-product-instructions',
          }),
        },
        {
          text: 'Patch & seed',
          heapId: 'nav-shed-instructions-patch-and-seed',
          route: r.shedCategoryRoute({
            category: 'product-instructions',
            subCategory: 'patch-and-seed-instructions',
          }),
        },
        {
          text: 'Lawn fertilizer',
          heapId: 'nav-shed-instructions-lawn-fertilizer',
          route: r.shedCategoryRoute({
            category: 'product-instructions',
            subCategory: 'lawn-fertilizer-instructions',
          }),
        },
        {
          text: 'Pest control',
          heapId: 'nav-shed-instructions-pest-control',
          route: r.shedCategoryRoute({
            category: 'product-instructions',
            subCategory: 'pest-control-instructions',
          }),
        },
        {
          text: 'Garden',
          heapId: 'nav-shed-instructions-garden',
          route: r.shedCategoryRoute({
            category: 'product-instructions',
            subCategory: 'garden-instructions',
          }),
        },
      ],
    },
  },
};

export default SubNavContent;

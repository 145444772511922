import React, { useLayoutEffect, useState } from 'react';
import cx from 'classnames';

import {
  hideGladlyChat,
  showGladlyChat,
} from '~/utils/analytics/plugins/gladly';

import styles from '~/components/call-to-action/call-to-action.module.scss';

const MobileFloatingCTA = ({
  children,
  elementToLookFor,
  offSetMargin = '0rem',
  whenHiding = () => {},
  whenShowing = () => {},
  hideAfterPrimaryCTA = false,
  className,
}) => {
  const [isCTAvisible, setIsCTAVisible] = useState(false);
  const outerMargin = {
    margin: `0 calc(var(--inverse-gutter) + ${offSetMargin})`,
  };

  function showFloatingCta() {
    setIsCTAVisible(true);
    whenShowing();
  }

  function hideFloatingCta() {
    setIsCTAVisible(false);
    whenHiding();
  }

  const displayWidgets = () => {
    const mobileCTA = document.getElementById('mobileFloatingCta');
    if (window.getComputedStyle(mobileCTA).display === 'none') {
      showGladlyChat();
    } else {
      hideGladlyChat();
    }
  };

  useLayoutEffect(() => {
    let isElementAboveViewPort = false;
    const intersectionObserver = new IntersectionObserver(function (entries) {
      entries[0].boundingClientRect.top <= 0
        ? (isElementAboveViewPort = true)
        : (isElementAboveViewPort = false);

      if (
        (isElementAboveViewPort && hideAfterPrimaryCTA) ||
        entries[0].intersectionRatio !== 0
      ) {
        hideFloatingCta();
      } else {
        showFloatingCta();
      }
      displayWidgets();
      return;
    });

    const element = document.querySelector(elementToLookFor);
    intersectionObserver.observe(element);

    return () => intersectionObserver.unobserve(element);
  });

  return (
    <div
      id="mobileFloatingCta"
      className={cx(styles.newMobileFloatingCta, className, {
        [styles.hidden]: !isCTAvisible,
      })}
      style={{ ...outerMargin }}
    >
      {children}
    </div>
  );
};

export default MobileFloatingCTA;

export const getTabbables = (el) => {
  return Array.from(el.querySelectorAll('a, button, input, select, textarea'));
};

export const setTabbable = (elements, isTabbable) => {
  elements.forEach((el) => {
    el.setAttribute('tabindex', isTabbable ? 0 : -1);
  });
};

export const trapFocus = (tabbables) => {
  const firstFocusableElement = tabbables[0];
  const lastFocusableElement = tabbables[tabbables.length - 1];

  const handleKeydown = (e) => {
    const isTab = e.key === 'Tab';

    if (!isTab) {
      return;
    }

    if (e.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        setTimeout(() => {
          lastFocusableElement.focus();
          e.preventDefault();
        });
      }
    } else {
      if (document.activeElement === lastFocusableElement) {
        setTimeout(() => {
          firstFocusableElement.focus();
          e.preventDefault();
        });
      }
    }
  };

  document.addEventListener('keydown', handleKeydown);

  return () => {
    document.removeEventListener('keydown', handleKeydown);
  };
};

import { useRef, useState, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

const DEFAULT_OPTIONS = {
  initialValue: { left: 0, top: 0, width: 0, height: 0 },
  observeOptions: { box: 'content-box' },
  entryAccessor: (entry) => entry.contentRect,
};

/**
 * Available options:
 * `initialValue` - the initial value of the `value` state (should be of the shape of the returned data)
 * `entryAccessor` - a function that accepts an ResizeObserverEntry as an argument and returns the data we wish to return from the hook itself
 * `observeOptions` - an object that contains additional options for the ResizeObserver.observe method (`box` is the only option at the moment)
 */
export const useMeasure = ({
  initialValue = DEFAULT_OPTIONS.initialValue,
  entryAccessor = DEFAULT_OPTIONS.entryAccessor,
  observeOptions = DEFAULT_OPTIONS.observeOptions,
} = DEFAULT_OPTIONS) => {
  const ref = useRef();
  const [value, set] = useState(initialValue);

  const [ro] = useState(
    () =>
      new ResizeObserver(([entry]) => {
        set(entryAccessor(entry));
      })
  );

  useEffect(() => {
    if (ref.current) {
      ro.observe(ref.current, observeOptions);
    }

    return () => ro.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return [ref, value];
};

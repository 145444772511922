import { set, get, del } from 'idb-keyval';
import idbReady from 'safari-14-idb-fix';

import { cookies } from '~/utils/cookie';

/**
 * createStorage() is a factory function that returns an object with methods
 * for interacting with device storage
 */
export const createStorage = ({ namespace }) => {
  let idbSupport = null;
  const checkSupport = async () => {
    if (idbSupport !== null) {
      return idbSupport;
    }

    try {
      await idbReady();
      await set(getFullKey('test'), 'test');
      await del(getFullKey('test'));
      idbSupport = true;
      return true;
    } catch (_) {
      idbSupport = false;
      return false;
    }
  };

  const getFullKey = (key) => {
    return `${namespace}:${key}`;
  };

  return {
    get: async (key) => {
      try {
        const canUseIdb = await checkSupport();
        if (canUseIdb) {
          return await get(getFullKey(key));
        } else {
          return JSON.parse(cookies.get(key) || null);
        }
      } catch (err) {}
    },

    set: async (key, value) => {
      try {
        const canUseIdb = await checkSupport();
        if (canUseIdb) {
          return await set(getFullKey(key), value);
        } else {
          return cookies.set(key, JSON.stringify(value), { expires: 29 });
        }
      } catch (err) {}
    },

    remove: async (key) => {
      try {
        const canUseIdb = await checkSupport();
        if (canUseIdb) {
          return await del(getFullKey(key));
        } else {
          return cookies.remove(key);
        }
      } catch (err) {}
    },
  };
};

export const storage = createStorage({
  namespace: 'APP',
});

(window.topsoil = window.topsoil || {}).storage = storage;

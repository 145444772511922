import imageUrlBuilder from '@sanity/image-url';

import { config } from '~/sanity/sanity-client';
// import { captureException } from '~/utils/exception-tracking';

const builder = imageUrlBuilder({
  dataset: config.dataset,
  projectId: config.projectId,
});

export const urlFor = (source) => {
  const windowDPR = window.devicePixelRatio || 1;
  return builder.image(source).dpr(Math.min(windowDPR, 2)).auto('format');
};

export const reportMissingSanityImage = (sku) => {
  return; // TODO: turn this back on once we care about missing images in sanity

  // captureException(new MissingSanityImageError({ sku }), {
  //   extras: { sku },
  // });
};

// class MissingSanityImageError extends Error {
//   constructor({ sku }) {
//     super(`Missing Sanity image for product: ${sku}`);
//     this.name = 'MissingSanityImageError';
//   }
// }

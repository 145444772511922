import React from 'react';
import cx from 'classnames';

import styles from '~/components/breadcrumbs/breadcrumbs.module.scss';

const Breadcrumbs = ({ children, className }) => {
  return (
    <nav className={cx(styles.breadcrumbs, className)} aria-label="Breadcrumb">
      <ol>{children}</ol>
    </nav>
  );
};

export default Breadcrumbs;

import { formatNumber } from './numbers';

export const valid =
  (...validatorFuncs) =>
  (value) => {
    const errorString = validatorFuncs.reduce((error, validatorFunc) => {
      return error || validatorFunc(value) || '';
    }, '');

    return errorString !== '' ? errorString : undefined;
  };

export const email = (customMsg) => (value) => {
  const emailRegex = new RegExp(/.+@.+\..+/);
  let error;

  if (!emailRegex.test(value)) {
    error = customMsg || 'Invalid email format';
  }

  return error;
};

export const required = (customMsg) => (value) => {
  let error;

  if (value === undefined || value === null || value === '') {
    error = customMsg || 'Required';
  }

  return error;
};

export const number = (customMsg) => (value) => {
  let error;

  if (isNaN(Number(value))) {
    error = customMsg || 'Must be a number';
  }

  return error;
};

export const minLength = (min, customMsg) => (value) => {
  let error;

  if (value.length < min) {
    error = customMsg || `Must be at least ${min} characters`;
  }

  return error;
};

export const maxLength = (max, customMsg) => (value) => {
  let error;

  if (value && value.length > max) {
    error = customMsg || `Must be no more than ${max} characters`;
  }

  return error;
};

export const moreThan = (min, customMsg) => (value) => {
  let error;

  if (Number(value) < min) {
    error = customMsg || `Can't be less than ${formatNumber(min)}`;
  }

  return error;
};

export const lessThan = (max, customMsg) => (value) => {
  let error;

  if (Number(value) > max) {
    error = customMsg || `Can't be more than ${formatNumber(max)}`;
  }

  return error;
};

export const sameAs = (otherValue, customMsg) => (value) => {
  let error;

  if (value !== otherValue) {
    error = customMsg || 'Must match';
  }

  return error;
};

/**
 * Validate the phone number if a user has started
 * entering a value (length > 0) and if there are any
 * placeholder characters (underscore)
 */
export const phone = () => (value) => {
  let error;

  if (value && value.length > 0 && value.match('_')) {
    error = 'Please enter a complete phone number';
  }

  return error;
};

export const usAddress = () => (address) => {
  let error;

  if (address.countryCode !== 'US') {
    error = "Sorry, we're only available in the United States (for now).";
  }

  return error;
};

export const strongPassword = (customMsg) => (value) => {
  const strongPasswordRegex = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\d\s:])(?=.{8,})/
  );
  let error;

  if (!strongPasswordRegex.test(value)) {
    error = customMsg || 'Password does not meet strength requirements';
  }

  return error;
};

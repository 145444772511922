import { useEffect } from 'react';

import { useScript } from '~/hooks/use-script';

// Docs (kinda): https://developers.facebook.com/docs/facebook-login/web#example

export const useFacebookSignIn = (onError) => {
  const [isLoaded, hasError] = useScript(
    'https://connect.facebook.net/en_US/sdk.js'
  );

  useEffect(() => {
    if (isLoaded && window.FB) {
      window.FB.init({
        appId: window.env.FACEBOOK_APP_ID,
        xfbml: true,
        version: 'v20.0',
      });
    }
  }, [isLoaded]);

  useEffect(() => {
    if (hasError && onError) {
      onError();
    }
  }, [hasError, onError]);
};

import React from 'react';

import CtaBanner from '~/components/cta-banner/cta-banner';
import { AutocompleteTypes } from '~/components/inputs/autocomplete-address-input/autocomplete-storage';
import { useAddressInput } from '~/hooks/use-address-input';
import { subscriptionType } from '~/features/subscriptions/subscription';
import { analyticsBeacon, EventType } from '~/utils/analytics';

const DEFAULT_THEME = {
  backgroundColor: '#ffc059',
  buttonBackgroundColor: '#007290',
  buttonFontColor: '#ffffff',
  titleColor: '#003d4e',
  footerTextColor: '#003d4e',
  accent1Color: '#00A5C4',
  accent2Color: '#0090B0',
  accent3Color: '#007290',
};

const FunnelCtaBanner = ({
  title,
  buttonText,
  footerText,
  theme = DEFAULT_THEME,
  funnelType = subscriptionType.LAWN_PLAN,
}) => {
  const {
    setAddress,
    handleContinue,
    isAddressInputVisible,
    isLoadingSubscriptions,
    isButtonDisabled,
  } = useAddressInput(funnelType);

  if (isLoadingSubscriptions) {
    return null;
  }

  const handleSubmit = async () => {
    analyticsBeacon.emit(EventType.ADDRESS_CTA_CLICKED, {
      inputId: 'shed-funnel-cta-input',
      type: AutocompleteTypes.FULL_ADDRESS,
    });

    // Let analytics event finish before continuing
    setTimeout(() => {
      handleContinue();
    }, 50);
  };

  return (
    <CtaBanner
      title={title}
      buttonText={buttonText}
      footerText={footerText}
      theme={theme}
      onAddressSelected={setAddress}
      onContinue={handleSubmit}
      isButtonDisabled={isButtonDisabled}
      showAddressInput={isAddressInputVisible}
      isButtonOnly={!isAddressInputVisible}
    />
  );
};

export default FunnelCtaBanner;

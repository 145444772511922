import { useRef } from 'react';

let ID = 0;

/**
 * Naive unique ID generator hook
 */
export const useId = () => {
  const idRef = useRef(ID++);
  return idRef.current;
};

import React, { useState } from 'react';
import cx from 'classnames';

import Button from '~/components/button';
import {
  useRequireAuth,
  AddToCartError,
} from '~/components/products/product-detail/add-product-form';
import { useCartMutations } from '~/features/cart/cart-actions';
import { useFlashMessage } from '~/features/flash-messages/flash-message-actions';
import {
  hideGladlyChat,
  showGladlyChat,
} from '~/utils/analytics/plugins/gladly';
import { captureException } from '~/utils/exception-tracking';
import { subscriptionType } from '~/features/subscriptions/subscription';
import {
  isLivePlantProduct,
  UNIQUE_CART_ITEMS,
} from '~/features/products/products';
import { REGISTRATION_SOURCES } from '~/components/modals/funnel-auth/register-modal-form';
import { useCart } from '~/hooks/use-cart';

import styles from '~/components/add-to-cart-button/add-to-cart-button.module.scss';

const AddToCartButton = ({
  channel,
  product,
  variant = 'primary',
  location,
  className,
  testId = '',
  size = '',
  fullWidth = false,
  onSuccess = async () => {},
  isFeaturedProduct = false,
  source = REGISTRATION_SOURCES.STORE,

  // Optional Algolia Fields
  objectID,
  queryID,
  position,
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const { cart } = useCart();
  const { addToCart } = useCartMutations();
  const { showFlashMessage } = useFlashMessage();

  const requireAuth = useRequireAuth({
    product,
    source: source,
  });

  const isPlanProduct = [
    subscriptionType.MOSQUITO,
    subscriptionType.TOTAL_HOME,
    subscriptionType.TICK,
    subscriptionType.LAWN_PLAN,
    subscriptionType.SPRING,
    subscriptionType.VEGETABLE,
    subscriptionType.FLOWER,
    subscriptionType.TREE_LANDSCAPE,
    subscriptionType.VEGETABLE_FLOWER,
  ].includes(product?.purchaseSku?.subscriptionType);

  if (isAdding) {
    hideGladlyChat();
  } else {
    showGladlyChat();
  }

  const handleAdd = async (e) => {
    e.preventDefault();

    const isUniqueCartItem = UNIQUE_CART_ITEMS.includes(
      product?.purchaseSku?.skuId
    );

    if (isUniqueCartItem) {
      const isUniqueCartItemInCart = cart?.contents?.some((item) =>
        UNIQUE_CART_ITEMS.includes(item.sku.skuId)
      );

      if (isUniqueCartItemInCart) {
        showFlashMessage(
          `Oops, you can only have one ${product?.productDetails?.name} in your cart at a time.`,
          'error'
        );
        return;
      }
    }

    try {
      setIsAdding(true);

      const isLawnRequired =
        product?.purchaseSku?.isLawnRequired || isLivePlantProduct(product);

      if (isLawnRequired) {
        await requireAuth({
          useZipCode: product?.purchaseSku?.isLawnRequired ? false : true,
        });
      }
    } catch (_) {
      setIsAdding(false);
      return;
    }

    try {
      setIsAdding(true);
      await addToCart(product.purchaseSku, 1, {
        isFeaturedProduct,
        isSubscription: isPlanProduct,
        objectID,
        queryID,
        position,
      });

      onSuccess();

      setIsAdding(false);
    } catch (err) {
      // Let the user know if they try to purchase a restricted item
      if (err?.errors?.includes('are not purchasable')) {
        showFlashMessage(
          `Oops, ${
            product?.purchaseSku?.title || product?.productDetails?.name
          } is restricted from sale in  ${
            err?.errors.slice(-2) || 'your state'
          }`,
          'error'
        );
        setIsAdding(false);
        return;
      }

      if (err?.errors === 'Companion items require a lawn plan in the cart') {
        showFlashMessage(
          `Oops, ${
            product?.purchaseSku?.title || product?.productDetails?.name
          } requires a lawn plan in your cart`,
          'error'
        );
        setIsAdding(false);
        return;
      }

      if (err?.errors === 'Companion items are limited to one per cart') {
        showFlashMessage(
          'You can only add one promotional item to your cart at a time',
          'error'
        );
        setIsAdding(false);
        return;
      }

      showFlashMessage(
        `Oops, there was a problem adding ${product?.productDetails?.name} to your cart`,
        'error'
      );
      setIsAdding(false);
      captureException(
        new AddToCartError(
          `Error while adding ${product?.productDetails?.name} to cart from ${location}`
        ),
        {
          extras: {
            error: JSON.stringify(err),
          },
        }
      );
    }
  };

  const isInStock = Boolean(
    product.productDetails?.inStock === true ||
      product.productDetails?.inStock === undefined
  );

  const classes = cx(styles.addToCartButton, className);

  let buttonText = 'Add to cart';

  if (!isInStock) {
    buttonText = 'Sold out';
  } else if (isPlanProduct) {
    buttonText = 'Subscribe';
  }

  return (
    <Button
      className={classes}
      variant={isInStock ? variant : 'dark'}
      fullWidth={fullWidth}
      isLoading={isAdding}
      disabled={!isInStock || product.purchaseSku.isRestrictedInState}
      onClick={(e) => handleAdd(e)}
      type="button"
      data-testid={testId}
      size={size}
    >
      {buttonText}
    </Button>
  );
};

export default AddToCartButton;

import { sanityProductByKeyQuery } from '~/sanity/products/product-queries';
import { sanity } from '~/sanity/sanity-client';

export const fetchSanityProductBySku = async (sku) => {
  const res = await sanity({
    query: sanityProductByKeyQuery,
    variables: { sku },
  });

  return res;
};

/**
 * API response for field validation errors look like:
 *
 * {
 *    errors: {
 *      email: ['that email sucks'],
 *      password: ['can not be your email address, stupid']
 *    }
 * }
 */
export const isValidationError = (response) => {
  return Boolean(response && response.errors);
};

/**
 * Accepts an API field validation error response and a field name on that response
 * and returns the error messages for that field.
 */
export const getValidationErrorMessageFor = (response, fieldName) => {
  if (
    !isValidationError(response) ||
    !Array.isArray(response.errors[fieldName])
  ) {
    return null;
  }

  return response.errors[fieldName].join('; ');
};

export const isIncorrectEmailOrPasswordError = (response) => {
  return response?.error === 'invalid_grant';
};

export const isExistingEmailError = (response) => {
  return response?.email === 'email_already_exists';
};

/**
 * Return the list of restricted SKUs from a checkout API error.
 * We don't use the validation message parsing functions here because
 * we need the array of SKUs that's returned rather than a message string.
 */
export const parseRestrictedSkusError = (response) => {
  return response?.errors?.RestrictedSKUs;
};

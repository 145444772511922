import React from 'react';
import { Link } from 'react-router-dom';

import { seedFinderRoute } from '~/routes';

import styles from '~/components/sunday-store/components/product-details/seed-finder-cta.module.scss';

const SeedFinderCta = () => {
  return (
    <div className={styles.seedFinderCta}>
      <div className={styles.imageContainer}>
        <img
          src="/images/which-seed-100w.png"
          srcSet="/images/which-seed-100w.png 1x, /images/which-seed-200w.png 2x"
          alt="A bag of Sunday seed next to an outline of an unknown bag of seed."
          width="90"
          height="75"
        />
      </div>
      <div>
        <p className={styles.bold}>Not sure what grass seed you need?</p>
        <p>
          Get a recommendation in about a minute!{' '}
          <Link to={seedFinderRoute()}>Let's go</Link>
        </p>
      </div>
    </div>
  );
};

export default SeedFinderCta;

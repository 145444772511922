import React from 'react';
import cx from 'classnames';

import styles from '~/components/carousels/carousel.module.scss';

export const carouselButtonDirection = {
  PREV: -1,
  NEXT: 1,
};

const CarouselButton = ({
  onClick,
  direction = carouselButtonDirection.NEXT,
  className,
}) => {
  const label =
    direction === carouselButtonDirection.NEXT ? 'next item' : 'previous item';

  const buttonStyle = {};
  if (direction === carouselButtonDirection.NEXT) {
    buttonStyle.marginRight = '-1.8rem'; // this value should match the horizontal padding on the `.button` class in `carousel.module.scss`
  } else {
    buttonStyle.marginLeft = '-1.8rem'; // this value should match the horizontal padding on the `.button` class in `carousel.module.scss`
  }

  const iconTransform =
    direction === carouselButtonDirection.NEXT
      ? 'rotate(-90deg)'
      : 'rotate(90deg)';

  return (
    <button
      type="button"
      title={label}
      aria-label={label}
      className={cx(styles.button, className)}
      style={buttonStyle}
      onClick={onClick}
    >
      <div className={styles.svgContainer}>
        <svg
          viewBox="0 0 12 8"
          fill="var(--gray-1)"
          height="16"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            transform: iconTransform,
          }}
        >
          <path d="m1.41 0 4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
        </svg>
      </div>
    </button>
  );
};

export default CarouselButton;

import React from 'react';
import { useRecommendations } from '@algolia/recommend-react';
import recommend from '@algolia/recommend';

import { useMediaQuery } from '~/hooks/use-media-query';
import { ALGOLIA_ARTICLES_INDEX } from '~/components/search/shared';
import {
  AlgoliaRecommendedArticles,
  SundayRecommendedArticles,
} from '~/components/the-shed/components';
import { IS_PRODUCTION } from '~/utils/environment';

/** displayed vertically on desktop and hide on mobile */
const RECOMMENDED_ARTICLE_SIMPLE = 'simple';
/** displayed horizontally on desktop and vertically on mobile */
const RECOMMENDED_ARTICLE_DEFAULT = 'default';

const algoliaRecommendClient = recommend(
  window.env.ALGOLIA_APP_ID,
  window.env.ALGOLIA_API_KEY
);

const RecommendedArticlesContainer = ({
  recommendedReading,
  articleUuid,
  recommendedArticleType = RECOMMENDED_ARTICLE_DEFAULT,
}) => {
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const { recommendations } = useRecommendations({
    model: 'related-products',
    recommendClient: algoliaRecommendClient,
    indexName: ALGOLIA_ARTICLES_INDEX,
    objectIDs: [articleUuid],
    maxRecommendations: 6,
  });

  const articlesToDisplay =
    isDesktop && recommendedArticleType === RECOMMENDED_ARTICLE_DEFAULT
      ? recommendations
      : recommendations.slice(0, 4);

  if (!recommendedReading?.length && articleUuid === '') {
    return null;
  }

  return (
    <div>
      {IS_PRODUCTION && recommendations.length > 0 ? (
        <AlgoliaRecommendedArticles
          recommendedArticles={articlesToDisplay}
          recommendedArticleType={recommendedArticleType}
        />
      ) : (
        <SundayRecommendedArticles
          articles={recommendedReading}
          recommendedArticleType={recommendedArticleType}
        />
      )}
    </div>
  );
};

export {
  RecommendedArticlesContainer,
  RECOMMENDED_ARTICLE_SIMPLE,
  RECOMMENDED_ARTICLE_DEFAULT,
};

import { signInRoute } from '~/routes';

export const getSignInUrl = (location) => {
  const redirectRoute = location?.pathname + location?.search;

  // Don't change the URL if the user is already on the Sign In page
  if (location?.pathname === signInRoute()) {
    return redirectRoute;
  }

  // Add a `redirect` param if the user is NOT on the Home page
  if (redirectRoute !== '/') {
    const queryParam = new URLSearchParams({
      redirect: redirectRoute,
    });
    return `${signInRoute()}?${queryParam.toString()}`;
  }

  return `${signInRoute()}`;
};

import { api } from '~/api';
import { cookies } from '~/utils/cookie';
import { captureException } from '~/utils/exception-tracking';

export const COOKIE_NAME = 'UTM';

const TRACKED_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
];

export const initUTMTracking = (queryParams) => {
  let hasUTMParams = false;
  const timestamp = new Date().toISOString();
  const utmData = {
    timestamp,
  };

  try {
    for (const param in queryParams) {
      const lowerCaseParam = param.toLowerCase();

      if (TRACKED_PARAMS.includes(lowerCaseParam) && queryParams[param]) {
        hasUTMParams = true;

        const utmValue = Array.isArray(queryParams[param])
          ? queryParams[param][0]
          : queryParams[param];

        // The API only allows 128 characters
        utmData[lowerCaseParam] = utmValue?.trim().slice(0, 127);
      }
    }

    if (hasUTMParams) {
      const previousUtmData = getUTMCookie();
      const newUtmData = [...previousUtmData, utmData];
      saveUTMCookie(newUtmData);
    }
  } catch (e) {
    captureException(e);
    return;
  }
};

export const sendUTMData = async () => {
  try {
    const utmData = getUTMCookie();
    await Promise.all(utmData.map((data) => api.sendUTM(data)));
    deleteUTMCookie();
    return true;
  } catch (e) {
    captureException(e);
    deleteUTMCookie();
    return false;
  }
};

function getUTMCookie() {
  try {
    return JSON.parse(cookies.get(COOKIE_NAME) || '[]');
  } catch (e) {
    captureException(e);
    return [];
  }
}

function saveUTMCookie(value) {
  try {
    cookies.set(COOKIE_NAME, JSON.stringify(value), { expires: 30 });
    return true;
  } catch (e) {
    captureException(e);
    return false;
  }
}

function deleteUTMCookie() {
  try {
    cookies.remove(COOKIE_NAME);
    return true;
  } catch (e) {
    captureException(e);
    return false;
  }
}

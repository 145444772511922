/**
 * Utility that lets us call functions before any async dependencies
 * have loaded. Useful for third-party scripts like analytics.
 *
 * If the dependencies haven't loaded yet we simply push the wrapped
 * function into a queue (ie an array), and once they load we call
 * each enqueued function. If the dependencies have loaded then we call
 * the wrapped function immediately.
 */
export const createReadyQueue = () => {
  let isReady = false;
  let queue = [];

  const runQueue = () => {
    queue.forEach((cb) => cb());
    queue = [];
    isReady = true;
  };

  const whenReady = (fn) => {
    return (...args) => {
      if (isReady) {
        return fn(...args);
      } else {
        queue.push(() => fn(...args));
      }
    };
  };

  return {
    runQueue,
    whenReady,
  };
};

import { useCallback, useEffect, useReducer, useRef } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

import { debounce } from '~/utils/functions';
import { usePrevious } from '~/hooks/use-previous';

import styles from '~/components/carousels/carousel.module.scss';

smoothscroll.polyfill();

/**
 * Hook that attaches to a container component that overflows left/right,
 * allows for a controlled `activeIndex` slide, and click-and-drag control
 * of the scroll container.
 *
 * When on mobile leverage the native overflow + css scroll-snap behavior
 * instead of faking it for maximum UX butter.
 *
 * TODO: investigate desktop inertia scrolling using `react-spring`
 * instead of "smooth" scrollTo behavior.
 * https://codesandbox.io/s/react-spring-920-beta-touch-scrolling-example-5riij?file=/src/App.tsx:726-746
 */
export const useHorizontalScroll = ({
  activeIndex,
  onActiveIndexChange,
  numSlides,
  triggerChangeOnScroll = true,
}) => {
  const ref = useRef();
  const previousActiveIndex = usePrevious(activeIndex);
  const [dragData, setDragData] = useReducer(
    (prev, next) => {
      return {
        ...prev,
        ...next,
      };
    },
    {
      isMouseDown: false,
      isDragging: false,
      startX: 0,
      scrollLeft: 0,
    }
  );

  /**
   * Mimic CSS scroll-snap behavior by realigning the scroll
   * position to the closest slide.
   *
   * If scrolled far enough right we realign to the right instead.
   */
  const scrollToActiveSlide = useCallback(() => {
    if (!ref.current) {
      return;
    }

    const { scrollLeft, offsetWidth, scrollWidth } = ref.current;

    const nextSlideScrollLeft = Math.floor(
      scrollWidth * (activeIndex / numSlides)
    );

    const fudgeFactor = 20;
    const isMovingRight = previousActiveIndex <= activeIndex;
    const leftWeCareAbout = isMovingRight ? scrollLeft : nextSlideScrollLeft;
    const isScrolledAllTheWayRight =
      offsetWidth + leftWeCareAbout >= scrollWidth - fudgeFactor;

    const nextScroll = isScrolledAllTheWayRight
      ? scrollWidth
      : nextSlideScrollLeft;

    ref.current.scrollTo({
      left: nextScroll,
      behavior: 'smooth',
    });
  }, [activeIndex, previousActiveIndex, numSlides]);

  useEffect(() => {
    if (activeIndex === null || activeIndex === undefined) {
      return;
    }
    scrollToActiveSlide();
  }, [activeIndex, scrollToActiveSlide]);

  const calculateSlideChange = () => {
    if (!ref.current) {
      return;
    }

    const { scrollLeft, scrollWidth } = ref.current;
    const initialScrollLeft = dragData.scrollLeft;
    const isIncreasing = initialScrollLeft < scrollLeft;
    const isDecreasing = scrollLeft < initialScrollLeft;
    const operation = isIncreasing ? 'ceil' : isDecreasing ? 'floor' : 'round';
    const scrollPerSlide = scrollWidth / numSlides;
    const nextIndex = Math[operation](scrollLeft / scrollPerSlide);

    if (!triggerChangeOnScroll) {
      return;
    }

    if (nextIndex !== activeIndex) {
      onActiveIndexChange(nextIndex);
    } else {
      scrollToActiveSlide();
    }
  };

  const calculateSlideChangeDebounced = debounce(calculateSlideChange, 100);

  const scrollOnDrag = (e) => {
    e.preventDefault();

    const x = e.pageX - ref.current.offsetLeft;
    const walk = (x - dragData.startX) * 1.62; // Multiply move amount by golden ratio

    // Only say we're dragging after > 12px so that clicks
    // on child items don't feel wonky
    if (Math.abs(walk) > 12) {
      setDragData({
        isDragging: true,
      });
    }

    ref.current.scrollLeft = dragData.scrollLeft - walk;
  };

  const handleMouseEvent = (e, type) => {
    switch (type) {
      case 'WHEEL':
        const isHorizontalScroll = Math.abs(e.deltaX);
        if (isHorizontalScroll) {
          calculateSlideChangeDebounced(e);
        }
        break;
      case 'MOUSE_DOWN':
        // Helps prevent focus/active state on children on initial click for click-to-drag
        e.preventDefault();
        e.stopPropagation();
        setDragData({
          isMouseDown: true,
          startX: e.pageX - ref.current.offsetLeft,
          scrollLeft: ref.current.scrollLeft,
        });
        return;
      case 'MOUSE_MOVE':
        if (!dragData.isMouseDown) {
          return;
        }
        scrollOnDrag(e);
        return;
      case 'MOUSE_LEAVE':
        // If we were dragging and the mouse exits the element
        // then snap to a slide
        if (dragData.isDragging) {
          calculateSlideChange();
          setDragData({ isDragging: false, isMouseDown: false });
        }
        return;
      case 'MOUSE_UP':
        calculateSlideChange();
        return;
      case 'CLICK_CAPTURE':
        // Since this is a { capture: true } event this will prevent
        // firing click events for child elements (like buttons and anchors)
        if (dragData.isDragging) {
          e.stopPropagation();
          e.preventDefault();
        }
        setDragData({ isMouseDown: false, isDragging: false });
        return;
      default:
        return;
    }
  };

  return {
    ref,
    bind: {
      className: styles.horizontalScroll,
      onWheel: (e) => handleMouseEvent(e, 'WHEEL'),
      onMouseLeave: (e) => handleMouseEvent(e, 'MOUSE_LEAVE'),
      onMouseDown: (e) => handleMouseEvent(e, 'MOUSE_DOWN'),
      onMouseMove: (e) => handleMouseEvent(e, 'MOUSE_MOVE'),
      onMouseUp: (e) => handleMouseEvent(e, 'MOUSE_UP'),
      onClickCapture: (e) => handleMouseEvent(e, 'CLICK_CAPTURE'),
    },
  };
};

import React from 'react';
import { useQueryClient } from 'react-query';

import IncrementorField from '~/components/inputs/incrementor-field';
import Button from '~/components/button';
import { Field, Form } from '~/components/form';
import { AuthType } from '~/components/modals/funnel-auth/funnel-auth-modal';
import { AutocompleteTypes } from '~/components/inputs/autocomplete-address-input/autocomplete-storage';
import { api } from '~/api';
import { useSession } from '~/features/session/session-actions';
import { useModal } from '~/features/modals/modal-actions';
import { useCartMutations } from '~/features/cart/cart-actions';
import { useFlashMessage } from '~/features/flash-messages/flash-message-actions';
import { seedFinderRoute, sundayStoreProductDetailsRoute } from '~/routes';
import { useLawn } from '~/features/lawns/lawn-actions';
import { useCart } from '~/hooks/use-cart';
import { subscriptionType } from '~/features/subscriptions/subscription';
import { analyticsBeacon, DeprecatedEventType } from '~/utils/analytics';
import { captureException } from '~/utils/exception-tracking';
import getChannelAndPrice from '~/utils/get-channel-price';
import { centsToCurrency } from '~/utils/numbers';
import { required, valid } from '~/utils/validation';
import { useId } from '~/hooks/use-id';
import {
  isLivePlantProduct,
  canSeeQtyField,
  UNIQUE_CART_ITEMS,
} from '~/features/products/products';
import { REGISTRATION_SOURCES } from '~/components/modals/funnel-auth/register-modal-form';

import styles from '~/components/products/product-detail/add-product-form.module.scss';

const AddProductForm = ({
  afterSubmit,
  channel,
  productDetails,
  purchaseSku,
  error,
  ctaText,
  source,
  onFieldChange,
  qtySelected = 1,
  objectID,
  queryID,
  position,
}) => {
  const queryClient = useQueryClient();
  const { showFlashMessage } = useFlashMessage();
  const { addToCart } = useCartMutations();
  const { cart } = useCart();
  const id = useId();

  const isInStock = Boolean(
    (productDetails?.inStock === true ||
      productDetails?.inStock === undefined) &&
      !error
  );

  const requireAuth = useRequireAuth({
    product: { purchaseSku, productDetails },
    source: source,
  });

  const isPlanProduct = [
    subscriptionType.MOSQUITO,
    subscriptionType.TOTAL_HOME,
    subscriptionType.TICK,
    subscriptionType.LAWN_PLAN,
    subscriptionType.SPRING,
    subscriptionType.VEGETABLE,
    subscriptionType.FLOWER,
    subscriptionType.TREE_LANDSCAPE,
    subscriptionType.VEGETABLE_FLOWER,
  ].includes(purchaseSku?.subscriptionType);

  const hasUniqueCartItems = cart?.contents.find((item) => {
    return (
      purchaseSku.skuId === item.purchaseSku.skuId &&
      UNIQUE_CART_ITEMS.includes(item.purchaseSku.skuId)
    );
  });

  const handleSubmit = async (formValues) => {
    if (hasUniqueCartItems) {
      showFlashMessage(
        `It looks like ${productDetails.name} is already in your cart`,
        'error'
      );
      return;
    }
    const isLawnRequired =
      purchaseSku?.isLawnRequired ||
      isLivePlantProduct({ purchaseSku, productDetails });

    let latestCart = null;

    if (isLawnRequired) {
      try {
        await requireAuth({
          useZipCode: purchaseSku?.isLawnRequired ? false : true,
        });
      } catch (_) {
        return null;
      }

      try {
        latestCart = await api.cart.find();
        queryClient.setQueryData('cart', latestCart);
      } catch (err) {
        captureException(new Error('Failed to find cart - lawn required ATC'), {
          extras: {
            error: JSON.stringify(err),
          },
        });
        return null;
      }
    }

    try {
      await addToCart(purchaseSku, formValues.quantity, {
        objectID,
        queryID,
        position,
        isSubscription: isPlanProduct,
        cartUuidToUse: latestCart?.uuid,
      });

      if (afterSubmit) {
        afterSubmit(formValues.quantity);
      }
    } catch (err) {
      let errorMessage =
        err.errors ||
        `There was a problem adding ${productDetails.name} to your cart`;

      if (err?.errors?.includes('are not purchasable')) {
        errorMessage = `Oops, ${
          purchaseSku?.title || productDetails?.name
        } is restricted from sale in  ${err?.errors.slice(-2) || 'your state'}`;
      }

      showFlashMessage(errorMessage, 'error');
      captureException(new AddToCartError('Product Details Page'), {
        extras: {
          error: JSON.stringify(err),
        },
      });
    }

    return null;
  };

  const validate = (formValues) => {
    const errors = {};
    if (formValues.quantity < 1) {
      errors.quantity = valid(required('Please select plan'))(
        formValues.quantity
      );
    }

    return errors;
  };

  const canEditQuantity = canSeeQtyField(
    productDetails,
    isPlanProduct,
    isInStock
  );

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={{ quantity: qtySelected }}
    >
      {({ isSubmitting }) => (
        <>
          <div className={styles.mobilePriceInputGrid}>
            <Price
              channel={channel}
              qtySelected={qtySelected}
              productDetails={productDetails}
              purchaseSku={purchaseSku}
            />

            {canEditQuantity && (
              <Field
                aria-label="quantity"
                id={`quantityMobile-${id}`}
                name="quantity"
                component={IncrementorField}
                onChange={onFieldChange}
                value={qtySelected}
              />
            )}
          </div>

          <div className={styles.submitButtonGrid}>
            {error && (
              <p className={styles.unavailable}>
                Not available for purchase at this time
              </p>
            )}

            {!isInStock && !error && (
              <Button
                className={styles.submitButton}
                variant="dark"
                fullWidth
                disabled
              >
                Sold out
              </Button>
            )}

            {isInStock && (
              <>
                {canEditQuantity && (
                  <div className={styles.selectWrapper}>
                    <Field
                      aria-label="quantity"
                      id={`quantityDesktop-${id}`}
                      name="quantity"
                      component={IncrementorField}
                      onChange={onFieldChange}
                      value={qtySelected}
                    />
                  </div>
                )}

                <Button
                  className={styles.submitButton}
                  type="submit"
                  variant="primary"
                  isLoading={isSubmitting}
                  fullWidth
                  disabled={purchaseSku?.isRestrictedInState}
                >
                  {isPlanProduct ? 'Subscribe' : ctaText || 'Add to cart'}
                </Button>
              </>
            )}
          </div>

          {!isInStock && productDetails.outOfStockMessage && (
            <p className={styles.outOfStock}>
              {productDetails.outOfStockMessage}
            </p>
          )}
        </>
      )}
    </Form>
  );
};

export default AddProductForm;

const Price = ({ channel, productDetails, purchaseSku }) => {
  if (!purchaseSku) {
    return null;
  }

  const { unitPrice, fullPrice } = getChannelAndPrice(channel, {
    productDetails,
    purchaseSku,
  });

  const regularPrice = (
    <p className={styles.price}>
      {centsToCurrency(fullPrice, { round: true })}{' '}
    </p>
  );

  const discountPrice = (
    <div>
      <p className={styles.price}>
        <span className={styles.salePrice}>
          {centsToCurrency(unitPrice, { round: true })}
        </span>
        <span className={styles.fullPrice}>
          {centsToCurrency(fullPrice, { round: true })}
        </span>
      </p>
    </div>
  );

  if (productDetails.inStock === false) {
    return regularPrice;
  } else if (unitPrice === fullPrice) {
    return regularPrice;
  } else return discountPrice;
};

export function useRequireAuth({ product, source } = {}) {
  const { showModal } = useModal();
  const { user } = useSession();
  const { lawn } = useLawn();

  const productDetails = product?.productDetails;

  const requireAuth = ({ useZipCode } = {}) => {
    const requireLawn =
      isLivePlantProduct(product) || product?.purchaseSku?.isLawnRequired;

    return new Promise((resolve, reject) => {
      if (requireLawn && Boolean(user) && Boolean(lawn)) {
        resolve(user);
        return;
      } else if (!requireLawn && Boolean(user)) {
        resolve(user);
        return;
      }

      // If user wants to add a plant product or a la carte Pest Plan to cart we need their address first, otherwise normal auth modal flow
      if (requireLawn) {
        showModal('STORE_AUTH', {
          magicLinkRedirectUrl: sundayStoreProductDetailsRoute({
            category: productDetails?.categories?.[0]?.category?.slug,
            slug: productDetails?.slug,
          }),
          onAuth: () => {
            resolve();
          },
          onClose: () => {
            reject();
          },
          type: useZipCode
            ? AutocompleteTypes.ZIP_CODE
            : AutocompleteTypes.FULL_ADDRESS,
        });
      } else if (source === REGISTRATION_SOURCES.SEED_FINDER) {
        showModal('FUNNEL_AUTH', {
          magicLinkRedirectUrl: seedFinderRoute(),
          content: {
            registerHeading: 'Save your results!',
            registerCopy:
              "You'll need an account to continue. Enter your email address and we'll create one for you, or help you sign in if you already have one.",
          },
          onAuth: (userData) => {
            resolve(userData);
          },
          source: source,
          onClose: () => {
            reject();
          },
        });
      } else {
        showModal('FUNNEL_AUTH', {
          magicLinkRedirectUrl: sundayStoreProductDetailsRoute({
            category: productDetails?.categories?.[0]?.category?.slug,
            slug: productDetails?.slug,
          }),
          content: {
            registerHeading: 'Real quick',
            registerCopy:
              "You'll need an account to continue. Enter your email address and we'll create one for you, or help you sign in if you already have one.",
          },
          onAuth: (type, userData) => {
            if (type === AuthType.REGISTER) {
              analyticsBeacon.emit(DeprecatedEventType.LEAD_STORE);
            }

            resolve(userData);
          },
          source: source,
          onClose: () => {
            reject();
          },
        });
      }
    });
  };

  return requireAuth;
}

export class AddToCartError extends Error {
  constructor(message) {
    super(message);
    this.name = 'AddToCartError';
  }
}

export const createEventEmitter = ({ logger }) => {
  const listeners = {};

  const on = (e, cb) => {
    (listeners[e] = listeners[e] || []).push(cb);
  };

  const emit = (e, ...args) => {
    logger.groupCollapsed(e, '|', ...args);
    (listeners[e] || []).forEach((cb) => {
      cb(...args);
    });
    logger.groupEnd(e, '|', ...args);
  };

  return {
    on,
    emit,
  };
};

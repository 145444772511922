import React, { useState } from 'react';
import cx from 'classnames';

import styles from './inputs.module.scss';

const PasswordField = (props) => {
  const [inputType, setInputType] = useState('password');
  const hasError = props.meta.submitFailed && props.meta.error;
  const placeholder = props.input.placeholder || props.label;
  const { mask, ...inputProps } = props.input;

  const labelClasses = cx(styles.label, {
    [styles.labelError]: hasError,
  });

  const containerClasses = cx(styles.passwordContainer, {
    [styles.inputError]: hasError,
  });

  const inputClasses = cx(
    styles.input,
    styles.passwordInput,
    props.input.className,
    {
      [styles.inputError]: hasError,
    }
  );

  const toggle = () => {
    if (inputType === 'password') {
      setInputType('text');
    } else {
      setInputType('password');
    }
  };

  return (
    <div>
      <label htmlFor={props.input.name} className={labelClasses}>
        {props.label}
      </label>

      <div className={containerClasses}>
        <input
          {...inputProps}
          type={inputType}
          id={props.input.name}
          className={inputClasses}
          placeholder={placeholder}
          disabled={inputProps.disabled}
        />

        <button
          className={styles.passwordShowButton}
          type="button"
          onClick={toggle}
        >
          {inputType === 'password' ? 'show' : 'hide'}
        </button>
      </div>

      {hasError && <p className={styles.errorMsg}>{props.meta.error}</p>}
    </div>
  );
};

export default PasswordField;

export const formatNumber = (num) => {
  if (isNull(num)) {
    return null;
  }

  return num.toLocaleString('en-US');
};

export const toCurrency = (num, options = {}) => {
  const { round, removeDollarSign } = options;

  // Explicit check instead of falsy check to allow for '0'
  if (isNull(num)) {
    return null;
  }

  const hasCents = Math.round(num) !== num;
  const shouldRound = round && !hasCents;
  const fractionDigits = shouldRound ? 0 : 2;

  const result = num.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

  if (removeDollarSign) {
    return result.slice(1);
  }

  return result;
};

export const centsToCurrency = (cents, options = {}) => {
  // Explicit check instead of falsy check to allow for '0'
  if (isNull(cents)) {
    return null;
  }

  return toCurrency(cents / 100, options);
};

/** Converts an integer value (in pennies) to it's corresponding float value */
export const centsToFloat = (cents) => {
  // Explicit check instead of falsy check to allow for '0'
  if (isNull(cents)) {
    return null;
  }

  const dollars = cents / 100;

  return Number(dollars.toFixed(2));
};

/**
 * Given a number returns a string of the number with it's
 * ordinal suffix (1st, 2nd, 3rd, etc)
 *
 * @param {Number} num
 */
export const ordinalSuffix = (num) => {
  const modTen = num % 10;
  const modOneHundred = num % 100;

  switch (true) {
    case modTen === 1 && modOneHundred !== 11:
      return 'st';
    case modTen === 2 && modOneHundred !== 12:
      return 'nd';
    case modTen === 3 && modOneHundred !== 13:
      return 'rd';
    default:
      return 'th';
  }
};

export const Ordinals = {
  1: 'first',
  2: 'second',
  3: 'third',
  4: 'fourth',
  5: 'fifth',
  6: 'sixth',
  7: 'seventh',
  8: 'eighth',
  9: 'ninth',
  10: 'tenth',
};

function isNull(val) {
  return val === undefined || val === null;
}

import React from 'react';

import { urlFor } from '~/sanity/images';

import styles from '~/components/products/products.module.scss';

const SeedSpecIcons = ({ icons }) => {
  return (
    <div className={styles.seedSpecIcons}>
      {icons?.map((icon) => (
        <div key={icon.caption} className={styles.seedSpecIcon}>
          {icon?.image && (
            <img
              className={styles.seedSpecIconImage}
              src={urlFor(icon.image).maxHeight(60).url()}
              alt=""
              width="100"
              height="60"
            />
          )}
          <div className={styles.seedSpecIconText}>{icon.caption}</div>
        </div>
      ))}
    </div>
  );
};

export default SeedSpecIcons;

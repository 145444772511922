import { addressApi } from '~/api/address-api';
import { cancellationsApi } from '~/api/cancellations-api';
import { cartApi } from '~/api/cart-api';
import { createHttpClient } from '~/utils/http-client';
import { dynamicFormsApi } from '~/api/dynamic-forms-api';
import { getSavedSession } from '~/features/session/session';
import { jobListingsApi } from '~/api/job-listings-api';
import { lawnsApi } from '~/api/lawns-api';
import { paymentMethodsApi } from '~/api/payment-methods-api';
import { productsApi } from '~/api/products-api';
import { providersApi } from '~/api/providers-api';
import { purchaseOrdersApi } from '~/api/purchase-orders-api';
import { radialApi } from '~/api/radial-api';
import { sessionsApi } from '~/api/sessions-api';
import { shipmentsApi } from '~/api/shipments-api';
import { shippingMessagesApi } from '~/api/shipping-messages-api';
import { soilTestApi } from '~/api/soil-test-api';
import { subscriptionsApi } from '~/api/subscriptions-api';
import { tasksApi } from '~/api/tasks-api';
import { usersApi } from '~/api/users-api';
import { attentiveApi } from '~/utils/analytics/plugins/attentive';
import { recommendationsApi } from '~/api/recommendations-api';
import { brownSpotApi } from '~/api/brown-spot-api';
import { lawnProblemChatApi, alwaysBeClosingChatApi } from '~/api/chat-api';
import { geolocationApi } from '~/api/geolocation-api';

export const createApi = ({ subsoilHttpClient, topsoilHttpClient }) => {
  return {
    http: subsoilHttpClient,
    attentive: attentiveApi(subsoilHttpClient),
    addresses: addressApi(subsoilHttpClient),
    brownSpot: brownSpotApi(topsoilHttpClient),
    cancellations: cancellationsApi(subsoilHttpClient),
    cart: cartApi(subsoilHttpClient),
    alwaysBeClosingChat: alwaysBeClosingChatApi(topsoilHttpClient),
    lawnProblemChat: lawnProblemChatApi(topsoilHttpClient),
    dynamicForms: dynamicFormsApi(subsoilHttpClient),
    geolocation: geolocationApi(topsoilHttpClient),
    jobListings: jobListingsApi(subsoilHttpClient),
    lawns: lawnsApi(subsoilHttpClient),
    paymentMethods: paymentMethodsApi(subsoilHttpClient),
    products: productsApi(subsoilHttpClient, topsoilHttpClient),
    providers: providersApi(subsoilHttpClient),
    purchaseOrders: purchaseOrdersApi(subsoilHttpClient),
    radial: radialApi(subsoilHttpClient),
    recommendations: recommendationsApi(subsoilHttpClient),
    sessions: sessionsApi(subsoilHttpClient),
    shipments: shipmentsApi(subsoilHttpClient),
    shippingMessages: shippingMessagesApi(subsoilHttpClient),
    soilTest: soilTestApi(subsoilHttpClient),
    subscriptions: subscriptionsApi(subsoilHttpClient),
    tasks: tasksApi(subsoilHttpClient),
    users: usersApi(subsoilHttpClient),

    sendUTM: async (data) => {
      return subsoilHttpClient.post('/api/urchin_tags/', data);
    },

    setErrorListener: (cb) => {
      subsoilHttpClient.errorListeners.push(cb);
    },
  };
};

// Include cookies for all subsoil requests
const credentialTransformer = (request) => {
  return {
    ...request,
    credentials: 'include',
  };
};

const authTransformer = async (request) => {
  const session = await getSavedSession();

  if (!session || !session.token) {
    return request;
  }

  return {
    ...request,
    headers: {
      Authorization: `Bearer ${session.token}`,
      ...request.headers,
    },
  };
};

const subsoilHttpClient = createHttpClient({
  baseUrl: window.env.API_URL,
  requestTransformers: [credentialTransformer, authTransformer],
});

const topsoilHttpClient = createHttpClient({
  baseUrl: '',
});

export const api = createApi({
  subsoilHttpClient,
  topsoilHttpClient,
});

if (window.env.DEPLOY_ENV !== 'production') {
  window.api = api;
}

import imageUrlBuilder from '@sanity/image-url';

const DEFAULT_IMAGE = '/images/unboxing-right-1000w.png';

let DEPLOY_ENV = 'production';

if (typeof process !== 'undefined') {
  DEPLOY_ENV = process.env.DEPLOY_ENV ?? 'production';
} else if (typeof window !== 'undefined') {
  DEPLOY_ENV = window.env.DEPLOY_ENV ?? 'production';
}

const builder = imageUrlBuilder({
  dataset: DEPLOY_ENV === 'production' ? 'production' : 'develop',
  projectId: 'afv8yn22',
});

export const generatePDPImageLinks = async ({ request, fetch }) => {
  const slug = request.params.slug;
  const product = await (
    await fetch(`${process.env.SITE_URL}/bff/api/products/${slug}?images=true`)
  ).json();

  const primaryImageUrl =
    product?.primaryImage &&
    builder
      .image(product.primaryImage)
      .width(640)
      .height(640)
      .dpr(2)
      .fit('crop')
      .auto('format')
      .url();

  const primaryImageUrlMobile =
    product?.primaryImage &&
    builder
      .image(product.primaryImage)
      .width(375)
      .height(375)
      .dpr(2)
      .fit('crop')
      .auto('format')
      .url();

  const carouselImagePreloads = product?.images?.flatMap((image) => {
    if (!image) {
      return null;
    }

    const imageUrl = builder
      .image(image)
      .width(640)
      .height(640)
      .dpr(2)
      .fit('crop')
      .auto('format')
      .url();

    const imageUrlMobile = builder
      .image(image)
      .width(375)
      .height(375)
      .dpr(2)
      .fit('crop')
      .auto('format')
      .url();

    return [
      {
        rel: 'preload',
        as: 'image',
        media: '(min-width: 768.1px)',
        href: imageUrl,
      },
      {
        rel: 'preload',
        as: 'image',
        media: '(max-width: 768px)',
        href: imageUrlMobile,
      },
    ];
  });

  return [
    {
      rel: 'preload',
      as: 'image',
      href: primaryImageUrl || DEFAULT_IMAGE,
      media: '(min-width: 768.1px)',
    },
    {
      rel: 'preload',
      as: 'image',
      href: primaryImageUrlMobile || DEFAULT_IMAGE,
      media: '(max-width: 768px)',
    },
    ...(carouselImagePreloads || []).filter(Boolean),
  ];
};

import { useEffect, useRef } from 'react';

export const useDocumentHead = (
  tagName,
  attributes,
  { enabled = true } = {}
) => {
  const element = useRef();

  const stringifiedAttributes = JSON.stringify(attributes);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    let el;

    if (!element.current) {
      el = document.createElement(tagName);
      element.current = el;
    } else {
      el = element.current;
    }

    for (const attr in attributes) {
      if (attr === 'textContent') {
        el.textContent = attributes[attr];
      } else {
        el.setAttribute(attr, attributes[attr]);
      }
    }

    if (!document.head.contains(el)) {
      document.head.appendChild(el);
    }

    return () => {
      if (document.head.contains(element.current)) {
        document.head.removeChild(element.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagName, stringifiedAttributes]);
};

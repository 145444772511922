export const subscriptionsApi = (http) => {
  return {
    find: async (userUuid) => {
      return http.get(`/api/users/${userUuid}/subscriptions/`);
    },
    findForLawn: async (lawnUuid) => {
      return http.get(`/api/lawns/${lawnUuid}/subscriptions`);
    },
    getDetailsByUuid: async (subscriptionUuid) => {
      return http.get(`/api/subscriptions/${subscriptionUuid}`);
    },
    update: async (subscriptionUuid, updatedFields) => {
      /** `updatedField` can contain:
       * `name` - the name of the subscription (string)
       * `subscriptionItems` - array of objects that each contain: `sku_id` (string) and `quantity` (integer)
       */
      return http.patch(
        `/api/subscriptions/${subscriptionUuid}`,
        updatedFields
      );
    },
    renewSubscription: async (subscriptionUuid, couponCode = '') => {
      const reqBody = couponCode ? { coupon_code: couponCode } : {};

      return http.patch(
        `/api/subscriptions/${subscriptionUuid}/renew/`,
        reqBody
      );
    },
    simulateRenewal: async (subscriptionUuid) => {
      return http.get(
        `/api/v2/subscriptions/${subscriptionUuid}/simulate_renewal/`
      );
    },
    cancelSubscription: async (subscriptionUuid) => {
      return http.delete(`/api/subscriptions/${subscriptionUuid}/cancel/`);
    },
  };
};

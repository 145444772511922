import React from 'react';
import { Link } from 'react-router-dom';

import Button from '~/components/button';
import SubNavContent from '~/components/navigation/sub-nav-content';
import { PEST_PLAN_FLAG } from '~/feature-flags';
import { customPestPlanRoute } from '~/routes';

import styles from '~/components/navigation/mobile/nav-sub-menus.module.scss';

const MobileSubNavMenu = ({ closeOnClick, toggleSubNav, selectedMenu }) => {
  const menuContent = SubNavContent[selectedMenu];

  return (
    <div className={styles.subNav}>
      <div className={styles.subNavHeader}>
        <Button
          className={styles.headerButton}
          onClick={() => toggleSubNav('')}
          aria-label="Back"
        >
          <div className={styles.arrowIcon}>
            <svg
              width="16"
              height="12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.88.453L8 6.56 14.12.453 16 2.333l-8 8-8-8L1.88.453z"
                fill="#25282B"
              />
            </svg>
          </div>
          {menuContent?.headerText ?? '\u00a0'}
        </Button>
        {menuContent?.headerSubText && (
          <p className={styles.headerSubText}>{menuContent?.headerSubText}</p>
        )}
      </div>

      <div className={styles.subNavBody}>
        <p className={styles.bodySubHeader}>{menuContent?.section1.title}</p>

        {menuContent?.section1 && (
          <ul className={styles.linksList}>
            {menuContent?.section1.links.map((link) =>
              !PEST_PLAN_FLAG && link.route === customPestPlanRoute() ? null : (
                <li key={link.heapId}>
                  <Link
                    className={styles.basicLink}
                    data-heap-id={link.heapId}
                    onClick={closeOnClick}
                    to={link.route}
                  >
                    {link.text}
                  </Link>
                </li>
              )
            )}
          </ul>
        )}

        <p className={styles.bodySubHeader}>{menuContent?.section2.title}</p>

        {menuContent?.section2 && (
          <ul className={styles.linksList}>
            {menuContent?.section2.customContent?.map((item) => (
              <li key={item.text} className={styles.customItem}>
                <img
                  alt={item.altText}
                  className={styles.customItemIcon}
                  height={item.height}
                  src={item.icon}
                  width={item.width}
                />
                <p className={styles.customItemText}>{item.text}</p>
              </li>
            ))}

            {menuContent?.section2.links?.map((link) => (
              <li key={link.heapId}>
                <Link
                  className={styles.basicLink}
                  data-heap-id={link.heapId}
                  onClick={closeOnClick}
                  style={{ color: '#6E7370' }}
                  to={link.route}
                >
                  {link.text}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export { MobileSubNavMenu };

export const PROMO_BANNER_QUERY = `
*[_type == "promoBanner" && slug.current == $slug] | order(_updatedAt desc) {
  name,
  "slug": slug.current,
  headlineText,
  bodyText,
  promoCode,
  template,
  backgroundColor,
  image,
  pageConfig,
}[0]
`;

export const PROMO_BANNERS_QUERY = `
*[_type == "promoBanner"] | order(_updatedAt desc) {
  name,
  "slug": slug.current,
  headlineText,
  bodyText,
  promoCode,
  template,
  backgroundColor,
  image,
  pageConfig,
}
`;

export const topBannerQuery = (slug = '') => `
*[_type == "topBanner" &&  ${slug ? "slug.current == $slug" : 'enabled == true'}] {
  enabled,
  name,
  "slug": slug.current,
  text,
  useCountDownTimer,
  promoEndDate,
  promoCode,
  backgroundColor,
  fontColor,
  terms,
}[0]
`
import React from 'react';

import { ErrorIcon } from './flash-messages';

import styles from '~/components/messages/alert-message.module.scss';

/**
 * An <AlertMessage /> is a banner style component meant to stay static on the screen with important messages
 */
const AlertMessage = ({ style, heading, children }) => {
  return (
    <div className={styles.container} style={style}>
      {heading && (
        <div className={styles.heading}>
          <span className={styles.icon}>
            <ErrorIcon />
          </span>
          {heading}
        </div>
      )}

      {children}
    </div>
  );
};

export default AlertMessage;

import { useQuery } from 'react-query';

import { api } from '~/api';

export const useShippingMessages = (skuIds) => {
  const { errorMessage, data: shippingMessages } = useQuery(
    ['shippingMessages'],
    () => api.shippingMessages.fetchShippingMessages()
  );

  const { isLoading: isLoadingProviders, data: providers } = useQuery(
    ['providers', skuIds],
    () => api.providers.findProviders([skuIds]),
    {
      enabled: Boolean(skuIds),
    }
  );

  // Basic lawn bundles don't return providers so hardcoding Radial
  // TODO: change string if Radial goes away
  // Providers can return as an empty object

  const shippingMessage =
    providers !== undefined && Object.keys(providers).length > 0
      ? shippingMessages?.find(
          (message) => message?.provider === providers?.[skuIds]?.[0]?.provider
        )
      : shippingMessages?.find((message) => message?.provider === 'rad');

  return {
    isLoadingProviders,
    shippingMessage,
    errorMessage,
  };
};

import React from 'react';

import { toSentenceCase } from '~/utils/strings';

import styles from '~/components/sunday-store/components/product-details/plant-components.module.scss';

const plantIconMapping = {
  sunlightExposure: '/icons/sunday-store/sun-outline-icon.svg',
  growthRate: '/icons/sunday-store/leaves-growing-outline-icon.svg',
  dimensions: '/icons/sunday-store/two-leaves-outline-icon.svg',
  bloomSeason: '/icons/sunday-store/flower-outline-icon.svg',
  bloomColor: '/icons/sunday-store/paint-swatch-outline-icon.svg',
  box: '/icons/sunday-store/shipping-box-icon.svg',
};

const PlantInfoList = ({ plantInfo }) => {
  if (!plantInfo) {
    return null;
  }

  return (
    <ul className={styles.plantInfoContainer}>
      <li className={styles.plantInfoItem}>
        <div className={styles.plantIconContainer}>
          <img
            className={styles.plantIcon}
            src={plantIconMapping.sunlightExposure}
            alt="Sun"
            width="29"
            height="29"
          />
        </div>
        <div className={styles.plantInfoTextContainer}>
          <p className={styles.plantInfoTitle}>Sunlight exposure:</p>
          <p className={styles.plantInfoData}>
            {toSentenceCase(plantInfo.sunlightExposure)}
          </p>
        </div>
      </li>
      <li className={styles.plantInfoItem}>
        <div className={styles.plantIconContainer}>
          <img
            className={styles.plantIcon}
            src={plantIconMapping.growthRate}
            alt="Leaves on a plant"
            width="29"
            height="29"
          />
        </div>
        <div className={styles.plantInfoTextContainer}>
          <p className={styles.plantInfoTitle}>Growing Rate:</p>
          <p className={styles.plantInfoData}>
            {toSentenceCase(plantInfo.growthRate)}
          </p>
        </div>
      </li>
      <li className={styles.plantInfoItem}>
        <div className={styles.plantIconContainer}>
          <img
            className={styles.plantIcon}
            src={plantIconMapping.dimensions}
            alt="Two leaves side by side"
            width="29"
            height="29"
          />
        </div>
        <div className={styles.plantInfoItemInner}>
          <div className={styles.plantInfoTextContainer}>
            <p className={styles.plantInfoTitle}>Mature height:</p>
            <p className={styles.plantInfoData}>{plantInfo.matureHeight}.</p>
          </div>
          <div className={styles.plantInfoTextContainer}>
            <p className={styles.plantInfoTitle}>Mature width:</p>
            <p className={styles.plantInfoData}>{plantInfo.matureWidth}.</p>
          </div>
        </div>
      </li>
      {(plantInfo.shippedHeight || plantInfo.shippedWidth) && (
        <li className={styles.plantInfoItem}>
          <div className={styles.plantIconContainer}>
            <img
              className={styles.plantIcon}
              src={plantIconMapping.box}
              alt="Two leaves side by side"
              width="29"
              height="29"
            />
          </div>
          <div className={styles.plantInfoItemInner}>
            {plantInfo.shippedHeight && (
              <div className={styles.plantInfoTextContainer}>
                <p className={styles.plantInfoTitle}>Shipped height:</p>
                <p className={styles.plantInfoData}>
                  {plantInfo.shippedHeight}.
                </p>
              </div>
            )}
            {plantInfo.shippedWidth && (
              <div className={styles.plantInfoTextContainer}>
                <p className={styles.plantInfoTitle}>Shipped width:</p>
                <p className={styles.plantInfoData}>
                  {plantInfo.shippedWidth}.
                </p>
              </div>
            )}
          </div>
        </li>
      )}
      {plantInfo.simplifiedBloomSeason?.length > 0 && (
        <li className={styles.plantInfoItem}>
          <div className={styles.plantIconContainer}>
            <img
              className={styles.plantIcon}
              src={plantIconMapping.bloomSeason}
              alt="Flower"
              width="29"
              height="29"
            />
          </div>
          <div className={styles.plantInfoTextContainer}>
            <p className={styles.plantInfoTitle}>Bloom season:</p>
            <p className={styles.plantInfoData}>
              {toSentenceCase(plantInfo.simplifiedBloomSeason.join(', '))}
            </p>
          </div>
        </li>
      )}
      <li className={styles.plantInfoItem}>
        <div className={styles.plantIconContainer}>
          <img
            className={styles.plantIcon}
            src={plantIconMapping.bloomColor}
            alt="Color swatches"
            width="29"
            height="29"
          />
        </div>
        <div className={styles.plantInfoItemInner}>
          {plantInfo.bloomColor && (
            <div className={styles.plantInfoTextContainer}>
              <p className={styles.plantInfoTitle}>Bloom color:</p>
              <p className={styles.plantInfoData}>
                {toSentenceCase(plantInfo.bloomColor)}
              </p>
            </div>
          )}
          <div className={styles.plantInfoTextContainer}>
            <p className={styles.plantInfoTitle}>Foliage color:</p>
            <p className={styles.plantInfoData}>
              {toSentenceCase(plantInfo.foliageColor)}
            </p>
          </div>
        </div>
      </li>
    </ul>
  );
};

export { PlantInfoList };

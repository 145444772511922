import React from 'react';
import ReactModal from 'react-modal';
import cx from 'classnames';

import styles from './modal.module.scss';

const isNotTestEnv = process.env.NODE_ENV !== 'test';

if (isNotTestEnv) {
  ReactModal.setAppElement('#root');
}

const Modal = ({ onClose, isOpen, allowClose = true, children, className }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      closeTimeoutMS={500}
      className={cx(styles.modal, className)}
      style={{
        overlay: {
          background: 'rgba(0,0,0,0.67)',
          zIndex: 9147483999, // when modal at top of screen, place over header
        },
      }}
      ariaHideApp={isNotTestEnv}
      shouldCloseOnOverlayClick={allowClose}
      shouldCloseOnEsc={allowClose}
    >
      {allowClose && (
        <button className={styles.close} onClick={onClose}>
          <svg
            width="45"
            height="45"
            viewBox="0 0 55 55"
            xmlns="http://www.w3.org/2000/svg"
            stroke="var(--dark-color)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="var(--light-color)"
          >
            <path d="M 19 19 L 35 35" />
            <path d="M 35 19 L 19 35" />
          </svg>
          <p>Close button</p>
        </button>
      )}

      {children}
    </ReactModal>
  );
};

export default Modal;

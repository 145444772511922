import queryString from 'query-string';

const deletePreviewToken = () => {
  window.sessionStorage.removeItem('preview');
};

const setPreviewToken = (token) => {
  window.sessionStorage.setItem('preview', token);
};

// The token needed to fetch "draft" data from Sanity
// is sent via the `preview` query parameter when authors
// click the "Open preview" menu link.
const params = queryString.parse(window.location.search);
if (params.preview) {
  setPreviewToken(params.preview);
}

export const getPreviewToken = () => {
  return window.sessionStorage.getItem('preview');
};

export const isPreviewMode = Boolean(getPreviewToken());

export const exitPreviewMode = () => {
  deletePreviewToken();

  // Reload the page without any query params
  window.location.href = `${window.location.origin}${window.location.pathname}`;
};

import React from 'react';

import { YotpoProductReviewsWidget } from '~/components/products/yotpo-product-reviews-widget';

import styles from '~/components/static/reviews/pdp-reviews.module.scss';

const PDPReviews = ({
  productId,
  productName,
  productPageUrl,
  productImageUrl,
  widget,
}) => {
  return (
    <div className={styles.pdpReviews}>
      <YotpoProductReviewsWidget
        productId={productId}
        productName={productName}
        productPageUrl={productPageUrl}
        productImageUrl={productImageUrl}
        widget={widget}
      />
    </div>
  );
};

export default PDPReviews;

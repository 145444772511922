import React, { useCallback, useEffect } from 'react';

import { useFacebookSignIn } from '~/hooks/use-facebook-sign-in';
import { api } from '~/api';
import { useFlashMessage } from '~/features/flash-messages/flash-message-actions';
import {
  SIGN_IN_PROVIDER,
  useSession,
} from '~/features/session/session-actions';
import { EventType, analyticsBeacon } from '~/utils/analytics';
import { captureException } from '~/utils/exception-tracking';

import styles from '~/components/sessions/sessions.module.scss';

const FacebookSignInButton = ({
  source,
  onSignInStart = () => {},
  onSuccess = () => {},
  onError = () => {},
}) => {
  useFacebookSignIn();
  const { showFlashMessage } = useFlashMessage();
  const { signIn } = useSession();

  const handleFbLogin = useCallback(
    async (response) => {
      onSignInStart();

      // If the `status` is anything other than `connected`, the user did not agree to authenticate with FB
      // So, don't do anything other than turn off our FB Login button's loading state
      if (response?.status !== 'connected') {
        return onError();
      }

      let accessToken = response?.authResponse?.accessToken;

      // Check the granted permissions for the 'email' permission
      const permissions = await new Promise((resolve) =>
        window.FB.api(
          '/me/permissions',
          'get',
          { access_token: accessToken },
          (res) => {
            resolve(res?.data || []);
          }
        )
      );

      const declinedEmailPermission = permissions?.find(
        (permission) =>
          permission?.permission === 'email' &&
          permission?.status === 'declined'
      );

      // If the user declined the 'email' permission, re-prompt them
      if (declinedEmailPermission) {
        try {
          accessToken = await new Promise((resolve, reject) =>
            window.FB.login(
              (response) => {
                if (response?.authResponse?.grantedScopes?.includes('email')) {
                  resolve(response.authResponse.accessToken);
                }

                // If the user STILL does not grant us 'email' permission => reject this promise and show a flash message
                reject('User did not grant email permissions');
              },
              {
                scope: 'email',
                auth_type: 'rerequest',
                return_scopes: true,
              }
            )
          );
        } catch (error) {
          showFlashMessage(
            'We were unable to log you in because you did not grant us access to your email'
          );
          return onError();
        }
      }

      // Send our FB 'accessToken' to the BE to create/log in to a Sunday Account
      let authResponse;
      try {
        authResponse = await api.sessions.validateFacebookToken(accessToken);
      } catch (error) {
        captureException(
          new FacebookSignInError('Error validating Facebook access token'),
          {
            extras: {
              error: JSON.stringify(error),
            },
          }
        );

        showFlashMessage(
          'Something went wrong signing you in. You may need to refresh the page and try again.'
        );

        return onError(error);
      }

      const isPostRegistration = authResponse?.created;
      const { email, token } = authResponse;

      // Use the one-time password from the BE to sign in the user
      try {
        await signIn(
          { email, password: token },
          isPostRegistration,
          SIGN_IN_PROVIDER.FACEBOOK
        );
      } catch (error) {
        captureException(
          new FacebookSignInError('Error signing in with Facebook.'),
          {
            extras: { errorInfo: JSON.stringify(error) },
          }
        );

        showFlashMessage(
          'Something went wrong signing you in. You may need to refresh the page and try again.'
        );

        return onError(error);
      }

      if (isPostRegistration) {
        // The call to `signIn` doesn't return a user, so we aren't able to pass user slug or UUID here
        analyticsBeacon.emit(EventType.ACCOUNT_CREATED, {
          email,
          source,
          provider: SIGN_IN_PROVIDER.FACEBOOK,
        });
      }

      try {
        await onSuccess();
      } catch {
        // Do nothing if onSuccess fails
      }
    },
    [onError, onSignInStart, onSuccess, showFlashMessage, signIn, source]
  );

  useEffect(() => {
    window.handleFbLogin = handleFbLogin;
  }, [handleFbLogin]);

  return (
    <div className={styles.facebookSignInContainer}>
      <div
        id="fb-login-button"
        className="fb-login-button"
        data-width="100%"
        data-size="large"
        data-button-type="continue_with"
        data-layout=""
        data-auto-logout-link="false"
        data-use-continue-as="true"
        data-onlogin="handleFbLogin"
        data-scope="public_profile,email"
        style={{
          backgroundColor: 'var(--gray-6)',
          height: '4rem',
          width: '100%',
        }}
      />
    </div>
  );
};

export default FacebookSignInButton;

class FacebookSignInError extends Error {
  constructor(message) {
    super(message);
    this.name = 'FacebookSignInError';
  }
}

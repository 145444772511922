import { useState, useEffect, useMemo } from 'react';

export const useMediaQuery = (query) => {
  const mq = useMemo(() => window.matchMedia(query), [query]);
  const [doesMatch, setDoesMatch] = useState(() => mq.matches);

  useEffect(() => {
    const handleChange = (e) => {
      setDoesMatch(e.matches);
    };

    mq.addListener(handleChange);

    return () => {
      mq.removeListener(handleChange);
    };
  }, [mq]);

  return doesMatch;
};

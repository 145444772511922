export const tasksApi = (http) => {
  return {
    fetchTips: async (lawnUuid) => {
      const res = await http.post(`/api/lawns/tips/`, {
        lawnUuid,
      });

      return res.tips;
    },

    fetchCareActions: async (lawnUuid) => {
      return http.get(`/api/lawns/${lawnUuid}/yard_care_actions`);
    },

    updateCareAction: async (taskUuid, lawnUuid, markedComplete) => {
      return http.patch(
        `/api/lawns/${lawnUuid}/yard_care_actions/${taskUuid}`,
        {
          markedComplete,
        }
      );
    },
  };
};

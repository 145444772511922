import React, { useState } from 'react';
import getYouTubeID from 'get-youtube-id';

import Carousel from '~/components/carousels/carousel';
import { useHorizontalScroll } from '~/components/carousels/use-horizontal-scroll';
import LazyImg from '~/components/lazy-img';
import YouTubeVideo from '~/components/static/about-us/you-tube-video';
import { urlFor } from '~/sanity/images';

import styles from '~/components/products/product-detail/product-carousel.module.scss';

const ProductCarousels = ({ images, videos }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div>
      <HeroCarousel
        activeIndex={activeIndex}
        onActiveIndexChange={setActiveIndex}
        images={images}
        videos={videos}
      />
      <ThumbnailCarousel
        activeIndex={activeIndex}
        onActiveIndexChange={setActiveIndex}
        images={images}
        videos={videos}
      />
    </div>
  );
};

export default ProductCarousels;

const HeroCarousel = ({ activeIndex, onActiveIndexChange, images, videos }) => {
  const numSlides = images.length + (videos?.length || 0);

  const { ref: horizontalScrollRef, bind } = useHorizontalScroll({
    activeIndex,
    onActiveIndexChange,
    numSlides,
  });

  return (
    <div ref={horizontalScrollRef} {...bind}>
      <div className={styles.heroCarousel}>
        <div className={styles.heroCarouselGrid}>
          {images.map((image, i) => (
            <span key={i} className={styles.heroCarouselGridItem}>
              <CarouselImage
                className={styles.heroCarouselImage}
                image={image}
                width="375"
                height="375"
              />
            </span>
          ))}
          {videos?.map((video) => (
            <span key={video.url} className={styles.heroCarouselGridVideoItem}>
              <YouTubeVideo
                src={video.url}
                title={video.description || 'Embedded YouTube video player'}
                stopWhenNotVisible
              />
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

function ThumbnailCarousel({
  activeIndex,
  onActiveIndexChange,
  images,
  videos,
}) {
  return (
    <div className={styles.thumbnailCarousel}>
      <Carousel
        activeIndex={activeIndex}
        onActiveIndexChange={onActiveIndexChange}
        childrenPerScroll={5}
        triggerChangeOnScroll={false}
      >
        {images.map((image, i) => (
          <button
            key={i}
            className={styles.thumbnailCarouselButton}
            type="button"
            onClick={() => onActiveIndexChange(i)}
          >
            <CarouselImage
              className={styles.thumbnailCarouselImage}
              image={image}
              width="100"
              height="100"
            />
          </button>
        ))}
        {videos?.map((video, i) => {
          const videoId = getYouTubeID(video.url);

          return (
            <button
              key={video.url}
              className={styles.thumbnailCarouselButton}
              type="button"
              onClick={() => onActiveIndexChange(images.length + i)}
            >
              <img
                src="/icons/play-icon.svg"
                alt="Play"
                className={styles.playIcon}
              />
              <LazyImg
                className={styles.thumbnailCarouselImage}
                src={`https://img.youtube.com/vi/${videoId}/0.jpg`}
                width="100"
                height="100"
                alt="YouTube video placeholder"
              />
            </button>
          );
        })}
      </Carousel>
    </div>
  );
}

function CarouselImage({ image, ...rest }) {
  const desktopSource = urlFor(image).width(640).dpr(2).fit('clip');
  const mobileSource = urlFor(image).width(375).dpr(2).fit('clip');

  return (
    <img
      srcSet={`${mobileSource.url()} 375w, ${desktopSource.url()}`}
      sizes="(max-width: 768px) 375px, 376px"
      alt={image.alt || ''}
      {...rest}
    />
  );
}

export const dynamicFormsApi = (http) => {
  return {
    submit: async ({
      answers,
      metaData,
      externalSourceCategory,
      externalSourceIdentifier,
    }) => {
      const payload = {
        answers,
        metaData,
        externalSourceIdentifier,
        externalSourceCategory,
      };

      return http.post('/api/forms/', payload);
    },

    fetchAnswers: async () => {
      return http.get('/api/forms/');
    },
  };
};

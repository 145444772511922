import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';

const flashMessageAtom = atom([]);

export const useFlashMessage = () => {
  const [messages, setMessages] = useAtom(flashMessageAtom);

  const hideFlashMessage = useCallback(
    (text) => {
      setMessages((prev) => prev.filter((message) => message.text !== text));
    },
    [setMessages]
  );

  const showFlashMessage = useCallback(
    (text, variant = 'error', duration = 6000) => {
      setMessages((prev) => [
        ...prev,
        {
          variant,
          text,
        },
      ]);

      setTimeout(() => {
        hideFlashMessage(text);
      }, duration);
    },
    [hideFlashMessage, setMessages]
  );

  return {
    messages,
    showFlashMessage,
    hideFlashMessage,
  };
};

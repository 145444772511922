export const sanityProductByKeyQuery = `{
  "product": *[_type == "sundayStoreProduct" && !(_id in path('drafts.**')) && (sku.current == $sku || $sku in duplicateSkus)]| order(_updatedAt desc)[0] {
    documents[] {
      asset -> {
        assetId,
        extension,
        originalFilename,
      },
      displayName,
    },
    name,
    faqs,
    carouselImages[] {
      alt,
      asset
    },
    linkedContent {
      linkedContentArticle -> {
        title,
        shortDescription,
        "slug": slug.current,
        thumbnailImage {
          alt,
          asset
        },
        category {
          articleCategory -> {
            name,
            "slug": slug.current,
          },
          articleSubCategory -> {
            name,
            "slug": slug.current,
          },
        },
      },
      linkedContentFlexContent[] {
        ...,
        markDefs[] {
          ...,
          _type == "link" => {
            articleLink -> {
              "slug": slug.current,
              category {
                articleCategory -> {
                  "slug": slug.current,
                },
                articleSubCategory -> {
                  name,
                  "slug": slug.current,
                },
              },
            },
            fileLink {
              asset -> {
                assetId,
                extension,
                originalFilename,
              }
            },
            productLink -> {
              "type": _type,
              "slug": slug.current,
            }
          },
        }
      },
    },
    pdpFlexContent[] {
      ...,
      product -> {
        name,
        primaryImage,
        "slug": slug.current,
        "categorySlug": category -> slug.current,
      },
      markDefs[] {
        ...,
        _type == "link" => {
          articleLink -> {
            "slug": slug.current,
            category {
              articleCategory -> {
                "slug": slug.current,
              },
              articleSubCategory -> {
                name,
                "slug": slug.current,
              },
            },
          },
          fileLink {
            asset -> {
              assetId,
              extension,
              originalFilename,
            }
          },
          productLink -> {
            "type": _type,
            "slug": slug.current,
          }
        },
      }
    },
    primaryImage {
      alt,
      asset
    },
    "primaryImagePreview": primaryImage.asset -> metadata.lqip,
    "sku": sku.current,
    reviewSkuOverride,
    videos[] {
      description,
      url
    },
    duplicateSkus,
    tooltipVariantCopy,
    pspDetailsModalCopy
  },
}`;

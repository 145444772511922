import queryString from 'query-string';

import { useDocumentHead } from '~/hooks/use-document-head';

export const useCanonicalUrl = (pathOrUrl) => {
  useDocumentHead(
    'link',
    {
      rel: 'canonical',
      href: buildCanonicalUrl(pathOrUrl),
    },
    {
      enabled: pathOrUrl !== null,
    }
  );
};

export function buildCanonicalUrl(pathOrUrl, location = window.location) {
  if (!pathOrUrl) {
    return toCanonicalUrl(location);
  }

  const isFullUrl = pathOrUrl.startsWith('http');
  if (isFullUrl) {
    return toCanonicalUrl(new URL(pathOrUrl));
  }

  const fullUrl = `${location.origin}${pathOrUrl}${location.search}`;
  return toCanonicalUrl(new URL(fullUrl));
}

function toCanonicalUrl(url) {
  let canonicalUrl = `https://${url.host}${removeTrailingSlash(url.pathname)}`;

  // If the URL contains a page query parameter, include it in the canonical URL
  const page = queryString.parse(url.search)?.page;
  if (page && Number(page) > 1 && !canonicalUrl.includes('page=')) {
    canonicalUrl += `?page=${page}`;
  }

  return canonicalUrl;
}

function removeTrailingSlash(path) {
  const hasSlash = path.charAt(path.length - 1) === '/';
  if (hasSlash) {
    return path.slice(0, -1);
  } else {
    return path;
  }
}

import React from 'react';

import LazyImg from '~/components/lazy-img';

import styles from '~/components/products/products.module.scss';

const OnePercentBanner = () => {
  return (
    <section className={styles.onePercentBanner}>
      <div className={styles.onePercentBannerIcons}>
        <LazyImg
          className={styles.onePercentBannerIcon}
          src="/icons/one-percent-icon-dark.svg"
          alt="1% for the planet logo"
          width="43"
          height="55"
        />
        <LazyImg
          className={styles.onePercentBannerBuffalo}
          src="/icons/buffalo-icon-outline-dark.svg"
          alt="Buffalo illustration"
          width="84"
          height="55"
        />
      </div>

      <p className={styles.onePercentBannerCopy}>
        A portion of every purchase goes to restoring <br />
        tallgrass prairie through{' '}
        <a
          href="https://www.onepercentfortheplanet.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          1% for the planet
        </a>
        .
      </p>
    </section>
  );
};

export default OnePercentBanner;

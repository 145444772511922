import { cookies } from '~/utils/cookie';
import { api } from '~/api';
import { captureException } from '~/utils/exception-tracking';

const COOKIE_NAME = 'privacySettings';

export const storePrivacySettings = (settings) => {
  return cookies.set(COOKIE_NAME, JSON.stringify(settings));
};

export const sendPrivacySettings = async (privacySettings) => {
  try {
    const settings = privacySettings || getPrivacySettings();
    if (!settings) {
      return;
    }

    await api.users.setPrivacyData(settings);

    deletePrivacySettings();
  } catch (err) {
    console.log(err);
    captureException(err);
  }
};

const getPrivacySettings = () => {
  return JSON.parse(cookies.get(COOKIE_NAME) || null);
};

const deletePrivacySettings = () => {
  return cookies.remove(COOKIE_NAME);
};

import cx from 'classnames';

import {
  DashHeader,
  RECOMMENDED_ARTICLE_DEFAULT,
  RECOMMENDED_ARTICLE_SIMPLE,
  RecommendedArticle,
  SimpleRecommendedArticle,
} from '~/components/the-shed/components';

import styles from '~/components/the-shed/components/recommended-reading.module.scss';

const AlgoliaRecommendedArticles = ({
  recommendedArticles,
  recommendedArticleType = RECOMMENDED_ARTICLE_DEFAULT,
}) => {
  const classes = cx({
    [styles.simpleWrapper]:
      recommendedArticleType === RECOMMENDED_ARTICLE_SIMPLE,
    [styles.wrapper]: recommendedArticleType === RECOMMENDED_ARTICLE_DEFAULT,
  });

  return (
    <div className={styles.recommendedReading}>
      {recommendedArticleType === RECOMMENDED_ARTICLE_SIMPLE ? (
        <p className={styles.simpleHeading}>RECOMMENDED READING</p>
      ) : (
        <DashHeader title={'Recommended Reading'} />
      )}
      <div className={classes}>
        {recommendedArticles?.map((article, index) => (
          <AlgoliaArticle
            recommendedArticle={article}
            recommendedArticleType={recommendedArticleType}
            key={article.slug}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};
export { AlgoliaRecommendedArticles };

const AlgoliaArticle = ({
  recommendedArticle,
  recommendedArticleType,
  index,
}) => {
  const article = {
    ...recommendedArticle,
    author: { name: recommendedArticle.authorName },
    category: {
      articleCategory: {
        slug: recommendedArticle.articleCategorySlug,
        name: recommendedArticle.articleCategoryName,
      },
      articleSubCategory: {
        slug: recommendedArticle.articleSubCategorySlug,
        name: recommendedArticle.articleSubCategoryName,
      },
    },
  };

  return recommendedArticleType === RECOMMENDED_ARTICLE_SIMPLE ? (
    <SimpleRecommendedArticle article={article} />
  ) : (
    <RecommendedArticle article={article} index={index} />
  );
};

import React from 'react';

import { urlFor } from '~/sanity/images';

const AnnotatedImageSerializer = ({ node }) => {
  const { asset, alt } = node;

  const desktopSource = urlFor(asset).width(680).fit('max');
  const mobileSource = urlFor(asset).width(375).fit('max');

  return (
    <div className="imageContainer">
      <img
        className="image"
        srcSet={`
        ${mobileSource.url()} 375w,
        ${desktopSource.url()}
      `}
        sizes="(max-width: 768px) 375px, 680px"
        alt={alt || ''}
      />
    </div>
  );
};

export default AnnotatedImageSerializer;

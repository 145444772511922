export const NonCartPurchaseOrderStatuses = [
  'closed', // PO has been paid
  'committed', // PO for a Flex Pay plan that hasn't been paid for
  'pending', // PO is temporarily set to this status when the paymentItent is being created and waiting for the webhook - it is very rare to see this status
];

export const PurchaseOrderPaymentStatus = {
  PAID: 'paid',
  PREPAID: 'prepaid',
  UNPAID: 'unpaid',
};

export const PurchaseOrderStatus = {
  COMMITTED: 'com', // used for unprocessed child carts
  TEMPORARY: 'tem', // used to create carts for bundle selection page
  OPEN: 'ope',
  CLOSED: 'clo',
  CANCELLED: 'can',
  MASTER: 'mas',
  PENDING: 'pen',
  FAILED: 'fai',
  SUCCEEDED: 'suc',
  ERROR: 'err',
};

export const PurchaseOrderTag = {
  FAT_PLAN: 'fat_plan',
  LAWN_ENGINE_REFRESH_CANCELLED: 'lawn_engine_refresh_cancelled',
  LAWN_ENGINE_REFRESH_CREATED: 'lawn_engine_refresh_created',
  PROCESS_DATE_CHANGED_USER: 'process_date_changed-user',
  PROCESS_DATE_CHANGED_AGENT: 'process_date_changed-agent',
  SKIPPED: 'skipped',
};

import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import Collapsible from '~/components/collapsible';
import { useId } from '~/hooks/use-id';
import { getTabbables, setTabbable } from '~/utils/keyboard';

import styles from '~/components/accordion/accordion.module.scss';

const Accordion = ({
  buttonClassName,
  buttonOpenClassName,
  buttonContent,
  containerClassName,
  isOpen,
  onClick,
  children,
  buttonVariant = '',
}) => {
  const id = useId();
  const [open, set] = useState(isOpen);
  const contentRef = useRef();

  // Disable tabbing to child elements unless the panel is open
  useEffect(() => {
    const tabbables = getTabbables(contentRef.current);

    if (open) {
      setTabbable(tabbables, true);
    } else {
      setTabbable(tabbables, false);
    }
  }, [open]);

  useEffect(() => {
    set(isOpen);
  }, [isOpen]);

  return (
    <div className={containerClassName}>
      <button
        id={`accordion${id}`}
        className={cx(styles.button, buttonClassName, {
          [`${buttonOpenClassName}`]: open,
        })}
        type="button"
        aria-expanded={open}
        aria-controls={`section${id}`}
        onClick={() => {
          if (onClick) {
            onClick(!open); // call 'onClick' with the NEW 'open' state
          }
          set((o) => !o);
        }}
      >
        {buttonContent}

        {buttonVariant === 'solid' ? (
          <div className={styles.solidIconWrapper}>
            <svg
              className={cx(styles.icon, {
                [`${styles.iconOpen}`]: open,
              })}
              viewBox="0 0 12 8"
              height="8"
              width="12"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
            >
              <path
                d="m1.41 0 4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z"
                fill="var(--light-color)"
              />
            </svg>
          </div>
        ) : (
          <svg
            className={cx(styles.icon, {
              [`${styles.iconOpen}`]: open,
            })}
            viewBox="0 0 12 8"
            height="8"
            width="12"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
          >
            <path
              d="m1.41 0 4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z"
              fill="var(--gray-color)"
            />
          </svg>
        )}
      </button>

      <Collapsible isOpen={open}>
        <div
          ref={contentRef}
          id={`section${id}`}
          role="region"
          aria-labelledby={`accordion${id}`}
        >
          {children}
        </div>
      </Collapsible>
    </div>
  );
};

export default Accordion;

export const productsApi = (subsoilHttp, topsoilHttp) => {
  return {
    all: async () => {
      return subsoilHttp.get('/api/products-list/');
    },

    sundayStoreProducts: async () => {
      const [products, categories] = await Promise.all([
        topsoilHttp.get('/bff/api/products'),
        topsoilHttp.get('/bff/api/categories'),
      ]);

      return { productList: products, productCategories: categories };
    },

    bySlug: async (slug) => {
      return topsoilHttp.get(`/bff/api/products?slugs=${slug}`);
    },

    byProductKey: async (key) => {
      return topsoilHttp.get(`/bff/api/products?keys=${key}`);
    },

    byId: async (key) => {
      return topsoilHttp.get(`/bff/api/products?ids=${key}`);
    },
  };
};

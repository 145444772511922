import { useState, useEffect } from 'react';

import { useScript } from '~/hooks/use-script';
import { captureException } from '~/utils/exception-tracking';

window.noop = () => {};

export const useGoogleMaps = () => {
  const [isLoaded, hasError] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${window.env.GOOGLE_MAPS_API_KEY}&callback=noop&libraries=places`
  );
  const [google, set] = useState();

  useEffect(() => {
    if (isLoaded && window.google) {
      set(window.google);
    }
  }, [isLoaded]);

  useEffect(() => {
    if (hasError) {
      captureException(new Error('Google Maps failed to load'), {
        level: 'info',
      });
    }
  }, [hasError]);

  return [google, hasError];
};

import React from 'react';
import cx from 'classnames';

import Button from '~/components/button';
import { clamp } from '~/utils/functions';

import styles from '~/components/inputs/inputs.module.scss';

const DEFAULT_MIN = 0;
const DEFAULT_MAX = 100;

const IncrementorField = ({
  label,
  size,
  input,
  onChange: externalOnChange,
}) => {
  const handleClick = (direction) => {
    const minValue = input.min || DEFAULT_MIN;
    const maxValue = input.max || DEFAULT_MAX;
    const nextValue = Number(input.value) + direction;

    input.onChange({
      target: {
        value: clamp(nextValue, minValue, maxValue),
      },
    });
  };

  const id = input.id || input.name;

  return (
    <div>
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      )}

      <div className={styles.incrementorContainer}>
        <Button
          className={cx(styles.incrementorButton, {
            [`${styles.incrementorButtonSmall}`]: size === 'small',
          })}
          variant="outline"
          type="button"
          onClick={() => handleClick(-1)}
          disabled={input.disabled}
          title="Remove one"
          aria-label="Subtract one"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 10 10"
            stroke="var(--gray-color)"
            strokeLinecap="round"
          >
            <line x1="2" x2="8" y1="5" y2="5" />
          </svg>
        </Button>

        <input
          id={id}
          name={input.name}
          className={cx(styles.incrementorValue, styles.frozen, {
            [styles.incrementorValueSmall]: size === 'small',
          })}
          type="text"
          value={input.value}
          onChange={(e) => externalOnChange(e)}
          tabIndex="-1"
          {...input}
        />

        <Button
          className={cx(styles.incrementorButton, {
            [`${styles.incrementorButtonSmall}`]: size === 'small',
          })}
          variant="outline"
          type="button"
          onClick={() => handleClick(1)}
          disabled={input.disabled}
          title="Add one"
          aria-label="Add one"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 10 10"
            stroke="var(--gray-color)"
            strokeLinecap="round"
          >
            <line x1="2" x2="8" y1="5" y2="5" />
            <line x1="5" x2="5" y1="2" y2="8" />
          </svg>
        </Button>
      </div>
    </div>
  );
};

export default IncrementorField;

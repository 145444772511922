import { useEffect } from 'react';

export const useTitle = (title = 'Sunday Lawn Care') => {
  useEffect(() => {
    const prevTitle = document.title || 'Sunday Lawn Care';
    document.title =
      title === 'Sunday Lawn Care' ? title : `${title} | Sunday Lawn Care`;

    return () => {
      document.title = prevTitle;
    };
  }, [title]);
};

import { useQueries, useQuery } from 'react-query';
import { useEffect, useMemo, useState } from 'react';

import { api } from '~/api';
import { subscriptionType } from '~/features/subscriptions/subscription';
import getChannelAndPrice from '~/utils/get-channel-price';
import { CHANNEL_NON_FUNNEL } from '~/utils/channels';
import { FEATURE_FLAG_IAS_INVENTORY } from '~/feature-flags';
import { fetchSanityProductBySku } from '~/sanity/products/product-api';

export const sundayStoreProductsQuery = {
  key: 'sundayStoreSanity',
  fn: api.products.sundayStoreProducts,
};

const categoryToSortingMap = {
  default: sortByStatePriority,
};

export const useProductData = ({ enabled = true, slug, sku } = {}) => {
  const [productQuery, subsoilQuery] = useQueries([
    {
      queryKey: sundayStoreProductsQuery.key,
      queryFn: sundayStoreProductsQuery.fn,
      enabled,
    },
    {
      queryKey: ['sundayStoreSubsoil'],
      queryFn: api.products.all,
      enabled,
    },
  ]);

  const {
    isLoading: isLoadingProductContent,
    data: productContent = {},
    error: productError,
  } = productQuery;
  const {
    isLoading: isLoadingSubsoilProducts,
    data: subsoilProducts = [],
    error: subsoilError,
  } = subsoilQuery;

  const isLoading = isLoadingProductContent || isLoadingSubsoilProducts;

  const combinedAllProducts = useMemo(
    () =>
      isLoading
        ? null
        : subsoilProducts
            .map((subSoilProduct) => {
              const productDetails = productContent?.productList?.find(
                (product) => product.sku === subSoilProduct.skuId
              );

              if (!productDetails) {
                return null;
              }

              return {
                productDetails: {
                  ...productDetails,
                  inStock: FEATURE_FLAG_IAS_INVENTORY
                    ? productDetails.inStock
                    : subSoilProduct.inStock,
                },
                purchaseSku: subSoilProduct,
              };
            })
            .filter(Boolean),
    [isLoading, productContent, subsoilProducts]
  );

  const combinedProducts = useMemo(
    () =>
      combinedAllProducts &&
      combinedAllProducts.filter(
        // Filter out products explicitly labeled as not searchable
        (product) => product.productDetails.isSearchable !== false
      ),
    [combinedAllProducts]
  );

  const productsByCategory =
    combinedProducts?.length > 0
      ? combinedProducts.reduce((acc, curr, index, arr) => {
          // Filter out products that don't have Sanity Data (shouldn't happen long term) and those that have a `subscriptionType` of lawn
          if (
            !curr.productDetails ||
            curr?.purchaseSku?.subscriptionType ===
              subscriptionType.LAWN_PLAN ||
            !curr?.productDetails?.categories?.[0]?.category?.slug
          ) {
            return acc;
          }

          const productsInCategory =
            acc[curr?.productDetails?.categories?.[0]?.category?.slug] || [];
          const updatedList = [...productsInCategory, curr];

          // Sort each category's product list when we reach the end of the `reduce`
          if (index === arr.length - 1) {
            const accCopy = {
              ...acc,
              [curr?.productDetails?.categories[0]?.category?.slug]:
                updatedList,
            };

            Object.keys(accCopy).forEach((key) =>
              accCopy[key].sort(
                categoryToSortingMap?.[key] || categoryToSortingMap.default
              )
            );

            return accCopy;
          }

          return {
            ...acc,
            [curr?.productDetails?.categories?.[0]?.category?.slug]:
              updatedList,
          };
        }, {})
      : {};

  // Find single product for PDP data using `slug` or `skuId`
  const [updatedProduct, setUpdatedProduct] = useState(null);

  useEffect(() => {
    setUpdatedProduct(null);

    if ((!slug && !sku) || !FEATURE_FLAG_IAS_INVENTORY) {
      return;
    }

    if (slug) {
      api.products.bySlug(slug).then((data) => setUpdatedProduct(data[0]));
      return;
    }

    if (sku) {
      api.products.byProductKey(sku).then((data) => setUpdatedProduct(data[0]));
      return;
    }
  }, [slug, sku]);

  const product = useMemo(() => {
    if ((!slug && !sku) || !combinedProducts) {
      return null;
    }

    const storedProduct =
      combinedProducts?.find(
        (p) => p.productDetails?.slug === slug || p?.productDetails?.sku === sku
      ) ?? null;

    if (
      updatedProduct &&
      storedProduct?.productDetails?.sku === updatedProduct?.sku
    ) {
      return {
        productDetails: {
          ...updatedProduct,
          inStock: FEATURE_FLAG_IAS_INVENTORY
            ? updatedProduct.inStock
            : storedProduct?.purchaseSku?.inStock,
        },
        purchaseSku: storedProduct?.purchaseSku,
      };
    }

    return storedProduct;
  }, [combinedProducts, slug, sku, updatedProduct]);

  return {
    isLoading,
    allProducts: combinedAllProducts,
    productCategories: productContent?.productCategories,
    products: combinedProducts,
    productsByCategory,
    product,
    recommendedProducts: getRecommendedProducts(
      product,
      combinedProducts,
      productsByCategory
    ),
    error: productError || subsoilError,
  };
};

export const useProductsBySku = (purchaseSkus) => {
  const skuIds =
    typeof purchaseSkus === 'string' ? purchaseSkus : purchaseSkus?.join(',');

  const {
    data: products,
    isLoading,
    isError,
    ...rest
  } = useQuery(
    ['productsBySku', purchaseSkus],
    () => api.products.byProductKey(skuIds),
    { enabled: Boolean(purchaseSkus) }
  );

  return {
    products,
    isLoading,
    isError,
    ...rest,
  };
};

export const useProductBySku = (sku) => {
  const { products, ...rest } = useProductsBySku(sku);

  return {
    product: products?.[0],
    ...rest,
  };
};

export const useProductsBySlug = (slug) => {
  const slugs = typeof slug === 'string' ? slug : slug?.join(',');

  const {
    data: products,
    isLoading,
    isError,
    ...rest
  } = useQuery(['productBySlug', slug], () => api.products.bySlug(slugs), {
    enabled: Boolean(slug),
  });

  return {
    products,
    isLoading,
    isError,
    ...rest,
  };
};

export const useSanityProduct = (sku, { enabled = true }) => {
  const { data, ...rest } = useQuery(
    ['dynamicForm', sku],
    () => fetchSanityProductBySku(sku),
    {
      enabled: Boolean(sku),
    }
  );

  const product = data?.product;

  return {
    product,
    ...rest,
  };
};

function getRecommendedProducts(product, products, productsByCategory) {
  if (!product || !products || !productsByCategory) {
    return null;
  }

  const { productDetails } = product;

  /**
   * "Default" recommended products are the highest rated (by State Priority Score)
   *  products in the same subcategory as the passed in `product`
   * */
  let recommendedProducts = productsByCategory[
    productDetails?.categories?.[0]?.category?.slug
  ]
    ?.filter(
      (item) =>
        item.productDetails.sku !== product.productDetails.sku &&
        item?.productDetails?.categories?.[0]?.subCategory?.slug ===
          product?.productDetails?.categories?.[0]?.subCategory?.slug &&
        !item.purchaseSku.isRestrictedInState &&
        item.productDetails.inStock
    )
    ?.slice(0, 2);

  /**
   * If there are `relatedProducts` set in Sanity for the product,
   * override the default recommendations.
   *
   * NOTE: More than two related products can be set in Sanity,
   * but we're filtering out any out of stock or restricted items
   * and then only taking the top 2!
   */
  if (productDetails?.relatedProducts?.products?.length > 0) {
    recommendedProducts = productDetails.relatedProducts.products
      .map((item) =>
        products.find((product) => product.productDetails.sku === item.sku)
      )
      .filter(
        (item) =>
          item && // if `item` is falsey, it wasn't found in `products` => inactive => filter it out
          !item.purchaseSku.isRestrictedInState &&
          item.productDetails.inStock
      )
      .slice(0, 2);
  }

  return recommendedProducts;
}

export function sortByStatePriority(a, b) {
  return b.purchaseSku.statePriorityScore - a.purchaseSku.statePriorityScore;
}

export function sortByInStock(a, b) {
  if (a.productDetails.inStock === b.productDetails.inStock) {
    return 0;
  } else if (a.productDetails.inStock) {
    return -1;
  } else {
    return 1;
  }
}

export function sortByIsRestricted(a, b) {
  if (a.purchaseSku.isRestrictedInState === b.purchaseSku.isRestrictedInState) {
    return 0;
  } else if (a.purchaseSku.isRestrictedInState) {
    return 1;
  } else {
    return -1;
  }
}

function isDiscounted(product) {
  const { unitPrice, fullPrice } = getChannelAndPrice(
    CHANNEL_NON_FUNNEL,
    product
  );

  return unitPrice !== fullPrice;
}

// If A is on sale and B is not => bring A forward
// If B is on sale and A is not => bring B forward
// If A and B are BOTH on sale or NOT on sale => do nothing
export function sortByIsDiscounted(a, b) {
  if (isDiscounted(a) === isDiscounted(b)) {
    return 0;
  } else if (isDiscounted(a)) {
    return -1;
  } else {
    return 1;
  }
}

export function sortByRecommended(a, b) {
  return (
    sortByIsRestricted(a, b) || sortByInStock(a, b) || sortByStatePriority(a, b)
  );
}

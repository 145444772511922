/**
 * The <AutocompleteAddressInput /> component will store the user's selection
 * in localStorage so that any return visits to the site will pre-populate
 * the last selected address. This module handles storing and retrieving
 * the data necessary to do so.
 */

export const AutocompleteTypes = {
  FULL_ADDRESS: 'full address',
  ZIP_CODE: 'zip code',
};

const NAMESPACE = 'autocomplete';

function key(key) {
  return `${NAMESPACE}:${key}`;
}

const StorageKeys = {
  [AutocompleteTypes.FULL_ADDRESS]: {
    address: 'fullAddress',
    displayText: 'addressText',
  },
  [AutocompleteTypes.ZIP_CODE]: {
    address: 'zipCodeAddress',
    displayText: 'zipCodeText',
  },
};

export const storeAutocompleteData = ({ type, address, displayText }) => {
  try {
    localStorage.setItem(
      key(StorageKeys[type].address),
      JSON.stringify(address)
    );
    localStorage.setItem(key(StorageKeys[type].displayText), displayText);
  } catch (err) {}
};

export const retrieveAutocompleteData = (type) => {
  try {
    const address = JSON.parse(
      localStorage.getItem(key(StorageKeys[type].address)) || ''
    );
    const displayText = localStorage.getItem(
      key(StorageKeys[type].displayText)
    );
    return {
      address,
      displayText,
    };
  } catch (err) {
    return {
      address: null,
      displayText: null,
    };
  }
};

export const clearAutocompleteData = (type) => {
  try {
    localStorage.removeItem(key(StorageKeys[type].address));
    localStorage.removeItem(key(StorageKeys[type].displayText));
  } catch (err) {}
};

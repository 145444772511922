import { AutocompleteTypes } from '~/components/inputs/autocomplete-address-input/autocomplete-storage';

/**
 * Convert a UI phone number into the E164 format accepted by the API
 *
 * @param {string} phone - "(888) 555-1234"
 * @return {string} - "+18885551234"
 */
export const toApiPhone = (phone) => {
  if (!phone) {
    return '';
  }

  const countryCode = '+1';
  const strippedPhone = phone.replace(/\W*/g, '');

  return `${countryCode}${strippedPhone}`;
};

/**
 * Convert an API phone number into the UI-friendly format
 */
export const toUiPhone = (phone) => {
  if (!phone) {
    return '';
  }

  const withoutCountryCode = phone.charAt(0) === '+' ? phone.slice(2) : phone;

  if (withoutCountryCode.length !== 10) {
    return phone;
  }

  const areaCode = withoutCountryCode.slice(0, 3);
  const firstThree = withoutCountryCode.slice(3, 6);
  const lastFour = withoutCountryCode.slice(6);
  return `(${areaCode}) ${firstThree}-${lastFour}`;
};

/**
 * placeToAddress parses a Google Place response into a standardized address shape.
 */
export const placeToAddress = (place, type) => {
  if (!place) {
    return null;
  }

  return {
    line1:
      type === AutocompleteTypes.ZIP_CODE
        ? ''
        : getLine1(place.address_components),
    line2: getAddressLine2(place.address_components),
    city: getCity(place.address_components),
    stateCode: getStateCode(place.address_components),
    postalCode: getPostalCode(place.address_components),
    countryCode: getCountryCode(place.address_components),
    latitude: getLat(place.geometry),
    longitude: getLong(place.geometry),
  };
};

function getStreetNumber(addressComponents) {
  return findComponentWithType(addressComponents, 'street_number').long_name;
}

function getStreetName(addressComponents) {
  return findComponentWithType(addressComponents, 'route').long_name;
}

function getLine1(addressComponents) {
  const streetNumber = getStreetNumber(addressComponents);
  const streetName = getStreetName(addressComponents);

  if (!streetName) {
    return '';
  }

  return [streetNumber, streetName].filter(Boolean).join(' ');
}

// I don't see a way for the Places response to include line 2 data...
function getAddressLine2() {
  return '';
}

function getCity(addressComponents) {
  return (
    findComponentWithType(addressComponents, 'locality').long_name ||
    findComponentWithType(addressComponents, 'sublocality').long_name ||
    findComponentWithType(addressComponents, 'administrative_area_level_3')
      .long_name ||
    findComponentWithType(addressComponents, 'neighborhood').long_name
  );
}

function getStateCode(addressComponents) {
  return findComponentWithType(addressComponents, 'administrative_area_level_1')
    .short_name;
}

function getPostalCode(addressComponents) {
  return findComponentWithType(addressComponents, 'postal_code').long_name;
}

function getCountryCode(addressComponents) {
  return findComponentWithType(addressComponents, 'country').short_name;
}

function findComponentWithType(addressComponents, type) {
  return (
    addressComponents.find((comp) => comp.types.includes(type)) || {
      long_name: '',
      short_name: '',
      types: [],
    }
  );
}

function getLat(geometry) {
  return geometry.location.lat();
}

function getLong(geometry) {
  return geometry.location.lng();
}

import React, { useEffect } from 'react';
import getYouTubeID from 'get-youtube-id';

import { useVisibility } from '~/hooks/use-visibility';

import styles from './about-us.module.scss';

const YouTubeVideo = ({ src, title, stopWhenNotVisible = false }) => {
  const videoId = getYouTubeID(src);
  const embeddableUrl = `https://www.youtube.com/embed/${videoId}?enablejsapi=1`;

  const [visibilityRef, isVisible] = useVisibility();

  useEffect(() => {
    if (!isVisible && stopWhenNotVisible) {
      pauseVideo(visibilityRef);
    }
  }, [isVisible, stopWhenNotVisible, visibilityRef]);

  const pauseVideo = (videoRef) =>
    videoRef.current.contentWindow.postMessage(
      '{"event":"command","func":"stopVideo"}',
      '*'
    );

  return (
    <div className={styles.youTubeVideoContainer}>
      <iframe
        ref={visibilityRef}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder="0"
        height="315"
        id="youtube-iframe"
        src={embeddableUrl}
        title={title}
        width="560"
      ></iframe>
    </div>
  );
};

export default YouTubeVideo;

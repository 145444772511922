import React, { useContext } from 'react';

import { FormContext } from './form';

const Field = ({
  variant,
  name,
  label,
  subLabel,
  options,
  disabled,
  component: Component,
  children,
  type,
  onChange: externalOnChange,
  ...rest
}) => {
  const { state, handleFieldChange } = useContext(FormContext);

  const value = state.formValues[name] || '';
  const error = state.formErrors[name];

  const componentProps = {
    variant,
    label,
    subLabel,
    children,
    type,
    input: {
      ...rest,
      type,
      options,
      name,
      value,
      checked: value,
      disabled: disabled || state.isSubmitting,
      onChange: (e) => {
        externalOnChange && externalOnChange(e);

        if (e.target.type === 'checkbox') {
          handleFieldChange(name, e.target.checked);
        } else {
          handleFieldChange(name, e.target.value);
        }
      },
      'data-valid': Boolean(!error),
    },
    meta: {
      error,
      submitFailed: state.submitFailed,
    },
  };

  return <Component {...componentProps} />;
};

export default Field;

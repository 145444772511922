import { wait } from '~/utils/functions';
import { DeprecatedEventType } from '~/utils/analytics';

const GLADLY_APP_ID = 'getsunday.com';
export const GLADLY_AVAILABILITY = {
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE_OFFICE_CLOSED: 'UNAVAILABLE_OFFICE_CLOSED',
  UNAVAILABLE_BUSY: 'UNAVAILABLE_BUSY',
};

/**
 * Gladly is the product our Customer Service team uses to communicate with users.
 * For now this plugin focuses on Gladly's chat widget, "Sidekick".
 *
 * NB: we only have a "PROD" Gladly environment, so ignore any mention of "STAGING"
 * in their documentation.
 *
 * https://developer.gladly.com/sidekick/
 */
export const gladlyPlugin = {
  name: 'Gladly',
  init: async () => {
    await wait(1000);

    attachGladlyChat();

    await window.Gladly.init({
      appId: GLADLY_APP_ID,
      autoShowButton: true,
    });
  },
  events: {
    [DeprecatedEventType.PAGE_VIEW]: () => {
      window.Gladly.navigate();
    },
  },
};

export const hideGladlyChat = () => {
  const el = gladlyChatContainer();
  if (el) {
    el.style.setProperty('display', 'none', 'important');
  }
};

export const showGladlyChat = () => {
  const el = gladlyChatContainer();
  if (el) {
    el.style.setProperty('display', 'flex', 'important');
  }
};

function gladlyChatContainer() {
  return document.querySelector('#gladlyChat_container');
}

/* eslint-disable */
function attachGladlyChat(env) {
  !(function (c, n, r, t) {
    if (!c[r]) {
      var i,
        d,
        p = [];
      (d =
        'PROD' !== t && t
          ? 'STAGING' === t
            ? 'https://cdn.gladly.qa/gladly/chat-sdk/widget.js'
            : t
          : 'https://cdn.gladly.com/chat-sdk/widget.js'),
        (c[r] = {
          init: function () {
            i = arguments;
            var e = {
              then: function (t) {
                return p.push({ type: 't', next: t }), e;
              },
              catch: function (t) {
                return p.push({ type: 'c', next: t }), e;
              },
            };
            return e;
          },
        }),
        (c.__onHelpAppHostReady__ = function (t) {
          if ((delete c.__onHelpAppHostReady__, ((c[r] = t).loaderCdn = d), i))
            for (var e = t.init.apply(t, i), n = 0; n < p.length; n++) {
              var a = p[n];
              e = 't' === a.type ? e.then(a.next) : e.catch(a.next);
            }
        }),
        (function () {
          try {
            var t = n.getElementsByTagName('script')[0],
              e = n.createElement('script');
            (e.async = !0),
              (e.src = d + '?q=' + new Date().getTime()),
              t.parentNode.insertBefore(e, t);
          } catch (t) {}
        })();
    }
  })(window, document, 'Gladly', 'PROD');
}

import React from 'react';
import cx from 'classnames';

import styles from '~/components/static/smart-lawn-plan/review.module.scss';

const Review = ({ review, additionalClassName }) => {
  return (
    <div className={cx(styles.review, additionalClassName)}>
      <p className={styles.reviewTitle}>{review.title}</p>
      <p className={styles.reviewText}>{review.text}</p>
      <ReviewStars rating={review.rating} />
    </div>
  );
};

export default Review;

const STAR_TYPE = {
  FULL: 'full',
  HALF: 'half',
  OUTLINE: 'outline',
};

const Star = ({ type }) => (
  <img
    height="18"
    width="18"
    className={styles.reviewStarIcon}
    src={`/icons/star-icon-yellow-${type}.svg`}
    alt="star"
  />
);

/**
 * This is essentially a recreation of the Yotpo stars component. It's needed
 * to display multiple Star components on the PLP without crashing the site.
 * Thanks Yotpo!
 */
export const ReviewStars = ({ rating, className }) => {
  const starComponents = [];

  const fullStars = Math.floor(rating);
  const ratingModulus = rating % fullStars;
  const outlineStars = 5 - fullStars - (ratingModulus ? 1 : 0);

  Array.from({ length: fullStars }).forEach((_, index) => {
    starComponents.push(<Star key={`full-${index}`} type={STAR_TYPE.FULL} />);
  });

  if (ratingModulus > 0) {
    if (ratingModulus >= 0.76) {
      // Yotpo rounds anything above 0.75 up to the next full star
      starComponents.push(<Star key="full-extra" type={STAR_TYPE.FULL} />);
    } else if (ratingModulus <= 0.24) {
      // Yotpo rounds anything below 0.25 down to the next full star
      starComponents.push(<Star key="outline-extra" type={STAR_TYPE.OUTLINE} />);
    } else {
      starComponents.push(<Star key="half" type={STAR_TYPE.HALF} />);
    }
  }

  Array.from({ length: outlineStars }).forEach((_, index) => {
    starComponents.push(<Star key={`outline-${index}`} type={STAR_TYPE.OUTLINE} />);
  });

  return (
    <div className={cx(styles.reviewStars, className)}>{starComponents}</div>
  );
};

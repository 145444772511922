import React, { useState } from 'react';

import Button from '~/components/button';
import InputField from '~/components/inputs/input-field';
import PasswordField from '../../inputs/password-field';
import { Form, Field } from '~/components/form';
import { valid, required, email } from '~/utils/validation';
import GoogleSignInButton, {
  SignInButtonLoadingUI,
} from '~/components/google-sign-in-button';
import { FACEBOOK_SIGN_IN_FLAG, GOOGLE_SIGN_IN_FLAG } from '~/feature-flags';
import { Divider } from '~/components/sessions/sign-in/sign-in-form';
import { useSession } from '~/features/session/session-actions';
import {
  getValidationErrorMessageFor,
  isIncorrectEmailOrPasswordError,
  isValidationError,
} from '~/api/api-errors';
import { useFlashMessage } from '~/features/flash-messages/flash-message-actions';
import { isFacebookBrowser } from '~/utils/is-facebook-browser';
import FacebookSignInButton from '~/components/facebook-sign-in-button';

import styles from '~/components/modals/modal.module.scss';

const SignInModalForm = ({
  email,
  disableSubmit = false,
  onCancel = () => {},
  onRequestMagicLink = () => {},
  onSuccess = async () => {},
}) => {
  const [isSigningInWithGoogle, setIsSigningInWithGoogle] = useState(false);
  const [isSigningInWithFacebook, setIsSigningInWithFacebook] = useState(false);

  const { showFlashMessage } = useFlashMessage();
  const { signIn } = useSession();

  const handleSignInSubmit = async (formValues) => {
    const email = formValues.email.toLowerCase();

    try {
      await signIn({
        email,
        password: formValues.password,
      });

      await onSuccess();
    } catch (err) {
      switch (true) {
        case isIncorrectEmailOrPasswordError(err):
          return {
            email: 'Invalid email or password',
            password: 'Invalid email or password',
          };
        case isValidationError(err):
          return {
            email: getValidationErrorMessageFor(err, 'email'),
            password: getValidationErrorMessageFor(err, 'password'),
          };
        default:
          showFlashMessage(
            `Oops, we couldn't sign you in. Please try again or contact support.`,
            'error',
            5000
          );
      }
    }
  };

  return (
    <>
      {FACEBOOK_SIGN_IN_FLAG && !isFacebookBrowser && (
        <>
          {!isSigningInWithFacebook ? (
            <FacebookSignInButton
              onSuccess={onSuccess}
              onSignInStart={() => setIsSigningInWithFacebook(true)}
              onError={() => setIsSigningInWithFacebook(false)}
            />
          ) : (
            <SignInButtonLoadingUI style={{ marginBottom: '2rem' }} />
          )}
        </>
      )}

      {GOOGLE_SIGN_IN_FLAG && !isFacebookBrowser && (
        <>
          {!isSigningInWithGoogle ? (
            <GoogleSignInButton
              containerId="google-sign-in"
              onSignInStart={() => setIsSigningInWithGoogle(true)}
              onSuccess={onSuccess}
            />
          ) : (
            <SignInButtonLoadingUI />
          )}
          <Divider backgroundColor="var(--light-color)" />
        </>
      )}
      <Form
        onSubmit={handleSignInSubmit}
        validate={validate}
        initialValues={{ email }}
      >
        {({ formValues, isSubmitting }) => (
          <>
            <Field
              label="Email address"
              name="email"
              type="email"
              component={InputField}
              autoComplete="email"
            />
            <Field
              label="Password"
              name="password"
              type="password"
              component={PasswordField}
              autoComplete="current-password"
            />

            <div className={styles.buttons}>
              <Button
                type="submit"
                fullWidth
                isLoading={isSubmitting}
                data-testid="sign-in-submit-button"
              >
                Sign in
              </Button>

              <div className={styles.magicLinkContainer}>
                <p>
                  Forgot your password?{' '}
                  <Button
                    type="button"
                    variant="link"
                    onClick={() =>
                      onRequestMagicLink(formValues.email?.toLowerCase())
                    }
                    disabled={disableSubmit}
                  >
                    Request a sign in link.
                  </Button>
                </p>
              </div>
              <Button
                type="button"
                variant="link"
                fullWidth
                onClick={onCancel}
                style={{ marginTop: '2rem' }}
              >
                Back
              </Button>
            </div>
          </>
        )}
      </Form>
    </>
  );
};

function validate(formValues) {
  return {
    email: valid(
      required('Email address is required'),
      email()
    )(formValues.email),
    password: valid(required())(formValues.password),
  };
}

export default SignInModalForm;

import React from 'react';

import {
  HeaderComponent,
  RecommendedProductsCarousel,
  RecommendedProduct,
} from '~/components/sunday-store/components/product-details/related-products';

const SundayRelatedProducts = ({ productDetails, productsByCategory }) => {
  let renderedProducts;
  const subCategory = productDetails?.categories?.[0]?.subCategory?.slug;
  const currentProductSlug = productDetails.slug;

  if (!productsByCategory) {
    return null;
  }

  const filterBySub = productsByCategory.filter(
    ({ productDetails }) =>
      productDetails?.categories?.[0]?.subCategory?.slug === subCategory
  );

  const productRecs = subCategory
    ? filterBySub.length > 5
      ? filterBySub
      : productsByCategory
    : productsByCategory;

  renderedProducts = productRecs
    ?.filter(({ productDetails }) => productDetails.slug !== currentProductSlug)
    .sort(
      (a, b) =>
        b.purchaseSku.statePriorityScore - a.purchaseSku.statePriorityScore
    )
    .slice(0, 8);

  return (
    <>
      <HeaderComponent />
      <RecommendedProductsCarousel
        itemComponent={RecommendedProduct}
        items={renderedProducts}
      />
    </>
  );
};

export default SundayRelatedProducts;

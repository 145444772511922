import React from 'react';
import { default as ReactTooltipLite } from 'react-tooltip-lite';
import cx from 'classnames';

import { EventType, analyticsBeacon } from '~/utils/analytics';

import styles from '~/components/tooltip/tooltip.module.scss';

const Tooltip = React.forwardRef(
  (
    {
      content,
      children,
      className,
      eventData,
      variant,
      color,
      zIndex = 2147483002,
    },
    ref
  ) => {
    let circleColor = color || 'var(--gray-1)';

    // If the content is a React component, we need to extract the text string
    const tooltipContent = () => {
      if (content?.props?.children) {
        const extractText = (children) => {
          if (Array.isArray(children)) {
            return children
              .map((child) => extractText(child.props.children))
              .join(' ');
          } else {
            return children;
          }
        };
        return extractText(content.props.children);
      } else {
        return content;
      }
    };

    const handleTrackingEvent = () => {
      analyticsBeacon.emit(EventType.TOOLTIP_VIEWED, {
        content: tooltipContent(),
        eventData,
      });
    };

    return (
      <span className={cx(styles.container, className)} ref={ref}>
        <ReactTooltipLite
          content={content}
          tagName="span"
          tipContentHover
          hoverDelay={400}
          mouseOutDelay={200}
          arrowSize={8}
          color={variant === 'light' ? 'var(--dark-color)' : null}
          background={variant === 'light' ? 'var(--light-color)' : null}
          zIndex={zIndex}
          onToggle={(isVisible) => isVisible && handleTrackingEvent()}
        >
          {children || (
            <svg
              className={styles.icon}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
            >
              <path
                fill={circleColor}
                d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
              />
              <path
                fill="var(--light-color)"
                d="M22 22h4v11h-4V22zM26.5 16.5c0 1.379-1.121 2.5-2.5 2.5s-2.5-1.121-2.5-2.5S22.621 14 24 14 26.5 15.121 26.5 16.5z"
              />
            </svg>
          )}
        </ReactTooltipLite>
      </span>
    );
  }
);

export default Tooltip;

export const soilTestApi = (http) => {
  return {
    registerForUser: async ({ redemptionCode, lawnAddress, isNewCustomer }) => {
      return http.put('/api/existing_user_soil_test/', {
        redemptionCode,
        lawnAddress,
        isNewCustomer,
      });
    },
  };
};

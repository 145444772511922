import React from 'react';
import cx from 'classnames';

import { ArticleBody } from '~/components/the-shed/article/article-body';
import { stripNonLetterChars, toKebabCase } from '~/utils/strings';

import styles from '~/components/the-shed/components/widgets/ordered-list-item/ordered-list-item.module.scss';

const OrderedListItemSerializer = ({ node }) => {
  const { orderNumber, headerTitle, body } = node;

  const editStr = stripNonLetterChars(headerTitle);
  const idText = toKebabCase(editStr);

  return (
    <div className={cx(styles.listBlock, 'orderedListItem')}>
      <span className={styles.orderNumber}>{orderNumber}</span>
      <h2 id={idText} className={styles.header}>
        {headerTitle}
      </h2>
      {body && (
        <div className={styles.body}>
          <ArticleBody body={body} />
        </div>
      )}
    </div>
  );
};

export default OrderedListItemSerializer;

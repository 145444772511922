import React, { useState } from 'react';

import Button from '~/components/button';
import InputField from '~/components/inputs/input-field';
import PasswordField from '~/components/inputs/password-field';
import { Form, Field } from '~/components/form';
import { valid, required, email } from '~/utils/validation';
import { FACEBOOK_SIGN_IN_FLAG, GOOGLE_SIGN_IN_FLAG } from '~/feature-flags';
import GoogleSignInButton, {
  SignInButtonLoadingUI,
} from '~/components/google-sign-in-button';
import { Divider } from '~/components/sessions/sign-in/sign-in-form';
import { useSession } from '~/features/session/session-actions';
import {
  getValidationErrorMessageFor,
  isExistingEmailError,
  isValidationError,
} from '~/api/api-errors';
import { useFlashMessage } from '~/features/flash-messages/flash-message-actions';
import { captureException } from '~/utils/exception-tracking';
import { isFacebookBrowser } from '~/utils/is-facebook-browser';
import FacebookSignInButton from '~/components/facebook-sign-in-button';

import styles from '~/components/modals/modal.module.scss';

export const REGISTRATION_SOURCES = {
  AMAZON_SOIL_TEST: 'amazon-soil-test',
  BARE_SPOT_ID: 'bare-spot-id',
  PEST_FUNNEL: 'pest-funnel',
  CHECKOUT_LOGIN: 'checkout-login',
  LAWN_FUNNEL: 'lawn-funnel',
  STORE: 'store',
  SEED_FINDER: 'seed-finder',
};

const RegisterModalForm = ({
  source,
  showPassword,
  buttonText = 'Continue',
  onSuccess = async () => {},
  onExistingEmailError = (email) => {},
}) => {
  const [isSigningInWithGoogle, setIsSigningInWithGoogle] = useState(false);
  const [isSigningInWithFacebook, setIsSigningInWithFacebook] = useState(false);

  const { register } = useSession();
  const { showFlashMessage } = useFlashMessage();

  const handleRegisterSubmit = async (formValues) => {
    const email = formValues.email.toLowerCase();

    try {
      await register({
        email,
        source,
      });
    } catch (err) {
      switch (true) {
        case isExistingEmailError(err):
          return onExistingEmailError(email);
        case isValidationError(err):
          return {
            email: getValidationErrorMessageFor(err, 'email'),
            password: getValidationErrorMessageFor(err, 'password'),
          };
        default:
          captureException(new Error('Unhandled Registration error'), {
            extras: {
              error: err,
              source,
              isFacebookBrowser,
            },
          });
          showFlashMessage(
            'Something went wrong. Please try again later.',
            'error',
            5000
          );
      }

      return;
    }

    try {
      await onSuccess();
    } catch (err) {
      captureException(new Error('Error after successful registration'), {
        extras: {
          error: err,
        },
      });
      showFlashMessage(
        'Something went wrong. Please try again later.',
        'error',
        5000
      );
    }
  };

  return (
    <>
      {FACEBOOK_SIGN_IN_FLAG && !isFacebookBrowser && (
        <>
          {!isSigningInWithFacebook ? (
            <FacebookSignInButton
              source={source}
              onSuccess={onSuccess}
              onSignInStart={() => setIsSigningInWithFacebook(true)}
              onError={() => setIsSigningInWithFacebook(false)}
            />
          ) : (
            <SignInButtonLoadingUI style={{ marginBottom: '2rem' }} />
          )}
        </>
      )}

      {GOOGLE_SIGN_IN_FLAG && !isFacebookBrowser && (
        <>
          {!isSigningInWithGoogle ? (
            <GoogleSignInButton
              containerId="google-register"
              source={source}
              onSignInStart={() => setIsSigningInWithGoogle(true)}
              onSuccess={onSuccess}
            />
          ) : (
            <SignInButtonLoadingUI />
          )}
          <Divider backgroundColor="var(--light-color)" />
        </>
      )}
      <Form
        onSubmit={handleRegisterSubmit}
        validate={createValidateFunc({ requirePassword: showPassword })}
      >
        {({ isSubmitting }) => (
          <>
            <Field
              label="Email address"
              name="email"
              type="email"
              component={InputField}
              autoComplete="email"
            />

            {showPassword && (
              <Field
                label="Password"
                name="password"
                type="password"
                component={PasswordField}
                autoComplete="current-password"
              />
            )}

            <div className={styles.buttons}>
              <Button
                type="submit"
                fullWidth
                isLoading={isSubmitting}
                data-testid="register-submit-button"
                disabled={isSigningInWithGoogle}
              >
                {buttonText}
              </Button>
            </div>
          </>
        )}
      </Form>
    </>
  );
};

function createValidateFunc({ requirePassword }) {
  return (formValues) => {
    const validationConfig = {
      email: valid(
        required('Email address is required'),
        email()
      )(formValues.email),
    };

    if (requirePassword) {
      validationConfig.password = valid(required('Password is required'))(
        formValues.password
      );
    }

    return validationConfig;
  };
}

export default RegisterModalForm;

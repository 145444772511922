import cx from 'classnames';

import { useVisibility } from '~/hooks/use-visibility';

const supportsNativeLazyLoad = 'loading' in HTMLImageElement.prototype;

// This is used to disable our JS lazy loading technique for search index crawlers.
// Borrowed from the popular LazySizes package
// https://github.com/aFarkas/lazysizes/blob/gh-pages/src/lazysizes-core.js#L334
const supportsScroll =
  'onscroll' in window && !/(gle|ing)bot/.test(navigator.userAgent);

/**
 * <LazyImg />  is an <img /> wrapper that uses lazy loading by default.
 * It leverages the native `loading` attribute when available or an IntersectionObserver as a fallback.
 *
 * Do not use this if the image is rendered "above the fold", use a normal <img /> instead.
 */
const LazyImg = (props) => {
  if (
    process.env.NODE_ENV !== 'production' &&
    (!props.width || !props.height)
  ) {
    console.warn(`Missing img width/height for ${props.src || props.srcSet}`);
  }

  if (supportsNativeLazyLoad) {
    return <NativeLazyImg {...props} />;
  } else {
    return <JSLazyImg {...props} />;
  }
};

export default LazyImg;

function NativeLazyImg({ alt = '', width, height, ...rest }) {
  return (
    <img loading="lazy" alt={alt} width={width} height={height} {...rest} />
  );
}

function JSLazyImg({
  alt = '',
  src,
  srcSet,
  className,
  width,
  height,
  ...rest
}) {
  const [ref, isVisible] = useVisibility({ triggerOnce: true });
  const shouldSetSource = isVisible || !supportsScroll;

  return (
    <img
      alt={alt}
      src={shouldSetSource ? src : ''}
      srcSet={shouldSetSource ? srcSet : ''}
      ref={ref}
      className={cx('lazyImg', className, {
        lazyImgVisible: shouldSetSource,
      })}
      width={width}
      height={height}
      {...rest}
    />
  );
}

import React, { useEffect } from 'react';
import cx from 'classnames';

import { useGoogleSignIn } from '~/hooks/use-google-sign-in';
import { useMeasure } from '~/hooks/use-measure';
import LoadingIndicator from '~/components/loading-indicator';

import styles from '~/components/sessions/sessions.module.scss';

export const GOOGLE_BUTTON_THEMES = {
  OUTLINE: 'outline',
  FILLED_BLUE: 'filled_blue',
  FILLED_BLACK: 'filled_black',
};

export const GOOGLE_BUTTON_TEXT_OPTIONS = {
  SIGN_IN: 'signin_with',
  SIGN_UP: 'signup_with',
  CONTINUE: 'continue_with',
};

const GoogleSignInButton = ({
  containerId = 'google-sign-in',
  theme = GOOGLE_BUTTON_THEMES.FILLED_BLUE,
  text = GOOGLE_BUTTON_TEXT_OPTIONS.CONTINUE,
  onSuccess = () => {},
  source,
  onSignInStart = () => {},
  onError = () => {},
}) => {
  const { isLoaded } = useGoogleSignIn({
    onSuccess,
    source,
    onSignInStart,
    onError,
  });

  const [measureRef, { width }] = useMeasure();

  // Renders the `Sign in with Google` button. Re-renders when the button container is resized (making the button responsive)
  useEffect(() => {
    if (width && isLoaded && window.google?.accounts?.id) {
      const buttonContainer = document.getElementById(containerId);

      window.google.accounts.id.renderButton(buttonContainer, {
        type: 'standard',
        theme,
        text,
        width: width > 400 ? '400' : String(Math.round(width)), // NOTE: this does NOT like being passed decimal/float values! MUST be an integer
      });
    }
  }, [containerId, isLoaded, text, theme, width]);

  return (
    <div
      id={containerId}
      ref={measureRef}
      className={cx({ [styles.skeletonButtonLoader]: !isLoaded })}
    />
  );
};

export const SignInButtonLoadingUI = ({ style }) => {
  return (
    <div
      className={cx(styles.skeletonButtonLoader, styles.withContent)}
      style={style}
    >
      Signing in <LoadingIndicator />
    </div>
  );
};

export default GoogleSignInButton;

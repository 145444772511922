import React, { useState } from 'react';

import Button from '~/components/button';
import InputField from '~/components/inputs/input-field';
import { Field, Form } from '~/components/form';
import { email, required, valid } from '~/utils/validation';
import { FACEBOOK_SIGN_IN_FLAG, GOOGLE_SIGN_IN_FLAG } from '~/feature-flags';
import GoogleSignInButton, {
  SignInButtonLoadingUI,
} from '~/components/google-sign-in-button';
import { Divider } from '~/components/sessions/sign-in/sign-in-form';
import { isFacebookBrowser } from '~/utils/is-facebook-browser';
import FacebookSignInButton from '~/components/facebook-sign-in-button';

import styles from '~/components/modals/modal.module.scss';

const MagicLinkModalForm = ({
  onSubmit,
  onCancel,
  onSignInWithPassword,
  onSignInWithThirdParty,
  initialValues = {},
}) => {
  const [isSigningInWithGoogle, setIsSigningInWithGoogle] = useState(false);
  const [isSigningInWithFacebook, setIsSigningInWithFacebook] = useState(false);

  return (
    <>
      {FACEBOOK_SIGN_IN_FLAG && !isFacebookBrowser && (
        <>
          {!isSigningInWithFacebook ? (
            <FacebookSignInButton
              onSuccess={onSignInWithThirdParty}
              onSignInStart={() => setIsSigningInWithFacebook(true)}
              onError={() => setIsSigningInWithFacebook(false)}
            />
          ) : (
            <SignInButtonLoadingUI style={{ marginBottom: '2rem' }} />
          )}
        </>
      )}

      {GOOGLE_SIGN_IN_FLAG && !isFacebookBrowser && (
        <>
          {!isSigningInWithGoogle ? (
            <GoogleSignInButton
              containerId="google-sign-in-magic-link"
              onSuccess={onSignInWithThirdParty}
              onSignInStart={() => setIsSigningInWithGoogle(true)}
            />
          ) : (
            <SignInButtonLoadingUI />
          )}
          <Divider backgroundColor="var(--light-color)" />
        </>
      )}
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
      >
        {({ isSubmitting }) => (
          <>
            <Field
              label="Email address"
              name="email"
              type="email"
              component={InputField}
              autoComplete="email"
              placeholder="Enter your email address"
            />

            <div className={styles.buttons}>
              <Button type="submit" fullWidth isLoading={isSubmitting}>
                Send link
              </Button>
              {onSignInWithPassword && (
                <Button
                  variant="transparentGreen"
                  type="button"
                  fullWidth
                  onClick={onSignInWithPassword}
                  style={{ marginTop: '1rem' }}
                >
                  Sign in with my password
                </Button>
              )}
              {onCancel && (
                <Button
                  variant="link"
                  type="button"
                  fullWidth
                  onClick={onCancel}
                  style={{ marginTop: '3.2rem' }}
                >
                  Back
                </Button>
              )}
            </div>
          </>
        )}
      </Form>
    </>
  );
};

export default MagicLinkModalForm;

function validate(formValues) {
  return {
    email: valid(required(), email())(formValues.email),
  };
}

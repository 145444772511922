import React from 'react';
import cx from 'classnames';

import styles from '~/components/static/static.module.scss';

const FullWidthSection = ({
  backgroundColor,
  first,
  wrapInContainer = true,
  children,
  className,
  innerClassName,
  id,
}) => {
  return (
    <section
      id={id}
      className={cx(
        styles.fullWidthSection,
        {
          [`${styles.firstSection}`]: first,
        },
        className
      )}
      style={{
        backgroundColor,
      }}
    >
      <div className={wrapInContainer ? 'container' : null}>
        <div className={cx(styles.fullWidthSectionInner, innerClassName)}>
          {children}
        </div>
      </div>
    </section>
  );
};

export default FullWidthSection;

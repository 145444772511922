import React from 'react';

import styles from '~/components/the-shed/components/dash-header.module.scss';

const DashHeader = ({ title }) => (
  <header className={styles.headerWrapper}>
    <h2 className={styles.headerText}>{title}</h2>
    <div className={styles.tagLineDash} />
  </header>
);

export { DashHeader };

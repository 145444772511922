export const addressApi = (http) => {
  return {
    createShippingAddress({userUuid, ...address}) {
      return http.post(`/api/users/${userUuid}/shipping_addresses/`, address);
    },

    updateDefaultShippingAddress({userUuid, addressUuid}) {
      return http.patch(`/api/users/${userUuid}/shipping_addresses/${addressUuid}`);
    },
    
    updateShippingAddress({userUuid, addressUuid,...address}) {
      return http.put(`/api/users/${userUuid}/shipping_addresses/${addressUuid}`, address);
    },

    deleteShippingAddress({userUuid, addressUuid}) {
      return http.delete(`/api/users/${userUuid}/shipping_addresses/${addressUuid}`);
    }
  };
};

import React from 'react';
import cx from 'classnames';
import MaskedInput from 'react-text-mask';

import styles from './inputs.module.scss';

const InputField = (props) => {
  const hasError = props.meta.submitFailed && props.meta.error;
  const placeholder = props.input.placeholder || props.label;
  const { mask, ...inputProps } = props.input;
  const variant = props.variant;

  const labelClasses = cx(styles.label, {
    [styles.labelError]: hasError,
    [styles[`label-${variant}`]]: Boolean(variant),
  });

  const sublabelClasses = cx(styles.subLabel, {
    [styles.subLabelError]: hasError,
    [styles[`subLabel-${variant}`]]: Boolean(variant),
  });

  const inputClasses = cx(styles.input, props.input.className, {
    [styles.inputError]: hasError,
    [styles[`input-${variant}`]]: Boolean(variant),
  });

  const errorClasses = cx(styles.errorMsg, {
    [styles[`errorMsg-${variant}`]]: Boolean(variant),
  });

  const id = props.input.id || props.input.name;

  return (
    <div>
      {props.label && (
        <label htmlFor={id} className={labelClasses}>
          {props.label}
        </label>
      )}

      {props.subLabel && <p className={sublabelClasses}>{props.subLabel}</p>}

      {mask ? (
        <MaskedInput
          {...inputProps}
          mask={mask}
          id={id}
          className={inputClasses}
          placeholder={placeholder}
          disabled={inputProps.disabled}
        />
      ) : (
        <input
          {...inputProps}
          id={id}
          className={inputClasses}
          placeholder={placeholder}
          disabled={inputProps.disabled}
        />
      )}

      {hasError && <p className={errorClasses}>{props.meta.error}</p>}
    </div>
  );
};

export default InputField;

import { useCallback, useEffect } from 'react';

import { api } from '~/api';
import { GOOGLE_SIGN_IN_FLAG } from '~/feature-flags';
import { useFlashMessage } from '~/features/flash-messages/flash-message-actions';
import {
  SIGN_IN_PROVIDER,
  useSession,
} from '~/features/session/session-actions';
import { useScript } from '~/hooks/use-script';
import { EventType, analyticsBeacon } from '~/utils/analytics';
import { captureException } from '~/utils/exception-tracking';
import { isFacebookBrowser } from '~/utils/is-facebook-browser';

export const useGoogleSignIn = ({
  onSuccess = () => {},
  source,
  onSignInStart = () => {},
  onError = () => {},
} = {}) => {
  const { showFlashMessage } = useFlashMessage();

  const { signIn } = useSession();
  const [isLoaded, hasError] = useScript(
    `https://accounts.google.com/gsi/client`,
    GOOGLE_SIGN_IN_FLAG && !isFacebookBrowser
  );

  const handleGoogle = useCallback(
    async (response) => {
      onSignInStart();

      // Validate the Google token
      let authResponse;
      try {
        authResponse = await api.sessions.validateGoogleToken(
          response.credential,
          source
        );
      } catch (error) {
        captureException(
          new GoogleSignInError('Error validating Google token.'),
          {
            extras: { error: JSON.stringify(error) },
          }
        );

        showFlashMessage(
          'Something went wrong signing you in. You may need to refresh the page and try again.'
        );

        return onError(error);
      }

      const isPostRegistration = authResponse.created;
      const { email, token } = authResponse;

      try {
        await signIn(
          { email, password: token },
          isPostRegistration,
          SIGN_IN_PROVIDER.GOOGLE
        );
      } catch (error) {
        captureException(
          new GoogleSignInError('Error signing in with Google.'),
          {
            extras: { errorInfo: JSON.stringify(error) },
          }
        );

        showFlashMessage(
          'Something went wrong signing you in. You may need to refresh the page and try again.'
        );

        return onError(error);
      }

      if (isPostRegistration) {
        // The call to `signIn` doesn't return a user, so we aren't able to pass user slug or UUID here
        analyticsBeacon.emit(EventType.ACCOUNT_CREATED, {
          email,
          source,
          provider: SIGN_IN_PROVIDER.GOOGLE,
        });
      }

      try {
        await onSuccess();
      } catch {
        // Do nothing if onSuccess fails
      }
    },
    [onSignInStart, onSuccess, showFlashMessage, signIn, source, onError]
  );

  useEffect(() => {
    if (hasError) {
      onError();
    }
  }, [hasError, onError]);

  useEffect(() => {
    if (isLoaded && window.google?.accounts?.id) {
      window.google.accounts.id.initialize({
        client_id: window.env.GOOGLE_CLIENT_ID,
        cancel_on_tap_outside: false,
        prompt_parent_id: 'google-one-tap',
        callback: handleGoogle,
      });
    }
  }, [handleGoogle, isLoaded]);

  return {
    isLoaded,
    hasError,
  };
};

class GoogleSignInError extends Error {
  constructor(message) {
    super(message);
    this.name = 'GoogleSignInError';
  }
}

import React from 'react';
import { Link } from 'react-router-dom';

import { useCart } from '~/hooks/use-cart';
import { cartRoute } from '~/routes';
import { subscriptionType } from '~/features/subscriptions/subscription';

import styles from '~/components/navigation/cart-link/cart-link.module.scss';

const CartLink = () => {
  const { cart, bundledCartItems } = useCart();

  // Since bundles can contain multiple items, we only want to display them as a
  // single item in the cart.

  const { addons, ...cartBundles } = bundledCartItems;

  // If the `cartBundles` contain any Garden products (`subscriptionType` === 'flo' + truthy `bundleUuid`),
  // add one to the total number of cart items (Lawn Plan + Garden items in Lawn Plan = 2 items)
  const containsGardenPlan = Object.values(cartBundles)
    .flatMap((items) => items)
    .some(
      (item) =>
        item.bundleUuid && item.subscriptionType === subscriptionType.FLOWER
    );

  const numAddOnItems =
    addons?.reduce((sum, cartItem) => {
      return sum + cartItem.quantity;
    }, 0) || 0;

  const totalNumItems =
    Object.keys(cartBundles).length +
    numAddOnItems +
    (containsGardenPlan ? 1 : 0); // Add 1 to cart quantity if we have Garden items in Lawn Plan

  return (
    <Link
      aria-label={cart ? `${numAddOnItems} item(s) in your cart` : 'Your cart'}
      className={styles.button}
      to={cartRoute()}
      data-heap-id="nav-cart"
    >
      <p>Cart</p>
      <div className={styles.iconsContainer}>
        <svg
          aria-labelledby="Cart icon"
          width="20"
          height="20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.55 11c.75 0 1.41-.41 1.75-1.03l3.58-6.49A.996.996 0 0019.01 2H4.21l-.94-2H0v2h2l3.6 7.59-1.35 2.44C3.52 13.37 4.48 15 6 15h12v-2H6l1.1-2h7.45zM5.16 4h12.15l-2.76 5H7.53L5.16 4zM6 16c-1.1 0-1.99.9-1.99 2S4.9 20 6 20s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
            fill="currentColor"
          />
        </svg>
        {totalNumItems > 0 && (
          <span className={styles.countIcon}>{totalNumItems}</span>
        )}
      </div>
    </Link>
  );
};

export default CartLink;

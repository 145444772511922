import { useEffect } from 'react';

/**
 * Given a ref and a callback, this effect will call the callback function
 * when anything outside of the ref is clicked.
 */
export const useOutsideClick = (ref, cb = () => {}) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref?.current && !ref.current.contains(event.target)) {
        cb();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

import React from 'react';

import BlockContent from '~/components/layouts/block-content';
import LazyImg from '~/components/lazy-img';
import ProductLink from '~/components/products/product-link';
import { urlFor } from '~/sanity/images';

import styles from '~/components/the-shed/components/widgets/product-widget/product-widget.module.scss';

const ProductWidget = ({
  slug,
  productPhotoUrl,
  productName,
  productDetails,
}) => (
  <div className={styles.container}>
    <ProductLink slug={slug}>
      <div className={styles.productPhoto}>
        <LazyImg
          className={styles.productPhoto}
          src={productPhotoUrl}
          alt={productName}
          width="110"
          height="110"
        />
      </div>
    </ProductLink>

    <div className={styles.productDetails}>
      <ProductLink slug={slug}>
        <p className={styles.productName}>{productName}</p>
      </ProductLink>

      <BlockContent content={productDetails} />
    </div>
  </div>
);

export const ProductWidgetSerializer = (props) => {
  const { product, details, header } = props.node;

  let title;
  if (header) {
    const withProductName = header.replace(/{productName}/g, product.name);
    title = withProductName;
  } else {
    title = product.name;
  }

  const productPhotoUrl = urlFor(product.primaryImage)
    .width(220)
    .fit('max')
    .url();

  return (
    <ProductWidget
      slug={product.slug}
      productPhotoUrl={productPhotoUrl}
      productName={title}
      productDetails={details}
    />
  );
};

export default ProductWidget;

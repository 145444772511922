import React from 'react';
import cx from 'classnames';

import { ArticleBody } from '~/components/the-shed/article/article-body';

import styles from '~/components/the-shed/components/widgets/sunday-tip/sunday-tip.module.scss';

const SundayTipSerializer = ({ node }) => {
  const { icon, text } = node;

  return (
    <div className={cx(styles.tipContainer, 'tipContainer')}>
      <TipIcon icon={icon} />
      <div className={styles.tipText}>
        <p>Sunday Tip:</p>
        <ArticleBody body={text} />
      </div>
    </div>
  );
};

export default SundayTipSerializer;

const TipIcon = ({ icon }) => {
  switch (icon) {
    default:
      return (
        <img
          alt="Sunday tip brain icon"
          className={styles.tipIcon}
          src="/icons/sunday-tip-icon.svg"
        />
      );
  }
};

import { useRef, useEffect } from 'react';

/**
 * Tracks the previous value, useful to compare changes across renders
 */
export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { useLawn } from '~/features/lawns/lawn-actions';
import {
  SubscriptionStatus,
  subscriptionType,
} from '~/features/subscriptions/subscription';
import { useSubscriptions } from '~/features/subscriptions/subscription-actions';
import { myPlanRoute, lawnDetailsRoute, newLawnRoute } from '~/routes';

/**
 *
 * @param {string} funnelType - the funnel where we want to direct the user. Can only
 * be `law` (use `subscriptionType` enum). This controls checks
 * for existing subscriptions and the logic of where we send the user on submit.
 */
export const useAddressInput = (funnelType = subscriptionType.LAWN_PLAN) => {
  const { subscriptions, isLoading: isLoadingSubscriptions } =
    useSubscriptions();
  const { lawn } = useLawn();

  const navigate = useNavigate();

  const [address, setAddress] = useState(null);

  /** Address input is visible if:
   *  User does NOT have an ACTIVE or SCHEDULED Subscription (of ANY kind)
   */
  const isAddressInputVisible =
    !subscriptions ||
    !subscriptions?.some((subscription) =>
      [SubscriptionStatus.ACTIVE, SubscriptionStatus.SCHEDULED].includes(
        subscription.status
      )
    );
  const isButtonDisabled = isAddressInputVisible && !address;

  const isCurrentSubscriber = subscriptions?.some(
    (subscription) =>
      subscription.planType === funnelType &&
      [SubscriptionStatus.ACTIVE, SubscriptionStatus.SCHEDULED].includes(
        subscription.status
      )
  );
  const isOtherSubscriber =
    !isCurrentSubscriber &&
    subscriptions?.some((subscription) =>
      [SubscriptionStatus.ACTIVE, SubscriptionStatus.SCHEDULED].includes(
        subscription.status
      )
    );

  const calculateNextRoute = () => {
    if (isCurrentSubscriber) {
      return myPlanRoute();
    } else if (isOtherSubscriber) {
      if (funnelType === subscriptionType.LAWN_PLAN) {
        return lawnDetailsRoute(lawn.uuid);
      } else {
        throw new Error('Unexpected funnel type used');
      }
    } else {
      if (funnelType === subscriptionType.LAWN_PLAN) {
        return newLawnRoute(address || undefined); // doesn't support a 'null' argument
      } else {
        throw new Error('Unexpected funnel type used');
      }
    }
  };

  const handleContinue = () => {
    navigate(calculateNextRoute());
  };

  return {
    calculateNextRoute,
    handleContinue,
    isAddressInputVisible,
    isButtonDisabled,
    isCurrentSubscriber,
    isLoadingSubscriptions,
    setAddress,
  };
};

export const usersApi = (http) => {
  return {
    find: async () => {
      const res = await http.get('/api/v2/users/');

      return {
        ...res,
        addresses: res?.allAddresses || [],
        shippingAddress: res?.allAddresses?.find(
          (address) => address.isDefault
        ),
      };
    },

    setPrivacyData: (settings) => {
      return http.post('/api/set_privacy_data/', {
        caResident: settings.caResident,
        doNotSellPersonalInfo: settings.doNotSell,
      });
    },

    update: (email, newUserInfo) => {
      return http.patch(
        `/api/users/${encodeURIComponent(email)}/`,
        newUserInfo
      );
    },
  };
};

import React from 'react';

import { useMediaQuery } from '~/hooks/use-media-query';
import {
  DashHeader,
  RecommendedArticle,
  SimpleRecommendedArticles,
  RECOMMENDED_ARTICLE_SIMPLE,
} from '~/components/the-shed/components';

import styles from '~/components/the-shed/components/recommended-reading.module.scss';

const SundayRecommendedArticles = ({ articles, recommendedArticleType }) => {
  const isDesktop = useMediaQuery('(min-width: 768px)');

  if (!articles?.length) {
    return null;
  }

  const renderedArticles = isDesktop ? articles : articles.slice(0, 4);

  // <SimpleRecommendedArticles /> is used on The Shed sidebar on desktop
  return recommendedArticleType === RECOMMENDED_ARTICLE_SIMPLE ? (
    <SimpleRecommendedArticles recommendedReading={articles} />
  ) : (
    <div className={styles.recommendedReading}>
      <DashHeader title={'Recommended Reading'} />
      <div className={styles.wrapper}>
        {renderedArticles.map((article, index) => (
          <RecommendedArticle
            article={article}
            index={index}
            key={article.title}
          />
        ))}
      </div>
    </div>
  );
};

export { SundayRecommendedArticles };

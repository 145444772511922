import React, { useEffect, useState } from 'react';

import CarouselGrid from '~/components/carousels/carousel-grid';
import CarouselButton, {
  carouselButtonDirection,
} from '~/components/carousels/carousel-button';
import { useHorizontalScroll } from '~/components/carousels/use-horizontal-scroll';
import { clamp } from '~/utils/functions';

import styles from '~/components/carousels/carousel.module.scss';

export const Carousel = ({
  activeIndex: externalActiveIndex = 0,
  onActiveIndexChange,
  buttonClassName,
  childrenPerScroll = 1,
  children,
  gridClassName,
  triggerChangeOnScroll,
}) => {
  const [activeIndex, setActiveIndex] = useState(externalActiveIndex);
  const [showButtons, setShowButtons] = useState(true);

  const numSlides = React.Children.count(children);

  const { ref: horizontalScrollRef, bind } = useHorizontalScroll({
    activeIndex,
    onActiveIndexChange: onActiveIndexChange || setActiveIndex,
    numSlides,
    triggerChangeOnScroll,
  });

  useEffect(() => {
    if (externalActiveIndex !== null && externalActiveIndex !== undefined) {
      setActiveIndex(externalActiveIndex);
    }
  }, [externalActiveIndex]);

  // Hide buttons when there aren't enough slides to warrant them
  useEffect(() => {
    const { offsetWidth, scrollWidth } = horizontalScrollRef.current;
    const scrollPerSlide = scrollWidth / numSlides;
    const slidesPerWindow = Math.round(offsetWidth / scrollPerSlide);

    if (slidesPerWindow >= numSlides) {
      setShowButtons(false);
    } else {
      setShowButtons(true);
    }
  }, [horizontalScrollRef, numSlides]);

  const handleClickSlideButton = (direction) => {
    const { offsetWidth, scrollWidth } = horizontalScrollRef.current;
    const unboundedNextIndex = activeIndex + childrenPerScroll * direction;
    const scrollPerSlide = scrollWidth / numSlides;
    const slidesPerWindow = Math.round(offsetWidth / scrollPerSlide);
    const maxIndex = numSlides - slidesPerWindow + 1;
    const nextIndex = clamp(unboundedNextIndex, 0, maxIndex);
    setActiveIndex(nextIndex);
  };

  return (
    <div className={styles.wrapper}>
      {showButtons && (
        <CarouselButton
          direction={carouselButtonDirection.PREV}
          onClick={() => handleClickSlideButton(-1)}
          className={buttonClassName}
        />
      )}
      <div ref={horizontalScrollRef} {...bind}>
        <CarouselGrid className={gridClassName}>{children}</CarouselGrid>
      </div>
      {showButtons && (
        <CarouselButton
          direction={carouselButtonDirection.NEXT}
          onClick={() => handleClickSlideButton(1)}
          className={buttonClassName}
        />
      )}
    </div>
  );
};

export default Carousel;

import React from 'react';

import DesktopNav from '~/components/navigation/desktop/desktop-nav';
import MobileNav from '~/components/navigation/mobile/mobile-nav';
import AlertMessage from '~/components/messages/alert-message';
import { useMediaQuery } from '~/hooks/use-media-query';

import styles from '~/components/navigation/desktop/desktop-nav.module.scss';

const SHOW_ALERT = false;
const ALERT_MESSAGE = `
We are currently experiencing technical difficulties. Our team is
actively working to fix the issue and we hope to have it resolved
soon. Thank you for your patience.
`;

const AppNav = ({ isOpen, toggleOpen }) => {
  const isDesktop = useMediaQuery('(min-width: 992px)');

  return (
    <>
      {SHOW_ALERT && (
        <AlertMessage style={{ margin: 0, textAlign: 'center' }}>
          {ALERT_MESSAGE}
        </AlertMessage>
      )}

      <div style={{ position: 'relative', marginBottom: '4rem' }}>
        {isDesktop ? (
          <DesktopNav />
        ) : (
          <MobileNav isOpen={isOpen} toggleOpen={toggleOpen} />
        )}

        <div id="google-one-tap" className={styles.googleOneTapContainer} />
      </div>
    </>
  );
};

export default AppNav;

import React from 'react';
import BlockContentToReact from '@sanity/block-content-to-react';

import styles from '~/components/layouts/block-content.module.scss';

const BlockContent = ({ content, serializers }) => {
  return typeof content === 'string' ? (
    <div className={styles.blockContent}>
      <p>{content}</p>
    </div>
  ) : (
    <div className={styles.blockContent}>
      <BlockContentToReact
        blocks={content}
        projectId="afv8yn22"
        dataset="develop"
        serializers={serializers}
      />
    </div>
  );
};

export default BlockContent;

import React, { useEffect, useState } from 'react';

import { SHED_ARTICLE_BODY_ID } from '~/components/the-shed/article/article-body';

import styles from '~/components/the-shed/components/progress-bar.module.scss';

const ShedProgressBar = () => {
  const [percentComplete, setPercentComplete] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const articleHeight =
        document.getElementById(SHED_ARTICLE_BODY_ID)?.offsetHeight;
      const articlePosition =
        document.getElementById(SHED_ARTICLE_BODY_ID)?.offsetTop;
      const articleBottom = articlePosition + articleHeight;
      const percentComplete = Math.round(
        (scrollPosition / articleBottom) * 100
      );
      setPercentComplete(percentComplete);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className={styles.progressBarWrapper}>
      <div
        className={styles.progressBar}
        style={{
          width: percentComplete + '%',
        }}
      />
    </div>
  );
};

export default ShedProgressBar;

import React, { useEffect, useState } from 'react';
import { animated } from '@react-spring/web';
import cx from 'classnames';

import Modal from '~/components/modals/modal';
import RegisterModalForm from '~/components/modals/funnel-auth/register-modal-form';
import SignInModalForm from '~/components/modals/funnel-auth/sign-in-modal-form';
import TermsOfUseLinks from '~/components/terms-and-conditions/terms-of-use-links';
import MagicLinkModalForm from '~/components/modals/funnel-auth/magic-link-modal-form';
import { useModal } from '~/features/modals/modal-actions';
import { useAnimatedPageTransitions } from '~/hooks/use-animated-page-transitions';
import { api } from '~/api';

import styles from '~/components/modals/modal.module.scss';

const Page = {
  REGISTER: 0,
  SIGN_IN: 1,
  MAGIC_LINK: 2,
};

/**
 * Passed to the onAuth() callback prop so the call site knows if the
 * user registered or signed in
 */
export const AuthType = {
  REGISTER: 'register',
  SIGN_IN: 'sign in',
};

const FunnelAuthModal = ({
  isOpen,
  onClose,
  onAuth = () => {},
  magicLinkRedirectUrl,
  content = {},
  source,
  initialPage = Page.REGISTER,
}) => {
  const { showModal } = useModal();
  const { transitions, transitionTo, resetPage } = useAnimatedPageTransitions(
    Page.REGISTER
  );

  const [email, setEmail] = useState();
  const [sentMagicLink, setSentMagicLink] = useState(false);

  useEffect(() => {
    if (initialPage) {
      transitionTo(initialPage, 1);
    }
  }, [initialPage, transitionTo]);

  const handleCloseModal = () => {
    resetPage();
    setSentMagicLink(false);
    onClose();
  };

  const handleRequestMagicLink = (email) => {
    return api.sessions
      .requestMagicLink({
        email,
        queryParams: { redirect: magicLinkRedirectUrl },
      })
      .then(() => {
        setEmail(email);
        setSentMagicLink(true);
      })
      .catch(() => showModal('ERROR'));
  };

  return (
    <Modal onClose={handleCloseModal} isOpen={isOpen} className={source}>
      {transitions((styleProps, item) => {
        switch (item) {
          case Page.SIGN_IN:
            return (
              <animated.div style={styleProps} className={styles.container}>
                <h1 className={styles.title}>Sign in</h1>
                <p className={cx(styles.text, styles.centerAlign)}>
                  It looks like you already have an account. Please sign in to
                  continue.
                </p>

                <SignInModalForm
                  onSuccess={() => {
                    onAuth(AuthType.SIGN_IN, { email });
                    handleCloseModal();
                  }}
                  onCancel={() => transitionTo(Page.REGISTER, -1)}
                  onRequestMagicLink={(email) => {
                    if (email) {
                      setEmail(email);
                    }
                    transitionTo(Page.MAGIC_LINK, -1);
                  }}
                  email={email}
                />
              </animated.div>
            );
          case Page.REGISTER:
            return (
              <animated.div style={styleProps} className={styles.container}>
                <h1 className={styles.title}>
                  {content.registerHeading || 'Save progress'}
                </h1>
                <p className={cx(styles.text, styles.centerAlign)}>
                  {content.registerCopy ||
                    'Enter your email address to save your account and continue.'}
                </p>

                <RegisterModalForm
                  source={source}
                  onCancel={handleCloseModal}
                  onSuccess={() => {
                    onAuth(AuthType.REGISTER, { email });
                    handleCloseModal();
                  }}
                  onExistingEmailError={(email) => {
                    setEmail(email);
                    transitionTo(Page.MAGIC_LINK, 1);
                  }}
                />
                <TermsOfUseLinks />
              </animated.div>
            );
          case Page.MAGIC_LINK:
            return (
              <animated.div style={styleProps} className={styles.container}>
                {sentMagicLink ? (
                  <>
                    <h1 className={styles.title}>Sign in link sent</h1>
                    <img
                      src="/icons/checkmark-circle.svg?v3"
                      alt=""
                      width="24"
                      height="24"
                      style={{
                        width: '3.2rem',
                        height: 'auto',
                        display: 'block',
                        margin: '-2.4rem auto 3.2rem auto',
                      }}
                    />
                    <p className={cx(styles.text, styles.centerAlign)}>
                      We're sending a sign in link to <strong>{email}</strong>.
                      <br />
                      Please allow a few minutes for the email to arrive.
                    </p>
                  </>
                ) : (
                  <>
                    <h1 className={styles.title}>Get a sign in link</h1>
                    <p className={cx(styles.text, styles.centerAlign)}>
                      It looks like you already have an account. We'll send you
                      a link you can use to sign in.
                    </p>
                    <MagicLinkModalForm
                      initialValues={{ email }}
                      onSubmit={({ email }) => handleRequestMagicLink(email)}
                      onSignInWithPassword={() => transitionTo(Page.SIGN_IN, 1)}
                      onCancel={() => transitionTo(Page.REGISTER, -1)}
                      onSignInWithThirdParty={() => {
                        onAuth(AuthType.SIGN_IN, { email });
                        handleCloseModal();
                      }}
                    />
                  </>
                )}
              </animated.div>
            );
          default:
            return null;
        }
      })}
    </Modal>
  );
};

export default FunnelAuthModal;

import { useQuery } from 'react-query';

import { api } from '~/api';
import { isUserFacingShipment } from '~/features/shipments/shipment';
import { useSession } from '~/features/session/session-actions';

export function useShipments(options = {}) {
  const { isSignedIn } = useSession();
  const {
    isLoading,
    error,
    data: shipments,
  } = useQuery('shipments', () => api.shipments.all(), {
    enabled:
      typeof options.enabled !== 'undefined'
        ? Boolean(options.enabled && isSignedIn)
        : Boolean(isSignedIn),
  });

  const userFacingShipments = shipments?.filter(isUserFacingShipment);

  return { shipments, userFacingShipments, isLoading, error };
}

export const toKebabCase = (string) => {
  if (!string) {
    return '';
  }

  return string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();
};

export const stripNonLetterChars = (string) => {
  if (!string) {
    return '';
  }

  return string.replace(/\d\s/g, '').replace(/[^\p{L}\s]/gu, '');
};

export const toSentenceCase = (string) => {
  if (!string) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const toTitleCase = (string) => {
  if (!string) {
    return '';
  }

  return string.toLowerCase().split(' ').map(toSentenceCase).join(' ');
};

export const hyphenToSnakeCase = (string) => {
  if (!string) {
    return '';
  }

  if (typeof string !== 'string') {
    return string;
  }

  return string.replaceAll('-', '_');
};

export const stripSnakeCase = (string) => {
  if (!string) {
    return '';
  }

  return string.replaceAll('_', ' ');
};

export const toSnakeCase = (string) => {
  if (!string) {
    return '';
  }

  return string.replaceAll('-', ' ').replaceAll(' ', '_');
};

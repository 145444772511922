import React from 'react';
import { animated, useSpring } from '@react-spring/web';

import { useMeasure } from '~/hooks/use-measure';

const Collapsible = ({ isOpen, children }) => {
  const [innerRef, measurements] = useMeasure({
    initialValue: null,
  });

  const height = measurements?.height;

  const springProps = useSpring({
    height: isOpen ? height : 0,
    opacity: isOpen ? 1 : 0,
    overflow: 'hidden',
  });

  return (
    <animated.div style={springProps}>
      <div ref={innerRef}>{children}</div>
    </animated.div>
  );
};

export default Collapsible;

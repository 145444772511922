import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import cx from 'classnames';

import Accordion from '~/components/accordion/accordion';
import AddProductForm from '~/components/products/product-detail/add-product-form';
import AlertMessage from '~/components/messages/alert-message';
import ProductBadge from '~/components/products-grid/products-cell-badge';
import BlockContent from '~/components/layouts/block-content';
import Breadcrumbs from '~/components/breadcrumbs/breadcrumbs';
import GuaranteeBadge from '~/components/sunday-store/components/product-details/guarantee-badge';
import LoadingIndicator from '~/components/loading-indicator';
import LinkedContent from '~/components/sunday-store/components/product-details/linked-content';
import MobileFloatingCTA from '~/components/call-to-action/mobile-floating-call-to-action';
import OnePercentBanner from '~/components/products/one-percent-banner';
import ProductCarousels from '~/components/products/product-detail/product-carousels';
import ProductLink from '~/components/products/product-link';
import SeedSpecIcons from '~/components/products/seed-spec-icons';
import ShippingBadge from '~/components/sunday-store/components/product-details/shipping-badge';
import RelatedProducts from '~/components/sunday-store/components/product-details/related-products';
import TrendingItems from '~/components/sunday-store/components/product-details/trending-products';
import PDPReviews from '~/components/static/reviews/pdp-reviews';
import { PlantInfoList } from '~/components/sunday-store/components/product-details/plant-components';
import { useProductStructuredData } from '~/components/products/product-detail/use-product-structured-data';
import RecommendedProductsList from '~/components/sunday-store/components/product-details/recommended-products-list';
import { useProductData } from '~/features/products/products-actions';
import { useFlashMessage } from '~/features/flash-messages/flash-message-actions';
import { useModal } from '~/features/modals/modal-actions';
import {
  categoryPageRoute,
  customLawnPlanRoute,
  shedArticleRoute,
  shopAllRoute,
  sundayStoreProductDetailsRoute,
} from '~/routes';
import { assetUrlFor } from '~/sanity/assets';
import { useSEO } from '~/hooks/use-seo';
import { urlFor } from '~/sanity/images';
import { useLawn } from '~/features/lawns/lawn-actions';
import {
  analyticsBeacon,
  DeprecatedEventType,
  EventType,
} from '~/utils/analytics';
import { centsToCurrency } from '~/utils/numbers';
import { toSentenceCase } from '~/utils/strings';
import {
  hideGladlyChat,
  showGladlyChat,
} from '~/utils/analytics/plugins/gladly';
import { CHANNEL_NON_FUNNEL } from '~/utils/channels';
import { captureException } from '~/utils/exception-tracking';
import getChannelAndPrice from '~/utils/get-channel-price';
import { seedSubcategorySlug } from '~/features/products/products';
import SeedFinderCta from '~/components/sunday-store/components/product-details/seed-finder-cta';
import { useReviewProductSku } from '~/hooks/use-review-product-sku';
import { REGISTRATION_SOURCES } from '~/components/modals/funnel-auth/register-modal-form';
import { useSession } from '~/features/session/session-actions';
import { lawnSubscriptionStatus } from '~/features/lawns/lawn';
import Button from '~/components/button';

import styles from '~/components/sunday-store/components/product-details/product-details-page.module.scss';

export const PDP_DISPLAY_TYPE = {
  LAWN_PLAN_PRODUCT: 'lawn-plan-product',
  STORE_PRODUCT: 'store-product',
};

const ProductDetailsPage = () => {
  const navigate = useNavigate();
  const { showFlashMessage } = useFlashMessage();
  const { showModal } = useModal();
  const params = useParams();
  const location = useLocation();
  const { lawn, isLoading: isLoadingLawn } = useLawn();
  const {
    error,
    isLoading: isLoadingProduct,
    product,
    productsByCategory,
    recommendedProducts,
  } = useProductData({
    slug: params.slug,
  });
  const { user } = useSession();
  const isActiveLawnPlanSubscriber =
    user?.lawnSubscriptionStatus === lawnSubscriptionStatus.ACTIVE;

  const reviewProductSku = useReviewProductSku(product?.purchaseSku?.skuId);

  const { objectID, queryID, position, query } = location?.state || {};

  const [qtySelected, setQtySelected] = useState(1);

  const isSeedProduct = product?.productDetails?.categories?.some(
    (category) => category?.subCategory?.slug === seedSubcategorySlug
  );

  useEffect(() => {
    if (product) {
      setQtySelected(1);
    }
  }, [product]);

  useSEO({
    title:
      product?.productDetails?.seo?.documentTitle ||
      product?.productDetails?.name,
    description: product?.productDetails?.seo?.documentDescription,
    canonicalUrl: 
      product?.productDetails?.seo?.canonicalUrl ? 
      product?.productDetails?.seo?.canonicalUrl : 
      sundayStoreProductDetailsRoute({
          category: product?.productDetails?.categories?.[0]?.category?.slug,
          slug: product?.productDetails?.slug
      }),
  });
  useProductStructuredData(product);

  useEffect(() => {
    if (product) {
      const { unitPrice } = getChannelAndPrice(CHANNEL_NON_FUNNEL, product);
      const { productDetails, purchaseSku } = product;

      analyticsBeacon.emit(DeprecatedEventType.VIEW_CONTENT, {
        sku: purchaseSku.skuId,
        name: productDetails.name,
        price: unitPrice,
        category: productDetails?.categories?.[0]?.category?.name,
      });

      analyticsBeacon.emit(EventType.PRODUCT_VIEWED, {
        sku: purchaseSku.skuId,
        name: productDetails.name,
        price: unitPrice,
        category: productDetails?.categories?.[0]?.category?.name,
        queryID,
        objectID: objectID || productDetails.id,
        position,
        query,
        productId: productDetails.id,
        productUrl: sundayStoreProductDetailsRoute({
          category: productDetails.categories?.[0]?.category?.slug,
          slug: productDetails.slug,
        }),
        imageUrl: productDetails.primaryImage,
      });
    }
  }, [objectID, position, product, query, queryID]);

  // Redirect when we don't find a product
  useEffect(() => {
    if (!product && !error && !isLoadingProduct) {
      showFlashMessage(
        "We couldn't find the page you were looking for.",
        'error'
      );
      captureException(new Error(`Invalid PDP request: ${params.slug}`), {
        level: 'info',
        extras: {
          slug: params.slug,
          url: window.location.href,
          referrer: document.referrer,
        },
        fingerprints: ['invalidPDP', params.slug],
      });
      navigate(shopAllRoute(), { replace: true });
    }
  }, [
    error,
    isLoadingProduct,
    navigate,
    params.slug,
    product,
    showFlashMessage,
  ]);

  if (isLoadingProduct) {
    return (
      <div
        style={{
          height: '100vh',
          margin:
            '-4rem var(--inverse-gutter) var(--inverse-body-padding-bottom)',
          paddingTop: '4rem',
          textAlign: 'center',
        }}
      >
        <LoadingIndicator />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ maxWidth: '64rem', margin: 'auto' }}>
        <AlertMessage heading="Something went wrong">
          There was an unexpected problem, please try again later
        </AlertMessage>
      </div>
    );
  }

  if (!product) {
    return null;
  }

  const { productDetails, purchaseSku } = product;
  const { unitPrice, fullPrice } = getChannelAndPrice(
    CHANNEL_NON_FUNNEL,
    product
  );
  const isLawnPlanProduct =
    productDetails.pdpDisplayType === PDP_DISPLAY_TYPE.LAWN_PLAN_PRODUCT;

  const showCta = !isLawnPlanProduct || !isActiveLawnPlanSubscriber;

  /**
   * Live Plants and Trees products are now categorized under `Garden` with the subcategories of `trees` and `shrubs-bushes`.
   */

  const images = [];
  if (productDetails?.primaryImage) {
    images.push(productDetails.primaryImage);
  }
  if (productDetails?.carouselImages) {
    images.push(...productDetails.carouselImages);
  }

  const handleQtyFieldChange = ({ target }) => {
    setQtySelected(target.value);
  };

  return (
    <div className={styles.page}>
      <div className={styles.innerPage}>
        <BreadcrumbsSection productDetails={productDetails} />
        <section className={styles.bodyContainer}>
          <div className={styles.columnSticky}>
            <ProductHeader
              isLawnPlanProduct={isLawnPlanProduct}
              reviewProductSku={reviewProductSku}
              formFactor={productDetails.formFactor}
              fullPrice={fullPrice}
              productName={productDetails.name}
              productDetails={productDetails}
              purchaseSku={purchaseSku}
              qtySelected={qtySelected}
              scientificName={productDetails.plantInfo?.scientificName}
              unitPrice={unitPrice}
            />

            <div className={cx(styles.imgCarousels, styles.imgCarouselsMobile)}>
              <ProductBadge
                fullPrice={fullPrice}
                productDetails={productDetails}
                purchaseSku={purchaseSku}
                unitPrice={unitPrice}
                variant="largeBadge"
              />
              <ProductCarousels
                images={images}
                videos={productDetails.videos}
              />
            </div>

            <div className={styles.shortDescription}>
              {productDetails.tagLine && (
                <p className={styles.tagLineText}>
                  {productDetails.tagLine}{' '}
                  <span className={styles.tagLineDash} />
                </p>
              )}

              <div className={styles.shortDescBlockContent}>
                <BlockContent content={productDetails.shortDescription} />
              </div>
            </div>

            {!isLawnPlanProduct && (
              <ShippingBadge
                productDetails={productDetails}
                purchaseSku={purchaseSku}
              />
            )}

            {showCta && (
              <div className={styles.addToCart}>
                {isLawnPlanProduct ? (
                  <Button
                    id="plan-exclusive-cta"
                    to={customLawnPlanRoute()}
                    className={styles.customPlanButton}
                    fullWidthMobile
                  >
                    Shop custom plans
                  </Button>
                ) : (
                  <AddProductForm
                    isLawnPlanProduct={isLawnPlanProduct}
                    objectID={objectID || productDetails.id}
                    queryID={queryID}
                    position={position}
                    channel={CHANNEL_NON_FUNNEL}
                    onFieldChange={handleQtyFieldChange}
                    qtySelected={qtySelected}
                    error={error || !purchaseSku}
                    productDetails={productDetails}
                    purchaseSku={purchaseSku}
                    source={REGISTRATION_SOURCES.STORE}
                    afterSubmit={(quantity) => {
                      hideGladlyChat();
                      showModal('ADDED_TO_CART', {
                        product,
                      });
                    }}
                  />
                )}
              </div>
            )}

            {isSeedProduct && <SeedFinderCta />}

            {!purchaseSku.isRestrictedInState && <GuaranteeBadge />}

            {recommendedProducts && (
              <RecommendedProductsList
                products={recommendedProducts}
                title={
                  product?.productDetails?.relatedProducts?.heading || undefined
                }
                onAdd={(addedProduct) => {
                  hideGladlyChat();
                  showModal('ADDED_TO_CART', {
                    product: addedProduct,
                  });
                }}
              />
            )}
          </div>

          <div className={styles.columnLeft}>
            <div className={styles.imgCarousels}>
              <ProductBadge
                fullPrice={fullPrice}
                productDetails={productDetails}
                purchaseSku={purchaseSku}
                unitPrice={unitPrice}
                variant="largeBadge"
              />
              <ProductCarousels
                images={images}
                videos={productDetails.videos}
              />
            </div>
            {!isLoadingLawn && (
              <InfoContent
                lawn={lawn}
                productDetails={productDetails}
                purchaseSku={purchaseSku}
              />
            )}
          </div>
        </section>

        <TrendingItems
          currentObjectID={objectID || productDetails.id}
          productDetails={productDetails}
          productsByCategory={
            productsByCategory?.[
              productDetails?.categories?.[0]?.category?.slug
            ]
          }
        />

        <LinkedContent linkedContent={productDetails.linkedContent} />

        <h2 className={styles.reviewsHeading}>Customer reviews</h2>
        <PDPReviews
          productId={reviewProductSku}
          productName={product.productDetails.name}
          productPageUrl={
            product.productDetails?.seo?.canonicalUrl ||
            sundayStoreProductDetailsRoute({
              category: product.productDetails.categories?.[0]?.category?.slug,
              slug: product.productDetails.slug,
            })
          }
          productImageUrl={urlFor(product.productDetails.primaryImage)}
          widget="yotpo-main-widget"
        />

        <RelatedProducts
          currentObjectID={objectID || productDetails.id}
          productDetails={productDetails}
          productsByCategory={
            productsByCategory?.[
              productDetails?.categories?.[0]?.category?.slug
            ]
          }
        />

        {productDetails?.inStock && !error && showCta && (
          <MobileFloatingCTA
            elementToLookFor="div[class*='addToCart']"
            offSetMargin="0rem"
            whenShowing={() => {
              hideGladlyChat();
            }}
            whenHiding={() => {
              showGladlyChat();
            }}
            className={styles.mobileAddToCart}
          >
            {isLawnPlanProduct ? (
              <Button
                id="plan-exclusive-cta-mobile"
                to={customLawnPlanRoute()}
                className={styles.customPlanButton}
                fullWidthMobile
              >
                Shop custom plans
              </Button>
            ) : (
              <AddProductForm
                objectID={objectID || productDetails.id}
                queryID={queryID}
                position={position}
                channel={CHANNEL_NON_FUNNEL}
                onFieldChange={handleQtyFieldChange}
                qtySelected={qtySelected}
                error={error || !purchaseSku}
                productDetails={productDetails}
                purchaseSku={purchaseSku}
                source={REGISTRATION_SOURCES.STORE}
                afterSubmit={(quantity) => {
                  hideGladlyChat();
                  showModal('ADDED_TO_CART', {
                    product,
                  });
                }}
              />
            )}
          </MobileFloatingCTA>
        )}
      </div>

      <OnePercentBanner />
    </div>
  );
};

export default ProductDetailsPage;

const BreadcrumbsSection = ({ productDetails }) => (
  <Breadcrumbs className={styles.breadcrumbsContainer}>
    <li>
      <Link to={shopAllRoute()}>Shop</Link>
    </li>
    <li>
      <Link
        to={categoryPageRoute(productDetails.categories?.[0]?.category?.slug)}
      >
        {productDetails.categories?.[0]?.category?.name}
      </Link>
    </li>
    {productDetails.categories?.[0]?.subCategory?.name && (
      <li>
        <Link
          to={categoryPageRoute(
            productDetails.categories?.[0]?.category?.slug,
            productDetails.categories?.[0]?.subCategory?.slug
          )}
        >
          {productDetails.categories?.[0]?.subCategory?.name}
        </Link>
      </li>
    )}
    <li>{productDetails.name}</li>
  </Breadcrumbs>
);

const ProductHeader = ({
  isLawnPlanProduct,
  reviewProductSku,
  formFactor,
  fullPrice,
  productName,
  productDetails,
  purchaseSku,
  qtySelected,
  scientificName,
  unitPrice,
}) => (
  <div className={styles.productHeader}>
    <h1 className={styles.productName}>{productName}</h1>
    {scientificName && (
      <p className={styles.productScientific} data-testid="scientific-name">
        {toSentenceCase(scientificName)}
      </p>
    )}
    {!isLawnPlanProduct && (
      <Price
        classname={styles.productPrice}
        fullPrice={fullPrice}
        purchaseSku={purchaseSku}
        qtySelected={qtySelected}
        unitPrice={unitPrice}
      />
    )}
    <p className={styles.productFormFactor}>{formFactor}</p>
    <PDPReviews
      productId={reviewProductSku}
      productName={productDetails.name}
      productPageUrl={productDetails?.seo?.canonicalUrl}
      productImageUrl={urlFor(productDetails.primaryImage)}
      widget="bottomLine"
    />
  </div>
);

export const Price = ({
  classname,
  fullPrice,
  purchaseSku,
  qtySelected,
  unitPrice,
}) => {
  const regularPrice = (
    <p className={classname}>{centsToCurrency(fullPrice, { round: true })}</p>
  );

  const discountPrice = (
    <>
      <p className={classname}>
        <span className={styles.salePrice}>
          {centsToCurrency(unitPrice, { round: true })}
        </span>{' '}
        <span className={styles.fullPrice}>
          {centsToCurrency(fullPrice, { round: true })}
        </span>
      </p>
    </>
  );

  if (unitPrice === fullPrice) {
    return regularPrice;
  } else return discountPrice;
};

const InfoContent = ({ lawn, productDetails, purchaseSku }) => {
  return (
    <div className={styles.infoContent}>
      <FlexContent content={productDetails.pdpFlexContent} />
      {productDetails.plantInfo &&
        Object.keys(productDetails.plantInfo).length > 0 && (
          <PlantInfoList plantInfo={productDetails.plantInfo} />
        )}

      {productDetails.faqs?.length > 0 && (
        <InfoContentAccordion title="FAQ">
          {productDetails.faqs.map((faq) => (
            <div key={faq.question}>
              <h4 className={styles.faqQuestion}>{faq.question}</h4>
              <div className={styles.faqAnswerBlockContent}>
                <BlockContent content={faq.answer} />
              </div>
            </div>
          ))}
        </InfoContentAccordion>
      )}

      {productDetails.documents?.length > 0 && (
        <InfoContentAccordion title="Documents">
          {productDetails.documents.map((document) => (
            <div key={document.displayName}>
              <a
                className={styles.documentLink}
                href={assetUrlFor(document.asset)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {document.displayName}
              </a>
            </div>
          ))}
        </InfoContentAccordion>
      )}
    </div>
  );
};

const InfoContentAccordion = ({ title, children }) => (
  <Accordion
    buttonContent={
      <span className={styles.infoContentAccordionLabel}>{title}</span>
    }
    buttonClassName={styles.infoContentAccordionButton}
  >
    <div className={styles.infoContentAccordionInner}>{children}</div>
  </Accordion>
);

const serializers = {
  types: {
    seedSpecIcons: (props) => {
      return <SeedSpecIcons icons={props.node.icons} />;
    },
  },
  marks: {
    link: ({ mark, children }) => {
      const { href, linkType } = mark;

      switch (linkType) {
        case 'articleLink':
          const { category, slug: articleSlug } = mark.articleLink;
          return (
            <Link
              to={shedArticleRoute({
                category: category.articleCategory.slug,
                slug: articleSlug,
              })}
            >
              {children}
            </Link>
          );

        case 'productLink':
          return (
            <ProductLink slug={mark.productLink.slug}>{children}</ProductLink>
          );

        case 'generalLink':
          const parsed = new URL(href);
          if (
            parsed.host === 'getsunday.com' ||
            parsed.host === 'www.getsunday.com'
          ) {
            return <Link to={parsed.pathname}>{children}</Link>;
          } else {
            return (
              <a href={href} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            );
          }

        case 'fileLink':
          return (
            <a
              href={assetUrlFor(mark.fileLink?.asset)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {children}
            </a>
          );

        default:
          return (
            <a href={href} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          );
      }
    },
    small: ({ children }) => {
      return <small>{children}</small>;
    },
  },
};

const FlexContent = ({ content }) => {
  if (!content) {
    return null;
  }

  return (
    <div className={styles.flexContent}>
      <BlockContent content={content} serializers={serializers} />
    </div>
  );
};

import React, { useState, useEffect } from 'react';

import { useVisibility } from '~/hooks/use-visibility';

/**
 * Only render children once they're visible on screen
 * or during the next available idle period. A placeholder height can
 * be passed to avoid layout shifts when children load
 */
const LazyLoad = ({ immediate, height, children }) => {
  const [timeoutExpired, setTimeoutExpired] = useState(false);
  const [ref, isVisible] = useVisibility({ triggerOnce: true });

  const showChildren = isVisible || timeoutExpired;

  useEffect(() => {
    if (!immediate) {
      return;
    }

    const timer = requestIdleCallback(() => setTimeoutExpired(true));

    return () => cancelIdleCallback(timer);
  });

  return (
    <div ref={ref} style={{ height: showChildren ? undefined : height }}>
      {showChildren && children}
    </div>
  );
};

export default LazyLoad;

import { sundayStoreProductDetailsRoute } from '~/routes';
import { useDocumentHead } from '~/hooks/use-document-head';
import { urlFor } from '~/sanity/images';
import { centsToCurrency } from '~/utils/numbers';
import { captureException } from '~/utils/exception-tracking';
import getChannelAndPrice from '~/utils/get-channel-price';
import { CHANNEL_NON_FUNNEL } from '~/utils/channels';

export const useProductStructuredData = (product) => {
  let structuredData = {};

  try {
    if (product) {
      const { unitPrice } = getChannelAndPrice(CHANNEL_NON_FUNNEL, product);

      // Allow for vaious stock conditions
      let productAvailability;
      if (product.productDetails.inStock) {
        productAvailability = "https://schema.org/InStock";
        if (product.productDetails.customBadge) {
          switch (product.productDetails.customBadge) {
            case "Pre-Order":
            case "Coming Soon":
              productAvailability = "https://schema.org/PreOrder";
              break;
            case "Sold Out":
              productAvailability = "https://schema.org/SoldOut";
              break;
            default:
              productAvailability = "https://schema.org/InStock";
          }
        }
      } else if (!product.productDetails.inStock) {
        // We treat Sold Out items a bit differently from just the badge sometimes so catch that here
        productAvailability = "https://schema.org/SoldOut";
      }

      structuredData = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: product.productDetails.name,
        image: [
          urlFor(product.productDetails.primaryImage)
            .width(1000)
            .height(1000)
            .fit('crop')
            .url(), // 1x1
          urlFor(product.productDetails.primaryImage)
            .width(1000)
            .height(750)
            .fit('crop')
            .url(), // 4x3
          urlFor(product.productDetails.primaryImage)
            .width(1000)
            .height(563)
            .fit('crop')
            .url(), // 16x9
        ],
        description: product.productDetails.seo.documentDescription || 
          product.productDetails.shortDescription,
        sku: product.productDetails.sku,
        brand: {
          '@type': 'Brand',
          name: 'Sunday',
        },
        offers: {
          '@type': 'Offer',
          url: `https://www.getsunday.com${sundayStoreProductDetailsRoute({
            category: product.productDetails.categories[0].category.slug,
            slug: product.productDetails.slug,
          })}`,
          priceCurrency: 'USD',
          price: centsToCurrency(unitPrice, {
            removeDollarSign: true,
          }),
          availability: productAvailability,
        },
      };
    }
  } catch (err) {
    captureException(new Error('Error generating structured data'), {
      extras: {
        error: err,
      },
    });
  }

  useDocumentHead('script', {
    type: 'application/ld+json',
    textContent: JSON.stringify(structuredData),
  });
};

import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { api } from '~/api';
import { useLawn } from '~/features/lawns/lawn-actions';
import { useCart } from '~/hooks/use-cart';
import {
  sundayStoreProductsQuery,
  useProductData,
} from '~/features/products/products-actions';
import { useSession } from '~/features/session/session-actions';
import {
  analyticsBeacon,
  DeprecatedEventType,
  EventType,
} from '~/utils/analytics';
import { captureException } from '~/utils/exception-tracking';
import { unique } from '~/utils/functions';
import { subscriptionType } from '~/features/subscriptions/subscription';
import { PAYMENT_TYPE } from '~/components/plans/bundles-page/bundles';
import { analytics } from '~/utils/analytics/plugins/segment';
import { retryPromise } from '~/utils/functions';

export const CART_LOCAL_STORAGE_KEY = 'cartLocalStorage';

export const useCartMutations = () => {
  const { user, isSignedIn } = useSession();
  const queryClient = useQueryClient();
  const { allProducts } = useProductData();
  const { lawn } = useLawn();
  const { cart: currentCart, isLoading: isLoadingCurrentCart } = useCart();

  // This function is used to invalidate the CART_LOCAL_STORAGE_KEY value.
  // If the user is on the checkout payment page in another tab, the eventListener will
  // refetch the cart data and send the user to the cart page in that tab.
  const invalidateCartLocalStorage = () => {
    const cartLocalStorageValue = window.localStorage.getItem(
      CART_LOCAL_STORAGE_KEY
    );

    if (cartLocalStorageValue === 'true') {
      try {
        window.localStorage.setItem(CART_LOCAL_STORAGE_KEY, 'false');
      } catch (err) {
        captureException(
          new Error(
            'Error setting localStorage cartLocalStorage to false when modifying cart'
          ),
          {
            extras: { error: err },
          }
        );
      }
    }
  };

  const setActiveCart = async (cartUuid) => {
    const cart = await api.cart.activate(cartUuid);

    queryClient.setQueryData('cart', cart);
    queryClient.refetchQueries(['carts.validate']);

    invalidateCartLocalStorage();

    return cart;
  };

  const addToCart = async (
    purchaseSku,
    qty = 1,
    {
      isSubscription,
      isFeaturedProduct = false,
      objectID,
      queryID,
      position = 1,
      cartUuidToUse = null,
    } = {}
  ) => {
    let newCart = null;

    if (!cartUuidToUse && !isLoadingCurrentCart && !currentCart) {
      newCart = await api.cart.create();
    }

    const sku = purchaseSku.skuId;
    const product = allProducts?.find(
      (product) => sku === product.purchaseSku?.skuId
    );

    // We need to send the BE the Segment anonymousId so that we can attribute server-side events to the same user
    let anonymousId = undefined;

    if (!isSignedIn) {
      const analyticsUser = await analytics.user();
      anonymousId = analyticsUser.anonymousId();
    }

    const cartUuid = cartUuidToUse || currentCart?.uuid || newCart?.uuid;

    const cart = await api.cart.add(
      cartUuid,
      sku,
      qty,
      isSubscription,
      anonymousId
    );

    invalidateCartLocalStorage();
    queryClient.setQueryData('cart', cart);
    queryClient.refetchQueries(['carts.validate']);

    try {
      postAddToCartAnalytics();
    } catch (err) {
      captureException(new Error('Error during add to cart analytics'), {
        extras: { error: err },
      });
    }

    function postAddToCartAnalytics() {
      const cartItem = cart.contents.find(
        (cartItem) => cartItem.sku.skuId === sku
      );

      if (isSubscription) {
        analyticsBeacon.emit(EventType.PLAN_TIER_SELECTED, {
          userUuid: user?.uuid,
          lawnUuid: lawn?.uuid,
          planSku: cartItem?.purchaseSku?.skuId,
          planCode: cartItem?.purchaseSku?.uuid,
          planTier: cartItem?.purchaseSku?.title,
          planPrice: cartItem?.purchaseSku?.unitPrice,
          planCartItems: [cartItem],
          cartContents: cart?.contents,
          paymentType: PAYMENT_TYPE.PAY_IN_FULL, // Pest plans are always pay in full
          allProducts,
        });
      } else {
        analyticsBeacon.emit(DeprecatedEventType.ADD_TO_CART, {
          cartItem,
        });

        analyticsBeacon.emit(EventType.PRODUCT_ADDED, {
          image: product?.productDetails?.primaryImage,
          cartUuid: cart.uuid,
          price: purchaseSku.unitPrice,
          productId: product?.productDetails?.id,
          productName: product?.productDetails?.name,
          quantity: Number(qty),
          sku: sku,
          productDetails: product?.productDetails,
          isFeaturedProduct,
          cartValue: cart.totalPriceWithTax,
          cartContents: cart.contents,
          products: allProducts,
        });
      }
    }

    return cart;
  };

  const addMultipleToCart = async (items) => {
    const cart = await api.cart.addMultiple(currentCart.uuid, items);
    invalidateCartLocalStorage();

    queryClient.setQueryData('cart', cart);
    queryClient.refetchQueries(['carts.validate']);

    return cart;
  };

  const removeMultipleFromCart = async (items) => {
    const cart = await api.cart.removeMultiple(currentCart.uuid, items);
    invalidateCartLocalStorage();

    queryClient.setQueryData('cart', cart);
    queryClient.refetchQueries(['carts.validate']);

    // Invalidate bundle query in the event a bundle was removed from cart
    queryClient.invalidateQueries(['bundle', lawn?.uuid, cart?.uuid]);
    return cart;
  };

  const updateCartQuantity = async (cartItem, qty, email) => {
    const cart = await api.cart.updateQuantity(currentCart.uuid, cartItem, qty);
    invalidateCartLocalStorage();

    queryClient.setQueryData('cart', cart);
    queryClient.refetchQueries(['carts.validate']);

    return cart;
  };

  const updateProcessDate = async ({ cartUuid, processDate }) => {
    const cart = await api.cart.updateProcessDate(cartUuid, processDate);

    queryClient.refetchQueries(['purchaseOrderAndShipmentHistory']);

    return cart;
  };

  const removeFromCart = async (cartItem) => {
    const cart = await api.cart.remove(currentCart.uuid, cartItem);
    invalidateCartLocalStorage();
    /**
     * The returned cart COULD contain an error message
     * explaining why a coupon was removed (in the case of a SKU-specific coupon),
     * so let's replace the 'cart' cache with the returned data
     */
    queryClient.setQueryData('cart', cart);
    queryClient.refetchQueries(['carts.validate']);
  };

  const addCoupon = useCallback(async (coupon, cartUuid) => {
    const cart = await api.cart.addCoupon(coupon, cartUuid);
    invalidateCartLocalStorage();

    return cart;
  }, []);

  const removeCoupon = async (cartUuid) => {
    const cart = await api.cart.removeCoupon(cartUuid);
    invalidateCartLocalStorage();

    return cart;
  };

  const confirmCart = async (cartUuid = currentCart.uuid) => {
    let cart;

    try {
      cart = await api.cart.confirmCart(cartUuid);
      queryClient.setQueryData('cart', cart);
    } catch (err) {
      captureException(new Error('Error while confirming cart'), {
        extras: {
          err,
        },
      });

      await queryClient.refetchQueries(['cart']);
    }

    return cart;
  };

  const updatePlanPaymentType = async (paymentType) => {
    const isFlexPay = paymentType === PAYMENT_TYPE.FLEX_PAY;

    const cart = await api.cart.updatePlanPaymentType(
      currentCart.uuid,
      isFlexPay
    );
    invalidateCartLocalStorage();

    queryClient.setQueryData('cart', cart);
    queryClient.refetchQueries(['carts.validate']);

    return cart;
  };

  const addShippingAddressToCart = async (cartUuid, shippingAddressUuid) => {
    return await api.cart.attachShippingAddress(cartUuid, shippingAddressUuid);
  };

  const associateCartWithUser = async (cartUuid) => {
    const cart = await api.cart.associateCartWithUser(cartUuid);
    queryClient.invalidateQueries('cart');
    return cart;
  };

  const skipShipment = async (cartUuid) => {
    const response = await api.cart.skipShipment(cartUuid);
    queryClient.refetchQueries(['purchaseOrderAndShipmentHistory']);
    queryClient.refetchQueries(['cart']);
    return response;
  };

  return {
    addCoupon,
    addMultipleToCart,
    addShippingAddressToCart,
    addToCart,
    associateCartWithUser,
    confirmCart,
    invalidateCartLocalStorage,
    removeCoupon,
    removeFromCart,
    removeMultipleFromCart,
    setActiveCart,
    skipShipment,
    updateCartQuantity,
    updateProcessDate,
    updatePlanPaymentType,
  };
};

/**
 * With the update to moving to PaymentIntents, we no longer have a token
 * to pass the BE. This, useCheckout(), will eventually
 * go away and be replaced with useSetPurchaseOrderToProcessing
 */
export const useCheckout = () => {
  const { getUser, refreshUser } = useSession();
  const { lawn } = useLawn();
  const queryClient = useQueryClient();

  const user = getUser();

  /**
   * This is used as part of the regular checkout flow.
   * Updates the status of the Purchase Order to "pending"
   * after a paymentIntents is successful while the BE
   * waits for the Stripe Webhook. Will also return the
   * payment method from the api to display on confirmation page
   */
  const checkout = async (cartUuid, paymentMethodId) => {
    let previousCart = {};

    // adding retry logic for confirmOrder. With the retry logic in place,
    // we can decrease the chances of the customer attempting to checkout with the same cart
    // multiple times. By the last retry, the thought is that the Stripe
    // webhook would have succeeded and updated the PO status.
    try {
      previousCart = await api.cart.confirmOrder(cartUuid, paymentMethodId);
    } catch (error) {
      captureException(new Error('Error during initial confirmOrder attempt'), {
        extras: { error: error.message },
      });

      await retryPromise(async () => {
        previousCart = await api.cart.confirmOrder(cartUuid, paymentMethodId);
      }).catch((retryError) => {
        new Error('All retry attempts failed during confirmOrder', {
          extras: {
            error: retryError,
            errorMessage: retryError.message,
            cartUuid,
            paymentMethodId,
          },
        });
      });
    }

    try {
      await postCheckoutAnalytics();
    } catch (err) {
      captureException(new Error('Error during post-checkout analytics'), {
        extras: { error: err },
      });
    }

    async function postCheckoutAnalytics() {
      let addOnProductData = queryClient.getQueryData(
        sundayStoreProductsQuery.key
      );
      if (!addOnProductData) {
        try {
          addOnProductData = await queryClient.fetchQuery(
            sundayStoreProductsQuery.key,
            sundayStoreProductsQuery.fn
          );
        } catch (err) {
          captureException(
            new Error(
              'Error while fetching products during post-checkout analytics'
            ),
            {
              extras: { error: err },
            }
          );
        }
      }

      analyticsBeacon.emit(DeprecatedEventType.PURCHASE, previousCart, user);

      analyticsBeacon.emit(EventType.ORDER_COMPLETED, {
        cart: previousCart,
        addOnProductsList: addOnProductData?.productList,
        customerName: user?.firstName + ' ' + user?.lastName,
      });

      if (previousCart.containsLawnPlan) {
        const itemWithPlanCode = previousCart.contents.find(
          (item) => item.bundleUuid
        );

        analyticsBeacon.emit(EventType.PLAN_PURCHASED, {
          orderUuid: previousCart.uuid,
          planSku: previousCart.planSku,
          userUuid: previousCart.user,
          planCode: itemWithPlanCode.bundleUuid,
          planPrice: previousCart.bundlePrice,
          planName: previousCart.bundleTitle,
          planPurchaseDate: new Date().toISOString(),
          isFlexPay: previousCart.containsFlexPlan,
          cartSubtotal: previousCart.subtotal,
          cartTotalPriceWithTax: previousCart.totalPriceWithTax,
        });

        analyticsBeacon.emit(
          DeprecatedEventType.SUBSCRIBE_LAWN_FUNNEL,
          previousCart,
          user?.uuid
        );
      }

      const pestPlans = getPestPlansFromCart(previousCart);

      pestPlans.forEach((item) => {
        analyticsBeacon.emit(EventType.PLAN_PURCHASED, {
          orderUuid: previousCart.uuid,
          planSku: item.sku.skuId,
          userUuid: previousCart.user,
          planCode: item.sku.skuId,
          planPrice: item.totalPrice,
          planName: item.sku.title,
          planPurchaseDate: new Date().toISOString(),
          isFlexPay: previousCart.containsFlexPlan,
          cartSubtotal: previousCart.subtotal,
          cartTotalPriceWithTax: previousCart.totalPriceWithTax,
        });

        analyticsBeacon.emit(
          DeprecatedEventType.SUBSCRIBE_PEST_FUNNEL,
          previousCart
        );
      });

      const springBoxPlan = getSpringBoxFromCart(previousCart);

      if (springBoxPlan) {
        analyticsBeacon.emit(EventType.PLAN_PURCHASED, {
          orderUuid: previousCart.uuid,
          planSku: springBoxPlan.sku.skuId,
          userUuid: previousCart.user,
          planCode: springBoxPlan.sku.skuId,
          planPrice: springBoxPlan.totalPrice,
          planName: springBoxPlan.sku.title,
          planPurchaseDate: new Date().toISOString(),
          isFlexPay: previousCart.containsFlexPlan,
          cartSubtotal: previousCart.subtotal,
          cartTotalPriceWithTax: previousCart.totalPriceWithTax,
        });
      }

      // Garden Plan Purchased Event
      const gardenPlanProducts = previousCart.contents.filter(
        (item) => item.subscriptionType === subscriptionType.GARDEN_ADDON
      );

      if (gardenPlanProducts?.length > 0) {
        const itemWithPlanCode = previousCart.contents.find(
          (item) => item.bundleUuid
        );

        analyticsBeacon.emit(EventType.PLAN_PURCHASED, {
          orderUuid: previousCart.uuid,
          planSku: previousCart.planSku,
          userUuid: previousCart.user,
          planCode: itemWithPlanCode.bundleUuid,
          planPrice: gardenPlanProducts.reduce(
            (totalPrice, item) => totalPrice + item.totalPrice,
            0
          ),
          planName: 'Garden Plan',
          planPurchaseDate: new Date().toISOString(),
          isFlexPay: previousCart.containsFlexPlan,
          cartSubtotal: previousCart.subtotal,
          cartTotalPriceWithTax: previousCart.totalPriceWithTax,
        });
      }

      // Pest-in-Lawn Plan Purchased Event
      const pestInLawnPlanProducts = previousCart.contents.filter(
        (item) => item.subscriptionType === subscriptionType.PEST_ADDON
      );

      if (pestInLawnPlanProducts?.length > 0) {
        const itemWithPlanCode = previousCart.contents.find(
          (item) => item.bundleUuid
        );

        analyticsBeacon.emit(EventType.PLAN_PURCHASED, {
          orderUuid: previousCart.uuid,
          planSku: previousCart.planSku,
          userUuid: previousCart.user,
          planCode: itemWithPlanCode.bundleUuid,
          planPrice: pestInLawnPlanProducts.reduce(
            (totalPrice, item) => totalPrice + item.totalPrice,
            0
          ),
          planName: 'Pest-in-Lawn Plan',
          planPurchaseDate: new Date().toISOString(),
          isFlexPay: previousCart.containsFlexPlan,
          cartSubtotal: previousCart.subtotal,
          cartTotalPriceWithTax: previousCart.totalPriceWithTax,
        });
      }

      // Emit PRO_SERVICE_PURCHASED for each service product in the cart
      previousCart.contents.forEach((cartItem) => {
        const matchingServiceProduct = addOnProductData?.productList.find(
          (product) =>
            product.sku === cartItem.sku.skuId &&
            product.fulfillmentProvider === 'ser'
        );

        if (matchingServiceProduct) {
          analyticsBeacon.emit(EventType.PRO_SERVICE_PURCHASED, {
            value: matchingServiceProduct.unitPrice * cartItem.quantity,
            cartUuid: previousCart.uuid,
          });
        }
      });

      const hasAddOns = previousCart.contents.some((item) => !item.bundleUuid);
      if (hasAddOns) {
        analyticsBeacon.emit(DeprecatedEventType.PURCHASE_ADD_ON);
      }

      if (!previousCart.containsLawnPlan && !previousCart.containsPestPlan) {
        analyticsBeacon.emit(
          DeprecatedEventType.PURCHASE_ONLY_ADD_ON,
          previousCart
        );
      }
    }

    const refreshPromises = [
      refreshUser(), // Get updated user.lawnSubscriptionStatus
      queryClient.refetchQueries(['lawn']), // Re-fetch lawns to invalidate any CTAs
      queryClient.refetchQueries(['shipments']),
      queryClient.refetchQueries(['purchaseOrderAndShipmentHistory']),
      queryClient.refetchQueries(['cart']),
      queryClient.refetchQueries(['subscriptions', user?.uuid]),
      queryClient.refetchQueries(['sundayStoreSubsoil']), // refetch products-list
    ];

    if (lawn) {
      refreshPromises.push(
        queryClient.refetchQueries(['subscriptionsForLawn', lawn?.uuid])
      );
    }

    Promise.all(refreshPromises).catch((err) =>
      captureException(new Error('Error during checkout data refresh'), {
        extras: {
          error: err,
        },
      })
    );

    return previousCart;
  };

  /**
   * This is only used in the instance of the PO status failing to be updated with an order being submitted in checkout.
   */
  const postCheckoutClearCache = async () => {
    queryClient.clear();
  };

  return { checkout, postCheckoutClearCache };
};

/**
 * Ask the API to determine which products in the current user's cart
 * are out of stock. The API returns two different arrays that both
 * represent OOS purchase sku ids, but we treat them the same so we combine
 * them into one array for better ergonomics.
 *
 * Both arrays from the API are purchase sku IDs, but they do have
 * slightly different meanings:
 * - `purchaseSkusOutOfStock` are Subsoil PurchaseSKUs marked as being OOS
 * - `purchaseSkusUnfulfillable` are Subsoil PurchaseSKUs without enough supply of related ShipmentSKUs
 *
 * This now also return products that are restricted from
 * sale in the user's state. purchaseSkusRestricted returns a list
 * of skus that are restricted in the customer's state.
 */
export const useCartValidation = ({ enabled } = { enabled: true }) => {
  const {
    data: cartValidation,
    isLoading,
    ...rest
  } = useQuery(
    ['carts.validate'],
    async () => {
      const res = await api.cart.validate();
      return {
        restrictedSkus: res.purchaseSkusRestricted,
        outOfStockSkus:
          unique(
            res.purchaseSkusOutOfStock.concat(res.purchaseSkusUnfulfillable)
          ) || [],
      };
    },
    {
      cacheTime: 0,
      enabled,
    }
  );

  return {
    outOfStockSkus: cartValidation?.outOfStockSkus,
    restrictedSkus: cartValidation?.restrictedSkus,
    isLoading,
    ...rest,
  };
};

export const getPestPlansFromCart = (cart = {}) => {
  return (
    cart.contents?.filter((item) =>
      [
        subscriptionType.MOSQUITO,
        subscriptionType.TOTAL_HOME,
        subscriptionType.TICK,
      ].includes(item.sku?.subscriptionType)
    ) || []
  );
};

const getSpringBoxFromCart = (cart = {}) => {
  return cart.contents?.find(
    (item) => item.sku?.subscriptionType === subscriptionType.SPRING
  );
};

export const useChildCarts = (cart = {}) => {
  const cartUuid = cart?.uuid;
  const { data: childCarts, ...rest } = useQuery(
    ['childCarts', cartUuid],
    () => api.cart.fetchChildCarts(cartUuid),
    {
      enabled: Boolean(cartUuid && cart?.masterPurchaseOrder?.uuid),
      staleTime: 0,
      cacheTime: 0,
    }
  );

  return {
    childCarts,
    ...rest,
  };
};

export const useProcessShipNowOrder = () => {
  const queryClient = useQueryClient();

  const processShipNowOrder = async (cartUuid) => {
    await api.cart.processShipNowOrder(cartUuid);

    // add a timeout to give the webhook a chance to process
    // so the paymentDetails is populated on the PO
    await new Promise((resolve) => {
      setTimeout(() => {
        queryClient.refetchQueries(['purchaseOrderAndShipmentHistory']);
        resolve();
      }, 2000);
    });
    return;
  };

  return { processShipNowOrder };
};

export const useProcessAuthFailOrder = () => {
  const queryClient = useQueryClient();

  const processAuthFailOrder = async (cartUuid, paymentMethodId, lawnUuid) => {
    await api.cart.confirmOrder(cartUuid, paymentMethodId);

    // add a timeout to give the webhook a chance to process
    // so the paymentDetails is populated on the PO
    await new Promise((resolve) => {
      setTimeout(() => {
        lawnUuid &&
          queryClient.refetchQueries(['subscriptionsForLawn', lawnUuid]);
        queryClient.refetchQueries(['purchaseOrderAndShipmentHistory']);
        queryClient.refetchQueries(['cart']);
        resolve();
      }, 2000);
    });
    return;
  };

  return { processAuthFailOrder };
};

export const paymentMethodsApi = (http) => {
  return {
    add: async (paymentMethodId) => {
      return http.post('/api/payment-method/', {
        payment_method_id: paymentMethodId,
      });
    },
    createPaymentIntent: async (uuid) => {
      return http.post(`/api/payment-intent/`, {
        purchase_order_uuid: uuid,
      });
    },
    find: async (paymentMethodId) => {
      return http.get(`/api/payment-method/${paymentMethodId}/`);
    },
  };
};

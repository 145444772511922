export const purchaseOrdersApi = (http) => {
  return {
    all: async () => {
      const res = await http.get('/api/purchase-orders-and-shipments/');
      return res;
    },

    postPurchaseSurvey: ({ purchaseOrderUuid, leadSource, otherText }) => {
      return http.post('/api/purchaseorders/surveyresults/', {
        purchaseOrder: purchaseOrderUuid,
        leadSource,
        otherText,
      });
    },
  };
};

import React from 'react';
import cx from 'classnames';

import styles from '~/components/carousels/carousel.module.scss';

const CarouselGrid = ({ className, children }) => {
  return (
    <div className={cx(styles.grid, className)}>
      {React.Children.map(children, (child) => (
        <span className={styles.gridItem}>{child}</span>
      ))}
    </div>
  );
};

export default CarouselGrid;

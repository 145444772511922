import { useCallback } from 'react';
import { atom, useAtom } from 'jotai';

const modalAtom = atom({
  currentModal: null,
  modalData: {},
});

export const useModal = () => {
  const [state, setState] = useAtom(modalAtom);

  const showModal = useCallback(
    (modalName, modalData = {}) => {
      setState({
        currentModal: modalName,
        modalData,
      });
    },
    [setState]
  );

  const hideModal = useCallback(() => {
    setState({
      currentModal: null,
      modalData: {},
    });
  }, [setState]);

  return {
    currentModal: state.currentModal,
    modalData: state.modalData,
    showModal,
    hideModal,
  };
};

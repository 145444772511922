import { useTitle } from '~/hooks/use-title';
import { useDocumentHead } from '~/hooks/use-document-head';
import { useCanonicalUrl } from '~/hooks/use-canonical-url';

export const useSEO = ({ title, description, canonicalUrl }) => {
  useTitle(title);
  useDocumentHead(
    'meta',
    {
      name: 'description',
      content: description,
    },
    { enabled: Boolean(description) }
  );
  useCanonicalUrl(canonicalUrl);
};

import Cookies from 'js-cookie';

/**
 * createCookieStorage() is a factory function that returns an object with methods
 * for interacting with device cookies.
 */
export const createCookieStorage = ({ cookies, namespace }) => {
  const getFullKey = (key) => {
    return `${namespace}_${key}`;
  };

  return {
    get: (key) => {
      return cookies.get(getFullKey(key));
    },

    set: (key, value, options = {}) => {
      return cookies.set(getFullKey(key), value, {
        sameSite: 'lax',
        ...options,
      });
    },

    remove: (key) => {
      return cookies.remove(getFullKey(key));
    },
  };
};

export const cookies = createCookieStorage({
  cookies: Cookies,
  namespace: 'APP',
});

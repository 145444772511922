import { derivedShipmentDate } from '~/features/shipments/shipment';

export const shipmentsApi = (http) => {
  return {
    all: async () => {
      const res = await http.get('/api/shipments/');
      const shipments = res?.shipments || res;

      const byShipmentDateAsc = (a, b) => {
        return new Date(derivedShipmentDate(a)) <
          new Date(derivedShipmentDate(b))
          ? -1
          : 1;
      };

      return shipments.sort(byShipmentDateAsc);
    },
    find: async (shipmentOrder) => {
      const res = await http.get(
        `/api/shipment_items/?shipment_order=${shipmentOrder}`
      );

      return res;
    },
    // this is for the add-to-box page, it is getting the next
    // unpaid shipment order specific to lawns in a flex pay plan
    nextUnpaid: async () => {
      const res = await http.get('/api/shipment_order/');

      return res;
    },
    update: async (shipmentUuid, payload) => {
      return http.put(`/api/shipment_order/${shipmentUuid}/`, payload);
    },
  };
};

import React from 'react';
import { Link } from 'react-router-dom';
import BlockContentToReact from '@sanity/block-content-to-react';

import FunnelCtaBanner from '~/components/cta-banner/funnel-cta-banner';
import { ProductWidgetSerializer } from '~/components/the-shed/components/widgets/product-widget/product-widget';
import AnnotatedImageSerializer from '~/components/the-shed/components/widgets/annotated-image/annotated-image';
import SundayTipSerializer from '~/components/the-shed/components/widgets/sunday-tip/sunday-tip';
import OrderedListItemSerializer from '~/components/the-shed/components/widgets/ordered-list-item/ordered-list-item';
import ProductLink from '~/components/products/product-link';
import EmbeddedVideoSerializer from '~/components/the-shed/components/widgets/embedded-video/embedded-video';
import { shedArticleRoute } from '~/routes';
import { stripNonLetterChars, toKebabCase } from '~/utils/strings';
import { blocksToText } from '~/sanity/block-content-utils';
import { useScrollToId } from '~/hooks/use-scroll-to-id';
import { assetUrlFor } from '~/sanity/assets';

import styles from '~/components/the-shed/article/article-body.module.scss';

export const SHED_ARTICLE_BODY_ID = 'shed-article-body';

export const BlockContentSerializer = (props) => {
  const { style = 'normal' } = props.node;

  // Custom Header Serializer that appends an id to the element
  if (/^h\d/.test(style)) {
    if (props.children && props.children[0] === '') {
      return null;
    }

    const editStr = stripNonLetterChars(blocksToText([props.node]));
    const idText = toKebabCase(editStr);

    return React.createElement(style, { id: idText }, props.children);
  }

  return BlockContentToReact.defaultSerializers.types.block(props);
};

const serializers = {
  types: {
    annotatedImage: AnnotatedImageSerializer,
    block: BlockContentSerializer,
    ctaBanner: (props) => {
      const ctaProps = props.node;
      return <FunnelCtaBanner {...ctaProps} />;
    },
    embeddedVideo: EmbeddedVideoSerializer,
    orderedListItem: OrderedListItemSerializer,
    productWidget: ProductWidgetSerializer,
    sundayTip: SundayTipSerializer,
  },
  marks: {
    link: ({ mark, children }) => {
      const { href, linkType } = mark;

      switch (linkType) {
        case 'articleLink':
          const { category, slug: articleSlug } = mark.articleLink;

          if (!category?.articleCategory?.slug || !articleSlug) {
            return <span>{children}</span>;
          } else
            return (
              <Link
                to={shedArticleRoute({
                  category: category?.articleCategory?.slug,
                  slug: articleSlug,
                })}
              >
                {children}
              </Link>
            );

        case 'productLink':
          return (
            <ProductLink slug={mark.productLink.slug}>{children}</ProductLink>
          );

        case 'generalLink':
          const parsed = new URL(href);
          if (parsed.host === ('getsunday.com' || 'www.getsunday.com')) {
            return <Link to={parsed.pathname}>{children}</Link>;
          } else {
            return (
              <a href={href} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            );
          }

        case 'fileLink':
          return (
            <a
              href={assetUrlFor(mark.fileLink?.asset)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {children}
            </a>
          );

        default:
          return (
            <a href={href} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          );
      }
    },
    small: ({ children }) => {
      return <small>{children}</small>;
    },
  },
};

const ArticleBody = ({ body }) => {
  useScrollToId();

  return (
    <div className={styles.articleBody} id={SHED_ARTICLE_BODY_ID}>
      <BlockContentToReact blocks={body} serializers={serializers} />
    </div>
  );
};

export { ArticleBody };

import { sanity } from '~/sanity/sanity-client';
import {
  PROMO_BANNERS_QUERY,
  PROMO_BANNER_QUERY,
  topBannerQuery,
} from '~/sanity/promo-banners/promo-banners-queries';

export const fetchPromoBanner = async (slug) => {
  const res = await sanity({
    query: PROMO_BANNER_QUERY,
    variables: {
      slug,
    },
  });

  return res;
};

export const fetchPromoBanners = async () => {
  const res = await sanity({
    query: PROMO_BANNERS_QUERY,
  });

  return res;
};

export const fetchTopBanner = async (slug) => {
  const res = await sanity({
    query: topBannerQuery(slug),
    variables: {
      slug,
    },
  })

  return res;
};

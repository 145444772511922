export const providersApi = (http) => {
  return {
    findProviders: async (skuIds) => {
      const res = await http.post(`/api/purchase/providers/`, {
        skuIds,
      });

      return res.mapping;
    },
  };
};

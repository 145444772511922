/* eslint-disable react/jsx-no-target-blank */

import React from 'react';

import { termsRoute } from '~/routes';

const TermsOfUseLinks = () => {
  return (
    <p
      style={{
        color: 'var(--gray-3)',
        fontSize: '1.3rem',
        margin: '2rem 0 1rem 0',
      }}
    >
      By creating an account you agree to our{' '}
      <a href={termsRoute()} style={{ color: 'var(--gray-3)' }} target="_blank">
        Terms of Use
      </a>{' '}
      and consent to our{' '}
      <a
        href={window.env.PRIVACY_POLICY_URL}
        style={{ color: 'var(--gray-3)' }}
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
    </p>
  );
};

export default TermsOfUseLinks;

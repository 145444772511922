export const PestPlanTypes = {
  MOSQUITO: 'MOSQUITO',
  TOTAL_HOME: 'TOTAL_HOME',
  TICK: 'TICK',
};

const PestPlanContent = {
  [PestPlanTypes.MOSQUITO]: {
    headerText: 'The results are in!',
    shortDesc: (isDesktop) => (
      <>
        The biggest buzzkill in your area? <strong>Mosquitoes</strong>.{' '}
        <br style={{ display: isDesktop ? 'block' : 'none' }} />
        This plan accounts for who’s using your yard and how often—and helps you
        enjoy a bite-free backyard. Just choose your size!
      </>
    ),
  },
  [PestPlanTypes.TOTAL_HOME]: {
    headerText: 'The results are in!',
    shortDesc: (isDesktop) => (
      <>
        Based on the variety and location of your pests, we recommend our most
        comprehensive plan. To protect your home indoors and out from dozens of
        bugs, just choose your size!
      </>
    ),
  },
  [PestPlanTypes.TICK]: {
    headerText: 'The results are in!',
    shortDesc: (isDesktop) => (
      <>
        Ticks are more than a nuisance—they’re a hazard to the health of your
        family. This plan accounts for your climate and yard use, and helps you
        enjoy a bite-free backyard. Just choose your size!
      </>
    ),
  },
};

export default PestPlanContent;

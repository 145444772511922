import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { config, useTransition, animated } from '@react-spring/web';
import cx from 'classnames';

import Button from '~/components/button';
import BlockContent from '~/components/layouts/block-content';
import { useFlashMessage } from '~/features/flash-messages/flash-message-actions';
import { useModal } from '~/features/modals/modal-actions';
import { analyticsBeacon, EventType } from '~/utils/analytics';
import { cookies } from '~/utils/cookie';
import { useTopBanner } from '~/hooks/use-promo-banner';

import styles from '~/components/static/promo-banner/top-banner.module.scss';

const TopBanner = () => {
  const { showFlashMessage } = useFlashMessage();
  const location = useLocation();
  const { showModal } = useModal();

  const { topBanner } = useTopBanner();

  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeRemaining(topBanner?.promoEndDate)
  );

  const shouldShowBanner =
    (topBanner?.text && !topBanner?.promoEndDate) ||
    new Date() < new Date(topBanner?.promoEndDate);

  const shouldShowCountdown =
    topBanner?.useCountDownTimer &&
    timeRemaining.total > 0 &&
    timeRemaining.days < 3; // Show countdown with 3 days or less remaining

  const topBannerPortableText = useMemo(() => {
    const [content, ...rest] = topBanner?.text || [{}];

    if (shouldShowCountdown) {
      // Add countdown timer to the beginning of portable text
      return [
        {
          ...content,
          children: [
            {
              _type: 'span',
              _key: 'countdown',
              marks: ['strong'],
              text: formatTimeRemaining(timeRemaining),
            },
            {
              _type: 'span',
              _key: 'countdown-1',
              text: ' | ',
            },
            ...content.children,
          ],
        },
        ...rest,
      ];
    }

    return topBanner?.text;
  }, [topBanner?.text, shouldShowCountdown, timeRemaining]);

  const transitions = useTransition(shouldShowBanner, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
  });

  useEffect(() => {
    setTimeRemaining(calculateTimeRemaining(topBanner?.promoEndDate));
  }, [topBanner?.promoEndDate]);

  useEffect(() => {
    if (timeRemaining.total <= 0) {
      return;
    }

    const isLastDay = timeRemaining.days < 1;
    const interval = isLastDay ? 1000 * 60 : 1000 * 60 * 5;
    const countdown = setInterval(() => {
      if (shouldShowBanner && topBanner?.promoEndDate) {
        setTimeRemaining(calculateTimeRemaining(topBanner?.promoEndDate));
      }
    }, interval);

    return () => clearInterval(countdown);
  }, [timeRemaining, topBanner?.promoEndDate, shouldShowBanner]);

  if (!topBanner) {
    return null;
  }

  const handleBannerClick = async () => {
    if (!topBanner?.promoCode) {
      return;
    }

    cookies.set('QUERY_PARAM_COUPON', topBanner.promoCode, { expires: 14 });
    analyticsBeacon.emit(EventType.PROMO_BANNER_CODE_COPIED, {
      coupon: topBanner.promoCode,
      pathname: location.pathname,
    });

    showFlashMessage(
      `Coupon code ${topBanner.promoCode.toUpperCase()} will automatically be added to your cart`,
      'success',
      5000
    );
  };

  return transitions(
    (props, item) =>
      item && (
        <animated.div
          aria-label={`Promo code ${topBanner?.promoCode}`}
          className={cx(styles.topBanner, {
            [styles.bannerOpen]: shouldShowBanner,
          })}
          id="top-banner"
          onClick={handleBannerClick}
          style={{
            ...props,
            backgroundColor: topBanner?.backgroundColor?.hex,
            color: topBanner?.fontColor?.hex,
          }}
          role="button"
          tabIndex={0}
        >
          <div
            className={cx(styles.topBannerWrapper, {
              [styles.multiLine]: topBannerPortableText?.length > 1,
            })}
          >
            <BlockContent content={topBannerPortableText} />
            {topBanner?.terms && (
              <Button
                className={styles.termsModalTrigger}
                id="terms-modal-button"
                onClick={(event) => {
                  // Don't copy the promo code when clicking the terms button
                  event.stopPropagation();

                  showModal('TERMS_CONDITIONS', {
                    content: topBanner.terms,
                  });
                }}
                style={{
                  color: topBanner?.fontColor?.hex,
                  padding: 0,
                }}
                variant="link"
              >
                Terms
              </Button>
            )}
          </div>
        </animated.div>
      )
  );
};

const calculateTimeRemaining = (endDate) => {
  const total = Date.parse(endDate) - Date.now();
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));
  return { total, days, hours, minutes, seconds };
};

const formatTimeRemaining = ({ days, hours, total }) => {
  if (total <= 0) {
    return null;
  }

  if (days > 3) {
    return 'Only a few days left';
  }

  if (days > 0) {
    return `Only ${days + 1} days left`;
  }

  if (hours > 12) {
    return 'Sale ends today';
  }

  return `${hours + 1} hour${hours + 1 === 1 ? '' : 's'} left`;
};

export default TopBanner;

import { useEffect, useRef, useState } from 'react';

/**
 * useVisibility() returns a ref (to be attached to a DOM node)
 * and a boolean indicating if that element is in the viewport or not.
 */
export const useVisibility = ({ triggerOnce } = {}) => {
  const visibilityRef = useRef();

  // Initialize to "true" if IntersectionObserver is not available
  const [isVisible, setIsVisible] = useState(
    () => !Boolean(window.IntersectionObserver)
  );

  useEffect(() => {
    const element = visibilityRef.current;

    if (!window.IntersectionObserver || !element) {
      return;
    }

    const observer = getObserver();

    observer.observe(element);

    LISTENERS.set(element, (visible) => {
      if (isVisible !== visible) {
        setIsVisible(visible);
      }

      if (visible && triggerOnce) {
        observer.unobserve(element);
        LISTENERS.delete(element);
      }
    });

    return () => {
      observer.unobserve(element);
    };
  }, [isVisible, triggerOnce]);

  return [visibilityRef, isVisible];
};

/**
 * LISTENERS holds the callback functions for each observed element.
 */
const LISTENERS = new Map();

/**
 * OBSERVER is a global IntersectionObserver singleton.
 * Since our use-case is simple enough we can get away with using one
 * IntersectionObserver rather than create a new one for each execution.
 */
let OBSERVER;
function getObserver() {
  if (OBSERVER) {
    return OBSERVER;
  }

  OBSERVER = new window.IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        const listener = LISTENERS.get(entry.target);
        if (listener) {
          const isVisible = entry.isIntersecting;
          listener(isVisible);
        }
      });
    },
    {
      // Trigger when the element is within 300px of the top or bottom of the screen
      rootMargin: '300px 0px',
      threshold: 0,
    }
  );

  return OBSERVER;
}

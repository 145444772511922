import React from 'react';
import { Link } from 'react-router-dom';

import { guaranteeRoute } from '~/routes';

import styles from '~/components/sunday-store/components/product-details/product-details-page.module.scss';

const GuaranteeBadge = () => (
  <div className={styles.guaranteeWrapper}>
    <img
      className={styles.guaranteeBadge}
      src="/icons/sunday-guarantee-badge-green.svg"
      alt="sunday-guarantee-badge"
    />
    <div className={styles.guaranteeTextWrapper}>
      <p className={styles.guaranteeText}>
        We're committed to making sure Sunday works for you and your home. Need
        a replacement? Or help troubleshooting an issue? Our experts are here
        for you.{' '}
        <Link
          className={styles.guaranteeText}
          to={guaranteeRoute()}
          aria-label="Learn more about the Sunday guarantee"
        >
          Learn more
        </Link>
      </p>
    </div>
  </div>
);

export default GuaranteeBadge;

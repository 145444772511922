import React from 'react';
import cx from 'classnames';

import { PDP_DISPLAY_TYPE } from '~/components/sunday-store/components/product-details/product-details-page';
import { centsToCurrency } from '~/utils/numbers';

import styles from '~/components/products-grid/products-cell-badge.module.scss';

const ProductBadge = ({
  fullPrice,
  productDetails,
  purchaseSku,
  unitPrice,
  variant,
}) => {
  const { inStock, customBadge, pdpDisplayType } = productDetails;
  const { isRestrictedInState } = purchaseSku;

  const isLawnPlanProduct =
    pdpDisplayType === PDP_DISPLAY_TYPE.LAWN_PLAN_PRODUCT;
  const isDiscounted = unitPrice < fullPrice;
  const discount = centsToCurrency(fullPrice - unitPrice, { round: true });

  const saveBadgeCopy =
    customBadge &&
    ['save', 'bundle'].some((str) => customBadge.toLowerCase().includes(str));

  let alertStyle;
  if (isDiscounted && customBadge && !saveBadgeCopy) {
    alertStyle = false;
  } else if (isDiscounted) {
    alertStyle = true;
  } else if (saveBadgeCopy) {
    alertStyle = true;
  } else alertStyle = false;

  let text = '';
  if (isLawnPlanProduct) {
    text = 'LAWN PLAN EXCLUSIVE';
  } else if (inStock === false) {
    text = 'SOLD OUT';
  } else if (isRestrictedInState) {
    text = 'RESTRICTED';
  } else if (customBadge) {
    text = customBadge;
  } else if (isDiscounted) {
    text = `SAVE ${discount}`;
  } else return null;

  return (
    <div
      className={cx(styles.badge, {
        [styles.alert]: alertStyle,
        [styles.muted]: !inStock || isRestrictedInState,
        [styles.largeBadge]: variant === 'largeBadge',
        [styles.exclusive]: isLawnPlanProduct,
      })}
    >
      <p className={styles.badgeText}>{text}</p>
    </div>
  );
};

export default ProductBadge;

import React from 'react';
import cx from 'classnames';

import AutocompleteAddressInput from '~/components/inputs/autocomplete-address-input/autocomplete-address-input';
import Button from '~/components/button';
import LazyLoad from '~/components/lazy-load';
import { useMeasure } from '~/hooks/use-measure';

import styles from '~/components/cta-banner/cta-banner.module.scss';

const DEFAULT_THEME = {
  backgroundColor: '#ffc059',
  buttonBackgroundColor: '#007290',
  buttonFontColor: '#ffffff',
  titleColor: '#003d4e',
  footerTextColor: '#003d4e',
  accent1Color: '#00A5C4',
  accent2Color: '#0090B0',
  accent3Color: '#007290',
};

const CtaBanner = ({
  title,
  buttonText,
  footerText,
  theme = DEFAULT_THEME,
  onAddressSelected,
  onContinue,
  isButtonOnly = false,
  showAddressInput = true,
  isButtonDisabled,
  errorMessage,
  submitFailed,
}) => {
  const [measureRef, width] = useMeasure({
    initialValue: 0,
    observeOptions: { box: 'border-box' },
    entryAccessor: (entry) => entry.target.offsetWidth,
  });

  let size = 'small';

  if (width >= 1200) {
    size = 'large';
  } else if (width >= 768) {
    size = 'medium';
  }

  return (
    <div
      ref={measureRef}
      className={cx(styles.ctaBanner, styles[size], 'ctaBanner')}
      style={{ backgroundColor: theme.backgroundColor }}
    >
      <div className={styles.svgContainer}>
        <svg
          className={cx(styles.accent, styles.accent1)}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 90 26"
        >
          <path
            d="M90 25.851C81.012 10.401 64.232 0 45 0 25.766 0 8.986 10.4 0 25.851h90z"
            fill={theme.accent1Color}
          />
        </svg>
        <svg
          className={cx(styles.accent, styles.accent2)}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 90 26"
        >
          <path
            d="M90 25.851C81.012 10.401 64.232 0 45 0 25.766 0 8.986 10.4 0 25.851h90z"
            fill={theme.accent2Color}
          />
        </svg>
        <svg
          className={cx(styles.accent, styles.accent3)}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 90 26"
        >
          <path
            d="M90 25.851C81.012 10.401 64.232 0 45 0 25.766 0 8.986 10.4 0 25.851h90z"
            fill={theme.accent3Color}
          />
        </svg>
      </div>

      <div
        className={cx(styles.innerWrapper, {
          [`${styles.buttonOnly}`]: isButtonOnly,
        })}
      >
        <h2
          className={cx(styles.title, {
            [`${styles.buttonOnly}`]: isButtonOnly,
          })}
          style={{ color: theme.titleColor }}
        >
          {title}
        </h2>
        <LazyLoad>
          <div
            className={cx(styles.inputContainer, {
              [`${styles.buttonOnly}`]: isButtonOnly,
            })}
          >
            {showAddressInput && (
              <div className={styles.inputErrorWrapper}>
                <AutocompleteAddressInput
                  placeholder="Enter your home address"
                  onSelect={onAddressSelected}
                  inputClassname={styles.input}
                  containerClassname={styles.autocompleteContainer}
                  error={errorMessage}
                  submitFailed={submitFailed}
                />
              </div>
            )}
            <Button
              disabled={isButtonDisabled}
              onClick={onContinue}
              className={styles.button}
              style={{
                backgroundColor: theme.buttonBackgroundColor,
                color: theme.buttonFontColor,
              }}
            >
              {buttonText}
            </Button>
          </div>
        </LazyLoad>
      </div>

      <p className={styles.text} style={{ color: theme.footerTextColor }}>
        {footerText}
      </p>
    </div>
  );
};

export default CtaBanner;
